
<div class="layout-dashboard">

    <div class="card" *ngIf="camere && camere?.length > 0">

            <p-table #dt [columns]="cols" [responsive]="true" [value]="camere" dataKey="id" exportFilename="camere" sortField="DataChangePrimaryID" sortOrder="-1" [customSort]="true" [columns]="cols">

                <ng-template pTemplate="caption">
                    <div style="text-align: right">
                        <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                        <input type="text" pInputText size="25" placeholder="Filtra" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto"/>
                    </div>
                </ng-template>

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 3em;text-align: center"></th>
                        <th style="width: 15em;text-align: center"></th>
                        <th style="text-align: center">Nome</th>
                        <th style="text-align: center">Struttura</th>
                        <th style="width: 15em;text-align: center"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-cols="columns">
                        <tr>
                            <td>
                                <a href="#" [pRowToggler]="rowData">
                                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                                </a>
                            </td>
                            <!--<td style="text-align: center"><a style="cursor: pointer;" (click)="selezionaCamera(rowData)"><i class="fa fa-edit" style="color: yellow"></i></a></td>
                            <td style="text-align: center" pTooltip="{{rowData | iconaCamera : 'human'}}" ><i [ngClass]="rowData | iconaCamera" [style.color]="rowData | iconaCamera : 'colore'"></i></td>-->
                            <td>
                                <span *ngIf="rowData.stato" [class]="'product-badge status-' + rowData.stato.toLowerCase()">{{rowData.stato}}</span>
                                <span *ngIf="!rowData.stato">???</span>
                            </td>
                            <td style="text-align: center">{{rowData.nome}}</td>
                            <td style="text-align: center">{{rowData.sistemazione | struttura}}</td>
                            <td style="text-align: center"><a style="cursor: pointer;" (click)="selezionaCamera(rowData)">[ modifica ]</a></td>
                        </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
                    <tr>
                        <td [attr.colspan]="5">
                            <div class="p-grid" style="font-size:16px;padding:20px">
                                    <div class="p-col-12 p-md-6">
                                        <b>Stato:</b> {{rowData.stato}}
                                    </div>
                                    <div class="p-col-12 p-md-6">
                                        <b>Struttura:</b> {{rowData.sistemazione | sistemazione}}
                                    </div>
                                    <div class="p-col-12 p-md-6">
                                        <b>Pulizia:</b> {{rowData.ultimaPulizia ? (rowData.ultimaPulizia.toDate() | date:'short') : 'MAI PULITA'}}
                                    </div>
                                    <div class="p-col-12 p-md-6">
                                        <b>Governante:</b> {{rowData.ultimaGovernante ? rowData.ultimaGovernante : 'MAI PULITA'}}
                                    </div>
                            </div>
                            <div class="p-col-12">
                                <span><button pButton type="button" label="CAMERA PULITA" [disabled]="rowData.stato === 'PRONTA'" (click)="cameraPulita(rowData)" icon="fa fa-thumbs-o-up" style="margin-top:0"></button></span>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

    </div>

    <div class="ui-g-12" *ngIf="camere?.length === 0 && !camere">

        <p-panel header="Elenco Camere">

            <h1>Non ho trovato alcuna Camera</h1>
            <br/><br/><br/><br/><br/><br/><br/>
            <br/><br/><br/><br/><br/><br/><br/>
        </p-panel>
    </div>

    </div>

