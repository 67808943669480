import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    OnChanges,
    Output,
    EventEmitter
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Camera } from 'src/app/domain/camera';
import { Ticket } from 'src/app/domain/ticket';
import { Account } from 'src/app/domain/account';
import { MyAccount } from 'src/app/domain/my.account';
import { Costanti } from 'src/app/services/costanti';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { StatoTicket } from 'src/app/enumeratori/stato-ticket';
import { SessioneService } from 'src/app/services/sessione.service';
import { DAService } from 'src/app/services/da.service';
import { TicketService } from 'src/app/services/ticket.service';

@Component({
    selector: 'app-ticket',
    templateUrl: './ticket.component.html',
})
export class TicketComponent implements OnInit, OnChanges, OnDestroy {

    @Input() ticket?: Ticket;
    @Input() camera?: Camera;
    @Output() chiudi = new EventEmitter();

    myForm: FormGroup;
    utente?: Account;
    myAccount?: MyAccount;

    comboTipo: SelectItem[] = Costanti.COMBO_TIPO_TICKET;

    dataCreazione = new Date();
    oggetto: string;
    descrizione: string;
    stato = StatoTicket.APERTO;

    constructor(
        private sessione: SessioneService,
        private dao: DAService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private ticketService: TicketService
    ) {}

    ngOnInit() {
        this.utente = this.sessione.operatoreLoggato;
        this.myAccount = this.sessione.myAccount;

    }

    /**
     * invocato ogni volta che l'input del componente cambia
     */
    ngOnChanges() {

        if (this.ticket) {
            this.myForm = new FormGroup({

                dataCreazione: new FormControl(this.ticket.dataCreazione ? this.ticket.dataCreazione.toDate() : null),
                cameraId: new FormControl(this.ticket.cameraId),
                cameraNome: new FormControl(this.ticket.cameraNome),
                cameraStruttura: new FormControl(this.ticket.cameraStruttura),
                codice: new FormControl(this.ticket.codice),

                oggetto: new FormControl(this.ticket.oggetto, Validators.required),
                descrizione: new FormControl(this.ticket.descrizione, Validators.required),
                // ospite: new FormControl(this.ticket.ospite, Validators.required),
                // tipo: new FormControl(this.ticket.tipo, Validators.required),
                stato: new FormControl(this.ticket.stato),
            });

    } else {
            console.error('TicketComponent ngOnChanges senza ticket');
    }
    }

    onSubmit() {

        // TODO: Use EventEmitter with form value

        console.log('SUBMINT');

        const data = {
            creatoDa: this.utente.uid,
            creatoDaNome: this.utente.displayName,
            creatoDaRuolo: this.utente.ruolo,
            dataCreazione: new Date(),
            oggetto: this.oggetto,
            stato: StatoTicket.APERTO,
            descrizione: this.descrizione,
            // cameraId: this.camera ? this.camera.id : null,
            // cameraNome: this.camera ? this.camera.nome : null,
            // cameraStruttura: this.camera ? this.camera.sistemazione : null,
        };

        if (!this.oggetto) {
            this.messageService.add({ severity: 'error', summary: 'Nuovo Ticket', detail: 'Manca l\'Oggetto' });
            return;
        }

        if (this.ticket.id) {
            this.dao.modifica(this.ticket.id, Costanti.TABELLA_TICKETS, this.myForm.value, true);
        } else {
            // this.dao.creaSenzaChiave(this.myForm.value, Costanti.TABELLA_TICKETS);
            this.dao.creaSenzaChiave(data, Costanti.TABELLA_TICKETS);
            this.messageService.add({ severity: 'success', summary: 'Nuovo Ticket', detail: 'Creato con successo !' });
            // this.annulla();
            this.oggetto = null;
        }

    }

    annulla() {
        this.chiudi.emit();
    }

    inLavorazione() {

        this.confirmationService.confirm({
            message: 'Sei sicuro di voler prendere in carico il Ticket ?',
            accept: () => {
                if (this.ticketService.inLavorazione(this.ticket, this.utente)) {
                    this.messageService.add({ severity: 'success', summary: 'Ticket', detail: 'Il ticket e\' stato preso in carico' });
                    this.ticket.stato = StatoTicket.IN_LAVORAZIONE;
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Ticket', detail: 'Spiacente, qualcosa e\' andato storto :(' });
                }

            }
        });

    }

    chiudiTicket() {

        this.confirmationService.confirm({
            message: 'Sei sicuro di voler chiudere il Ticket ?',
            accept: () => {
                if (this.ticketService.chiudi(this.ticket, this.utente)) {
                    this.messageService.add({ severity: 'success', summary: 'Ticket', detail: 'Il ticket e\' stato chiuso' });
                    this.ticket.stato = StatoTicket.CHIUSO;
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Ticket', detail: 'Spiacente, qualcosa e\' andato storto :(' });
                }

            }
        });

    }

    annullaTicket() {

        this.confirmationService.confirm({
            message: 'Sei sicuro di voler annullare il Ticket ?',
            accept: () => {
                if (this.ticketService.annulla(this.ticket, this.utente)) {
                    this.messageService.add({ severity: 'success', summary: 'Ticket', detail: 'Il ticket e\' stato annullato' });
                    this.ticket.stato = StatoTicket.ANNULLATO;
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Ticket', detail: 'Spiacente, qualcosa e\' andato storto :(' });
                }

            }
        });

    }

    elimina() {

        this.confirmationService.confirm({
            message: 'Sei sicuro di voler cancellare il Ticket ?',
            accept: () => {
                this.ticketService.elimina(this.ticket);
                this.chiudi.emit();
            }
        });

    }

    salva() {
        this.onSubmit();
    }

    ngOnDestroy() {
        // this.sessione.deselezionaTgate();
    }
}
