import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AutenticatoreService } from '../services/autenticatore.service';
import { SessioneService } from '../services/sessione.service';
// import { AutenticatoreService } from '../service/autenticatore.service';
// import { SessioneService } from '../service/sessione.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private sessione: SessioneService, private router: Router, private autenticatore: AutenticatoreService ) {}
    // constructor(private router: Router ) {}


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;

        // console.log('AuthGuard.canActivate ? con url ' + url + ' and checkLogin = ' + this.autenticatore.loggato);
        console.log('AuthGuard.canActivate ? con url ' + url);

        // if (this.autenticatore.loggato || this.sessione.utenteLoggato) {
        if (this.autenticatore.loggato || this.sessione.uidConnesso) {
            console.log('AuthGuard this.autenticatore.isLoggedIn TRUE');
            return true;
        }

        console.log('AuthGuard this.autenticatore.isLoggedIn FALSE');

        // this.autenticatore.redirectUrl = url;

        // Navigate to the login page with extras
        console.log('AuthGuard not logged in so redirect to login page, /');
        this.router.navigate(['login']);
        return false;
    }

}
