<div class="p-grid" *ngIf="tariffa">

    <div class="p-col-12">
        <div class="card">
            <p-toolbar>
                <div class="p-toolbar-group-left">
                    <button pButton label="Save" (click)="onSubmit()" icon="fa fa-save" type="button" class="p-button-outlined" [disabled]="!myForm?.valid"></button>
                </div>
            </p-toolbar>
        </div>
    </div>

    <!-- Left Side -->
    <div class="p-col-12 p-md-6">
        <div class="card">
        <div class="card-header">
            <h2>Village e Family Room</h2>
        </div>

        <div class="ui-g form-group">
            <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
                    <div class="p-grid">
                    <div class="p-col-12 p-md-6">
                        <label for="tariffa1">Tariffa A</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="tariffa1" placeholder="Tariffa 1" type="number" formControlName="tariffa1" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa B</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 2" type="number" formControlName="tariffa2" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa C</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 3" type="number" formControlName="tariffa3" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa D</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 4" type="number" formControlName="tariffa4" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>


                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa E</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="tariffa1" placeholder="Tariffa 5" type="number" formControlName="tariffa5" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa F</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 6" type="number" formControlName="tariffa6" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa G</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 7" type="number" formControlName="tariffa7" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa H</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 8" type="number" formControlName="tariffa8" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa I</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="tariffa1" placeholder="Tariffa 9" type="number" formControlName="tariffa9" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa L</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 10" type="number" formControlName="tariffa10" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa M</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 11" type="number" formControlName="tariffa11" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa N</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 12" type="number" formControlName="tariffa12" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>


                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa O</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="tariffa1" placeholder="Tariffa 13" type="number" formControlName="tariffa13" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa P</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 14" type="number" formControlName="tariffa14" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>
                    </div>
                </form>
            </div>

        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="card">
        <div class="card-header">
            <h2>Hotel Palace</h2>
        </div>
            <div class="ui-g form-group">
                <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
                    <div class="p-grid">

                    <div class="p-col-12 p-md-6">
                        <label for="Animali">Tariffa A</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="tariffa1" placeholder="Tariffa 1" type="number" formControlName="tariffaHp1" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa B</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 2" type="number" formControlName="tariffaHp2" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa C</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 3" type="number" formControlName="tariffaHp3" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa D</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 4" type="number" formControlName="tariffaHp4" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>


                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa E</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="tariffa1" placeholder="Tariffa 5" type="number" formControlName="tariffaHp5" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa F</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 6" type="number" formControlName="tariffaHp6" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa G</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 7" type="number" formControlName="tariffaHp7" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa H</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 8" type="number" formControlName="tariffaHp8" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa I</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="tariffa1" placeholder="Tariffa 9" type="number" formControlName="tariffaHp9" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa L</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 10" type="number" formControlName="tariffaHp10" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa M</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 11" type="number" formControlName="tariffaHp11" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa N</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 12" type="number" formControlName="tariffaHp12" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>


                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa O</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="tariffa1" placeholder="Tariffa 13" type="number" formControlName="tariffaHp13" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa P</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 14" type="number" formControlName="tariffaHp14" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>
                    </div>
                </form>
            </div>

        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="card">
            <div class="card-header">
                <h2>Residence Monolocale</h2>
            </div>
            <div class="ui-g form-group">
                <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
                    <div class="p-grid">

                    <div class="p-col-12 p-md-6">
                        <label for="Animali">Tariffa A</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="tariffa1" placeholder="Tariffa 1" type="number" formControlName="tariffaResidenceMono1" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa B</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 2" type="number" formControlName="tariffaResidenceMono2" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa C</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 3" type="number" formControlName="tariffaResidenceMono3" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa D</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 4" type="number" formControlName="tariffaResidenceMono4" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>


                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa E</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="tariffa1" placeholder="Tariffa 5" type="number" formControlName="tariffaResidenceMono5" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa F</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 6" type="number" formControlName="tariffaResidenceMono6" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa G</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 7" type="number" formControlName="tariffaResidenceMono7" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa H</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 8" type="number" formControlName="tariffaResidenceMono8" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa I</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="tariffa1" placeholder="Tariffa 9" type="number" formControlName="tariffaResidenceMono9" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa L</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 10" type="number" formControlName="tariffaResidenceMono10" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa M</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 11" type="number" formControlName="tariffaResidenceMono11" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa N</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 12" type="number" formControlName="tariffaResidenceMono12" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa O</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 13" type="number" formControlName="tariffaResidenceMono13" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa P</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 14" type="number" formControlName="tariffaResidenceMono14" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    </div>
                </form>
            </div>

        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="card no-margin">
            <h2>Residence Trilocale</h2>
            <div class="ui-g form-group">
                <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
                    <div class="p-grid">

                    <div class="p-col-12 p-md-6">
                        <label for="Animali">Tariffa A</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="tariffa1" placeholder="Tariffa 1" type="number" formControlName="tariffaResidenceTrilo1" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa B</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 2" type="number" formControlName="tariffaResidenceTrilo2" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa C</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 3" type="number" formControlName="tariffaResidenceTrilo3" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa D</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 4" type="number" formControlName="tariffaResidenceTrilo4" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>


                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa E</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="tariffa1" placeholder="Tariffa 5" type="number" formControlName="tariffaResidenceTrilo5" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa F</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 6" type="number" formControlName="tariffaResidenceTrilo6" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa G</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 7" type="number" formControlName="tariffaResidenceTrilo7" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa H</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" placeholder="Tariffa 8" type="number" formControlName="tariffaResidenceTrilo8" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa I</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="tariffa1" placeholder="Tariffa 9" type="number" formControlName="tariffaResidenceTrilo9" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa L</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" type="number" formControlName="tariffaResidenceTrilo10" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa M</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" type="number" formControlName="tariffaResidenceTrilo11" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa N</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" type="number" formControlName="tariffaResidenceTrilo12" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa O</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" type="number" formControlName="tariffaResidenceTrilo13" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <label for="sconto">Tariffa P</label>
                        <div class="p-col-12 p-inputgroup">
                            <input id="sconto" type="number" formControlName="tariffaResidenceTrilo14" pInputText />
                            <span class="p-inputgroup-addon">€</span>
                            <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>

                    </div>
                </form>
            </div>

        </div>
    </div>

</div>
