<h4>Reusable CSS widgets for your applications.</h4>

<div class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<h4>Overview Boxes</h4>
			<div class="p-grid">
				<div class="p-col-12 p-md-6 p-xl-3">
					<div class="card no-gutter widget-overview-box widget-overview-box-1">
                                <span class="overview-icon">
                                    <i class="pi pi-shopping-cart"></i>
                                </span>
						<span class="overview-title">Orders</span>
						<div class="p-grid overview-detail">
							<div class="p-col-6">
								<div class="overview-number">640</div>
								<div class="overview-subtext">Pending</div>
							</div>
							<div class="p-col-6">
								<div class="overview-number">1420</div>
								<div class="overview-subtext">Completed</div>
							</div>
						</div>
					</div>
				</div>
				<div class="p-col-12 p-md-6 p-xl-3">
					<div class="card no-gutter widget-overview-box widget-overview-box-2">
                                <span class="overview-icon">
                                    <i class="pi pi-dollar"></i>
                                </span>
						<span class="overview-title">Revenue</span>
						<div class="p-grid overview-detail">
							<div class="p-col-6">
								<div class="overview-number">$2,100</div>
								<div class="overview-subtext">Expenses</div>
							</div>
							<div class="p-col-6">
								<div class="overview-number">$9,640</div>
								<div class="overview-subtext">Income</div>
							</div>
						</div>
					</div>
				</div>
				<div class="p-col-12 p-md-6 p-xl-3">
					<div class="card no-gutter widget-overview-box widget-overview-box-3">
                                <span class="overview-icon">
                                    <i class="pi pi-users"></i>
                                </span>
						<span class="overview-title">Customers</span>
						<div class="p-grid overview-detail">
							<div class="p-col-6">
								<div class="overview-number">8272</div>
								<div class="overview-subtext">Active</div>
							</div>
							<div class="p-col-6">
								<div class="overview-number">25402</div>
								<div class="overview-subtext">Registered</div>
							</div>
						</div>
					</div>
				</div>
				<div class="p-col-12 p-md-6 p-xl-3">
					<div class="card no-gutter widget-overview-box widget-overview-box-4">
                                <span class="overview-icon">
                                    <i class="pi pi-comment"></i>
                                </span>
						<span class="overview-title">Comments</span>
						<div class="p-grid overview-detail">
							<div class="p-col-6">
								<div class="overview-number">12</div>
								<div class="overview-subtext">New</div>
							</div>
							<div class="p-col-6">
								<div class="overview-number">85</div>
								<div class="overview-subtext">Responded</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="p-col-12 p-lg-4">
		<div class="card widget-tasks">
			<h4>Task Status</h4>
			<div>
				<div class="task task-1">
					<div class="task-name"><span>12 Orders</span> to fulfill</div>
					<div class="task-progress">
						<div></div>
					</div>
				</div>
				<div class="task task-2">
					<div class="task-name"><span>40+ Payments</span> to withdraw</div>
					<div class="task-progress">
						<div></div>
					</div>
				</div>
				<div class="task task-3">
					<div class="task-name"><span>4 Reports</span> to revise</div>
					<div class="task-progress">
						<div></div>
					</div>
				</div>
				<div class="task task-4">
					<div class="task-name"><span>6 Questions</span> to respond</div>
					<div class="task-progress">
						<div></div>
					</div>
				</div>
				<div class="task task-5">
					<div class="task-name"><span>2 Chargebacks</span> to review</div>
					<div class="task-progress">
						<div></div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="p-col-12 p-lg-4">
		<div class="card">
			<h4>Image List</h4>

			<ul class="widget-image-list">
				<li>
					<span>Product</span>
					<span>Sales</span>
				</li>
				<li>
					<span>
						<img src="assets/demo/images/product/bamboo-watch.jpg" alt="diamond-layout" />
						<span>Bamboo Watch</span>
					</span>
					<span class="listitem-value">82</span>
				</li>
				<li>
					<span>
						<img src="assets/demo/images/product/blue-band.jpg" alt="diamond-layout" />
						<span>Blue Band</span>
					</span>
					<span class="listitem-value">75</span>
				</li>
				<li>
					<span>
						<img src="assets/demo/images/product/game-controller.jpg" alt="diamond-layout" />
						<span>Game Controller</span>
					</span>
					<span class="listitem-value">64</span>
				</li>
				<li>
					<span>
						<img src="assets/demo/images/product/lime-band.jpg" alt="diamond-layout" />
						<span>Lime Band</span>
					</span>
					<span class="listitem-value">62</span>
				</li>
			</ul>
		</div>
	</div>

	<div class="p-col-12 p-lg-4">
		<div class="widget-pricing-card">
			<h4>PRICING</h4>
			<span>Starting from</span>
			<h3>$19</h3>
			<ul>
				<li>Responsive Layout</li>
				<li>Unlimited Push Messages</li>
				<li>50 Support Tickets</li>
				<li>Free Shipping</li>
				<li>10GB Storage</li>
			</ul>
			<a href="#">BUY NOW</a>
		</div>
	</div>

	<div class="p-col-12 p-lg-3">
		<div class="card">
			<h4>Timeline</h4>
			<div class="widget-timeline">
				<div class="timeline-event">
					<span class="timeline-event-icon" style="background-color:#64B5F6">
						<i class="pi pi-dollar"></i>
					</span>
					<div class="timeline-event-title">New Sale</div>
					<div class="timeline-event-detail">Richard Jones has purchased a blue t-shirt for <strong>$79</strong>.</div>
				</div>
				<div class="timeline-event">
					<span class="timeline-event-icon" style="background-color:#7986CB">
						<i class="timeline-icon pi pi-download"></i>
					</span>
					<div class="timeline-event-title">Withdrawal Initiated</div>
					<div class="timeline-event-detail">Your request for withdrawal of <strong>$2500</strong> has been initiated.</div>
				</div>
				<div class="timeline-event">
					<span class="timeline-event-icon" style="background-color:#4DB6AC">
						<i class="timeline-icon pi pi-question"></i>
					</span>
					<div class="timeline-event-title">Question Received</div>
					<div class="timeline-event-detail">Jane Davis has posted a <strong>new question</strong> about your product.</div>
				</div>
				<div class="timeline-event">
					<span class="timeline-event-icon" style="background-color:#4DD0E1">
						<i class="timeline-icon pi pi-comment"></i>
					</span>
					<div class="timeline-event-title">Comment Received</div>
					<div class="timeline-event-detail">Claire Smith has upvoted your store along with a <strong>comment</strong>.</div>
				</div>
			</div>
		</div>
	</div>

	<div class="p-col-12 p-lg-9">
		<div class="card">
			<h4>Chat</h4>
			<div class="widget-chat">
				<ul>
					<li class="message-from">
						<img src="assets/demo/images/avatar/ionibowcher.png" alt="diamond-layout" />
						<div>Retro occupy organic, stumptown shabby chic pour-over roof party DIY normcore.</div>
					</li>
					<li class="message-own">
						<img src="assets/demo/images/avatar/onyamalimba.png" alt="diamond-layout" />
						<div>Actually artisan organic occupy, Wes Anderson ugh whatever pour-over gastropub selvage.</div>
					</li>
					<li class="message-from">
						<img src="assets/demo/images/avatar/ionibowcher.png" alt="diamond-layout" />
						<div>Chillwave craft beer tote bag stumptown quinoa hashtag.</div>
					</li>
					<li class="message-own">
						<img src="assets/demo/images/avatar/onyamalimba.png" alt="diamond-layout" />
						<div>Dreamcatcher locavore iPhone chillwave, occupy trust fund slow-carb distillery art party narwhal.</div>
					</li>
				</ul>
				<div class="new-message">
					<div class="message-attachment">
						<i class="pi pi-paperclip"></i>
					</div>
					<div class="message-input">
						<input type="text" placeholder="Write a message" class="p-inputtext" />
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="p-col-12 p-lg-4">
		<div class="card">
			<h4>Person List</h4>
			<ul class="widget-person-list">
				<li>
					<div class="person-item">
						<img src="assets/demo/images/avatar/amyelsner.png" alt="diamond-layout" />
						<div class="person-info">
							<div class="person-name">Amy Elsner</div>
							<div class="person-subtext">Accounting</div>
						</div>
					</div>
					<div class="person-actions">
						<button type="button" class="p-button-rounded p-button-success" pButton icon="pi pi-envelope"></button>
						<button type="button" class="p-button-rounded p-button-warning" pButton icon="pi pi-cog" ></button>
					</div>
				</li>
				<li>
					<div class="person-item">
						<img src="assets/demo/images/avatar/annafali.png" alt="diamond-layout" />
						<div class="person-info">
							<div class="person-name">Anna Fali</div>
							<div class="person-subtext">Procurement</div>
						</div>
					</div>
					<div class="person-actions">
						<button type="button" class="p-button-rounded p-button-success" pButton icon="pi pi-envelope" ></button>
						<button type="button" class="p-button-rounded p-button-warning" pButton icon="pi pi-cog"></button>
					</div>
				</li>
				<li>
					<div class="person-item">
						<img src="assets/demo/images/avatar/bernardodominic.png" alt="diamond-layout" />
						<div class="person-info">
							<div class="person-name">Bernardo Dominic</div>
							<div class="person-subtext">Finance</div>
						</div>
					</div>
					<div class="person-actions">
						<button type="button" class="p-button-rounded p-button-success" pButton icon="pi pi-envelope" ></button>
						<button type="button" class="p-button-rounded p-button-warning" pButton icon="pi pi-cog" ></button>
					</div>
				</li>
				<li>
					<div class="person-item">
						<img src="assets/demo/images/avatar/ivanmagalhaes.png" alt="diamond-layout" />
						<div class="person-info">
							<div class="person-name">Ivan Magalhaes</div>
							<div class="person-subtext">Sales</div>
						</div>
					</div>
					<div class="person-actions">
						<button type="button" class="p-button-rounded p-button-success" pButton icon="pi pi-envelope" ></button>
						<button type="button" class="p-button-rounded p-button-warning" pButton icon="pi pi-cog"></button>
					</div>
				</li>
				<li>
					<div class="person-item">
						<img src="assets/demo/images/avatar/xuxuefeng.png" alt="diamond-layout" />
						<div class="person-info">
							<div class="person-name">Xuxue Feng</div>
							<div class="person-subtext">Management</div>
						</div>
					</div>
					<div class="person-actions">
						<button type="button" class="p-button-rounded p-button-success" pButton icon="pi pi-envelope" ></button>
						<button type="button" class="p-button-rounded p-button-warning" pButton icon="pi pi-cog" ></button>
					</div>
				</li>
			</ul>
		</div>
	</div>

	<div class="p-col-12 p-lg-4">
		<div class="card widget-user-card">
			<div class="user-card-header">
				<img src="assets/demo/images/avatar/profile.jpg" class="user-card-avatar" alt="diamond-layout" />
			</div>
			<div class="user-card-body">
				<div class="user-card-title">AMELIA STONE</div>
				<div class="user-card-subtext">Sales Team</div>

				<div class="p-grid user-card-stats">
					<div class="p-col-4">
						<i class="pi pi-users"></i>
						<div>14 Clients</div>
					</div>
					<div class="p-col-4">
						<i class="pi pi-bookmark"></i>
						<div>2 Leads</div>
					</div>
					<div class="p-col-4">
						<i class="pi pi-check-square"></i>
						<div>6 Tasks</div>
					</div>
				</div>

				<button type="button" label="Assign" pButton style="width: 100%"></button>
			</div>
		</div>
	</div>

	<div class="p-col-12 p-lg-4">
		<div class="card p-fluid">
			<h4>Contact Form</h4>
			<div class="p-field">
				<label for="firstname">First Name</label>
				<input pInputText id="firstname" />
			</div>
			<div class="p-field">
				<label for="lastname">Last Name</label>
				<input pInputText id="lastname" />
			</div>
			<div class="p-field">
				<label for="email">Email</label>
				<input pInputText id="email" />
			</div>
			<div class="p-field">
				<label for="message">Message</label>
				<textarea pInputTextarea id="message" ></textarea>
			</div>
			<button type="button" label="Send" pButton></button>
		</div>
	</div>

	<div class="p-col-12">
		<div class="card">
			<h4>Feature Box</h4>
			<div class="p-grid">
				<div class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box">
						<img src="assets/layout/images/pages/landing/icon-devices.svg" alt="diamond-layout" />
						<h2>Responsive</h2>
						<span>Nam non ligula sed urna malesuada lacinia. Aliquam sed viverra ipsum.</span>
					</div>
				</div>
				<div class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box">
						<img src="assets/layout/images/pages/landing/icon-design.svg" alt="diamond-layout" />
						<h2>Modern Design</h2>
						<span>Nam non ligula sed urna malesuada lacinia. Aliquam sed viverra ipsum.</span>
					</div>
				</div>
				<div class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box">
						<img src="assets/layout/images/pages/landing/icon-document.svg" alt="diamond-layout" />
						<h2>Well Documented</h2>
						<span>Nam non ligula sed urna malesuada lacinia. Aliquam sed viverra ipsum.</span>
					</div>
				</div>
				<div class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box">
						<img src="assets/layout/images/pages/landing/icon-diamond.svg" alt="diamond-layout" />
						<h2>Premium Support</h2>
						<span>Nam non ligula sed urna malesuada lacinia. Aliquam sed viverra ipsum.</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
