<div class="layout-dashboard" *ngIf="camera">

    <div class="p-card" *ngIf="!ticket">
        
        <p-toolbar>
            <div class="ui-toolbar-group-left">
                <span>
                    <button pButton label="SALVA" (click)="onSubmit()" icon="fa fa-save" type="button" [disabled]="!myForm.valid" class="p-button-outlined p-mr-2 p-mb-2"></button>
                    <button label="ELIMINA" (click)="elimina()" icon="fa fa-trash" pButton type="button" class="p-button-danger p-button-outlined p-mr-2 p-mb-2" ></button>
                    <button label="CHIUDI" (click)="annulla()" icon="fa fa-times" pButton type="button" class="p-button-warning p-button-outlined p-mr-2 p-mb-2" ></button>
                </span>
            </div>
            <!--<div class="ui-toolbar-group-right"><p-splitButton class="green-btn" label="Connetti" icon="fa fa-phone" [model]="items"></p-splitButton></div>-->
        </p-toolbar>
    </div>

    <div class="p-card" *ngIf="ticket">
        <app-ticket [ticket]="ticket" [camera]="camera"  (chiudi)='deselezionaTicket()'></app-ticket>
    </div>

    <br/><br/>

    <div class="p-card" *ngIf="!ticket">

            <p-panel header="Dati Camera">
                <div class="p-grid">
                    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">

                        <div class="p-col-12">
                            <p-selectButton [options]="strutture" formControlName="sistemazione"></p-selectButton>
                        </div>

                        <div class="p-col-12">
                            <input id="nome" placeholder="nome" style="width: 100%" type="text" formControlName="nome" pInputText/>
                            <span class="errore" *ngIf="(myForm.get('nome').touched || myForm.get('nome').dirty ) && myForm.get('nome').errors">
                               <span *ngIf="myForm.get('nome').errors">manca il nome della Camera</span>
                            </span>
                        </div>
            </form>
                </div>
            </p-panel>
    </div>
</div>
