<div class="layout-dashboard">

    <div class="ui-g" *ngIf="!ticket">
        <div class="card">
            <p-toolbar>
                <div class="p-toolbar-group-left">
                    <span class="p-buttonset">
                        <button pButton pRipple label="Tutti" [ngClass]="filtro !== 'TUTTI' ? '' : 'p-button-secondary'" (click)="filtraTutti()"></button>
                        <button pButton pRipple label="Solo i miei Tickets" [ngClass]="filtro !== 'MIEI' ? '' : 'p-button-secondary'" (click)="filtraMiei()"></button>
                        <button pButton pRipple label="Chiusi" [ngClass]="filtro !== 'CHIUSI' ? '' : 'p-button-secondary'" (click)="filtraChiusi()"></button>
                        <button pButton pRipple label="In lavorazione" [ngClass]="filtro !== 'LAVORAZIONE' ? '' : 'p-button-secondary'" (click)="filtraLavorazione()"></button>
                    </span>
                </div>
                <div class="p-toolbar-group-right">
                    <button pButton pRipple type="button" pTooltip="CREA UN NUOVO TICKET" class="p-button-rounded p-button-outlined p-mr-2 p-mb-2" (click)="nuovo()" icon="fa fa-plus"></button>
                </div>
            </p-toolbar>
        </div>

    </div>

    <div class="ui-g-12" >
        <app-ticket *ngIf="ticket" [ticket]="ticket"  (chiudi)='deseleziona()'></app-ticket>
        <app-tickets *ngIf="!ticket && tickets.length > 0" [tickets]="tickets"  (seleziona)='selezionaTicket($event)'></app-tickets>
        <app-tickets *ngIf="!ticket && mieiTickets.length > 0" [tickets]="mieiTickets"  (seleziona)='selezionaTicket($event)'></app-tickets>
    </div>


</div>
