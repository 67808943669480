<div class="layout-dashboard">

    <p-table #dt [columns]="cols" [responsive]="true" [value]="messaggi" dataKey="id">

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width: 3em;text-align: center"></th>
                    <th style="width: 3em;text-align: center"></th>
                    <th style="width: 9em;text-align: center">Data</th>
                    <th style="text-align: center">Oggetto</th>
                    <th style="text-align: center">Da</th>
                    <th style="width: 6em;text-align: center"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-cols="columns">
                <tr>
                    <td style="text-align: center">
                        <a href="#" [pRowToggler]="rowData">
                            <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                        </a>
                    </td>
                    <td style="text-align: center" pTooltip="{{rowData.letto ? 'MESSAGGIO LETTO' : 'MESSAGGIO DA LEGGERE'}}" ><i [ngClass]="rowData.letto ? 'fa fa-envelope-open-o' : 'fa fa-envelope-o'" [style.color]="rowData.letto ? 'green' : 'red'"></i></td>
                    <td style="text-align: center">{{rowData.data.toDate() | date :'shortDate'}}</td>
                    <td style="text-align: center">{{rowData.oggetto}}</td>
                    <td style="text-align: center">{{rowData.mittenteNominativo}}</td>
                    <!--<td style="text-align: center"><a style="cursor: pointer;" (click)="selezionaMessaggio(rowData)">[dettaglio]</a></td>-->
                    <td style="text-align: center"><a style="cursor: pointer;" (click)="leggi(rowData)">[letto]</a></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
                <tr>
                    <td [attr.colspan]="6">
                        <div class="ui-g ui-fluid" style="font-size:16px;padding:20px">
                            <div *ngIf="rowData.letto" class="ui-g-12">
                                Messaggio letto il {{rowData.dataLettura.toDate() | date}} ore {{rowData.dataLettura.toDate() | date:"shortTime"}}
                            </div>
                            <div class="ui-g-12" [innerHTML]="rowData.testo"></div>
                            <div class="ui-g-4">
                                <button pButton label="Segna come Letto" (click)="leggi(rowData)" icon="fa fa-eye" type="button"  ></button>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
    </p-table>

    <div class="card" *ngIf="messaggi?.length === 0">
        <h1>Non ho trovato alcun messaggio</h1>
        <br/><br/><br/><br/><br/><br/><br/>
        <br/><br/><br/><br/><br/><br/><br/>
</div>

<div class="p-col-12" *ngIf="messaggio">
    <app-messaggio [showToolbar]="false" (chiudi)='deseleziona()' (messaggioInviato)='deseleziona()' [messaggio]="messaggio"></app-messaggio>
</div>

</div>
