import { firestore } from 'firebase';
import { Ruolo } from '../enumeratori/ruolo';

export class Operatore {
    id?:                    string;
    email?:                 string;
    dataCreazione:          firestore.Timestamp;
    nome?:                  string;
    cognome?:               string;
    citta?:                 string;
    sigla?:                 string;
    ruolo?:                 Ruolo;

    constructor() {
        this.dataCreazione = firestore.Timestamp.fromDate(new Date());
    }

}
