import { firestore } from 'firebase';
import { Tgate } from './tgate';
import { Varco } from './varco';
import { StatoTicket } from '../enumeratori/stato-ticket';
import { TipoTicket } from './tipo-ticket';

export class Ticket {
    id?:                   string;
    stato?:                StatoTicket;
    creatoDaNome?:         string;
    creatoDaRuolo?:        string;
    creatoDa?:             string; // ID
    codice?:               string;
    cameraNome?:           string;
    cameraId?:             string;
    cameraStruttura?:   string;

    dataCreazione:         firestore.Timestamp;
    dataCreazioneHuman:    string;
    dataLavorazione:       firestore.Timestamp;
    dataChiusura:          firestore.Timestamp;
    dataAnnulla:           firestore.Timestamp;

    tempoLavorazione:      number;
    tempoChisura:          number;

    oggetto?:              string;
    messaggio?:            string;
    descrizione?:          string;
    ospite?:               string;
    tipo?:                 TipoTicket; // da eliminare. Non serve piu' per discriminare tra Cliente e Rivenditore

    constructor(_ospite?: string, _cameraId?: string, _cameraNome?: string, _cameraSistemazione?: string) {

        this.dataCreazione = firestore.Timestamp.fromDate(new Date());
        this.stato = StatoTicket.APERTO;
        this.tipo = TipoTicket.ALTRO;
        this.ospite = _ospite;
        this.cameraNome = _cameraNome;
        this.cameraId = _cameraId;
        this.cameraStruttura = _cameraSistemazione;
      }
}
