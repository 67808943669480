
import { firestore } from 'firebase';
import { StatoPreventivo } from './stato-preventivo';
import { Sistemazioni } from './sistemazioni';
import { Cliente } from './cliente';
import * as moment from 'moment-timezone';
import { TipoResidence } from '../enumeratori/tipo-residence';
import { Costanti } from '../services/costanti';

export class Preventivo {

    id?: string;
    uid?: string;
    numero?: string;
    stato?: string;
    erroreStripe?: string; // eventuale errore generato da Stripe
    checkIn?: firestore.Timestamp;
    checkOut?: firestore.Timestamp;
    notti?:  number;
    pax?:  number;
    sistemazione?: string;
    clientSecret?: string; // codice Stripe
    inviato?: boolean; // preventivo gia' inviato via e-mail

    operatore?: string;
    modalita?: string;

    date?: Date[];
    // date?: firestore.Timestamp[];

    dataScadenza?: firestore.Timestamp;
    dataCreazione?: firestore.Timestamp;
    dataModifica?: firestore.Timestamp;

    adulti?:  number;  // >= 12
    // bambini?:  number; // 3 - 11,99
    // bambiniFree?:  number; // 3 - 11,99
    infanti?:  number; // 0 - 2,99

    letto3?: boolean;
    letto5?: boolean;
    letto6?: boolean;

    chd3?: boolean;
    chd4?: boolean;
    adult3?: boolean;
    adult4?: boolean;

    costoChd4?: number;
    costoAdl3?: number;
    costoAdl4?: number;

    tessereClub?:  number; // importo
    tessereInfant?: number; // importo
    numeroTessereClub?: number;

    trattamento?: string; // messaggio del tipo: 'Pensione Completa, bevande incluse ai pasti (acqua,vino, birra e soft drink)
    letti?: string; // messaggio del tipo: '1 letto matrimoniale + letto

    aliquotaSconto?:  number;
    sconto?:  number;



    note?:  string;

    cliente?: string;
    clienteNominativo: string;
    clienteNome: string;
    clienteCognome: string;
    clienteTelefono: string;
    clienteEmail: string;
    clienteCitta: string;

    opModifica?: string;
    opCreazione?: string;


    tariffa?:  number;

    // RESIDENCE
    tipoResidence?: string; // trilocale 1 monolocale
    forfaitConsumi?: number;

    // supplementi e riduzioni
    supplementoNotte?: number;
    supplementoVistaMare?: number;
    supplementoDus?: number;
    supplementoBalconeVeranda?: number;
    supplementoAnimali?: number;
    supplementoSingola?: number;
    riduzioneMezzaPensione?: number;
    supplementoLetto3: number;
    supplementoLetto5: number;
    supplementoLetto6: number;


    totaleAdulti?: number; // adulti x tariffa media x notti
    subTotaleLordo?: number;
    subTotale?: number;
    totaleLordo?: number;
    totale?:  number;


    vistaMare?: boolean;
    balcone?: boolean; // balcone o veranda
    // finestra?: boolean; // e' solo un flag che viene mostrato nella mail
    economy?: boolean; // village con balcone (+10%) con sconto del 15%
    dus?: boolean;
    animali?: boolean;
    mezzaPensione?: boolean;
    specialCase?: boolean; // FR con 1 ADL e 1 CHD


    constructor(_uid: string, cliente: Cliente) {

        this.uid = _uid;
        this.dataCreazione = firestore.Timestamp.fromDate(new Date());
        this.dataScadenza = firestore.Timestamp.fromDate(moment().tz('Europe/Rome').add(Costanti.GIORNI_SCADENZA_PREVENTIVO, 'day').toDate());
        this.stato = StatoPreventivo.IN_CORSO;
        this.sconto = 0;
        this.sistemazione = Sistemazioni.HOTEL ;
        this.tipoResidence = TipoResidence.MONOLOCALE ;

        // CLIENTE
        this.cliente = cliente.id;
        this.clienteNominativo = cliente.nome + ' ' + cliente.cognome;
        this.clienteNome = cliente.nome;
        this.clienteCognome = cliente.cognome;
        this.clienteCitta = cliente.citta;
        this.clienteTelefono = cliente.telefono;
        this.clienteEmail = cliente.email;
    }


}
