import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';

import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';

import {AppCodeModule} from './app.code.component';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppConfigComponent} from './app.config.component';
import {AppRightmenuComponent} from './app.rightmenu.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppSearchComponent} from './app.search.component';
import {AppFooterComponent} from './app.footer.component';
import {DashboardDemoComponent} from './demo/view/dashboarddemo.component';
import {FormLayoutDemoComponent} from './demo/view/formlayoutdemo.component';
import {InputDemoComponent} from './demo/view/inputdemo.component';
import {ButtonDemoComponent} from './demo/view/buttondemo.component';
import {TableDemoComponent} from './demo/view/tabledemo.component';
import {ListDemoComponent} from './demo/view/listdemo.component';
import {TreeDemoComponent} from './demo/view/treedemo.component';
import {PanelsDemoComponent} from './demo/view/panelsdemo.component';
import {OverlaysDemoComponent} from './demo/view/overlaysdemo.component';
import {MediaDemoComponent} from './demo/view/mediademo.component';
import {MenusDemoComponent} from './demo/view/menusdemo.component';
import {MessagesDemoComponent} from './demo/view/messagesdemo.component';
import {MiscDemoComponent} from './demo/view/miscdemo.component';
import {EmptyDemoComponent} from './demo/view/emptydemo.component';
import {ChartsDemoComponent} from './demo/view/chartsdemo.component';
import {FileDemoComponent} from './demo/view/filedemo.component';
import {DocumentationComponent} from './demo/view/documentation.component';
import {DisplayComponent} from './utilities/display.component';
import {ElevationComponent} from './utilities/elevation.component';
import {FlexboxComponent} from './utilities/flexbox.component';
import {GridComponent} from './utilities/grid.component';
import {IconsComponent} from './utilities/icons.component';
import {WidgetsComponent} from './utilities/widgets.component';
import {SpacingComponent} from './utilities/spacing.component';
import {TypographyComponent} from './utilities/typography.component';
import {TextComponent} from './utilities/text.component';
import {AppCrudComponent} from './pages/app.crud.component';
import {AppCalendarComponent} from './pages/app.calendar.component';
import {AppInvoiceComponent} from './pages/app.invoice.component';
import {AppHelpComponent} from './pages/app.help.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';

// LOCALE

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt);

import {CountryService} from './demo/service/countryservice';
import {CustomerService} from './demo/service/customerservice';
import {EventService} from './demo/service/eventservice';
import {IconService} from './demo/service/iconservice';
import {NodeService} from './demo/service/nodeservice';
import {PhotoService} from './demo/service/photoservice';
import {ProductService} from './demo/service/productservice';
import {BreadcrumbService} from './app.breadcrumb.service';
import {MenuService} from './app.menu.service';
import { AuthGuard } from './guard/AuthGuard';
import { AutenticatoreService } from './services/autenticatore.service';
import { SessioneService } from './services/sessione.service';
import { PreventivoService } from './services/preventivo.service';
import { TicketService } from './services/ticket.service';
import { EmailService } from './services/email.service';
import { MessaggioService } from './services/messaggio.service';
import { UtenteService } from './services/utente.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DAService } from './services/da.service';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import {DashboardComponent} from './view/dashboard/dashboard.component';
import {PagamentiViewComponent} from './view/pagamenti-view/pagamenti-view.component';
import {PagamentiNewViewComponent} from './view/pagamenti-new-view/pagamenti-new-view.component';
import {PagamentiComponent} from './components/pagamenti/pagamenti.component';
import {CamereComponent} from './components/camere/camere.component';
import {CameraComponent} from './components/camera/camera.component';
import {PagamentoComponent} from './components/pagamento/pagamento.component';
import {PreventiviViewComponent} from './view/preventivi/preventivi-view.component';
import {OperatoriViewComponent} from './view/operatori-view/operatori-view.component';
import {ClientiViewComponent} from './view/clienti-view/clienti-view.component';
import {TicketsViewComponent} from './view/tickets-view/tickets.view.component';
import {CamereViewComponent} from './view/camere-view/camere-view.component';
import {AccountViewComponent} from './view/account-view/account-view.component';
import {MessaggiViewComponent} from './view/messaggi/messaggi.view.component';
import {PreventiviComponent} from './components/preventivi/preventivi.component';
import {TicketComponent} from './components/ticket/ticket.component';
import {TicketsComponent} from './components/tickets/tickets.component';
import {PreventivoComponent} from './components/preventivo/preventivo.component';
import {RichiestePreventivoViewComponent} from './view/richieste-preventivo-view/richieste-preventivo-view.component';
import {TicketsApertiComponent} from './components/tickets-aperti/tickets-aperti.component';
import {AccountComponent} from './components/account/account.component';
import {MessaggiComponent} from './components/messaggi/messaggi.component';
import {OperatoreComponent} from './components/operatore/operatore.component';
import {ClienteComponent} from './components/cliente/cliente.component';
import {MessaggioComponent} from './components/messaggio/messaggio.component';
import {AppShopComponent} from './pages/app.shop.component';
import {ParametriComponent} from './view/parametri/parametri.component';
import {TariffeComponent} from './view/tariffe/tariffe.component';
import { MomentModule } from 'ngx-moment';
import { CellularePipe } from './pipe/cellulare.pipe';
import { DataPipe } from './pipe/data.pipe';
import { OsPipe } from './pipe/os.pipe';
import { IconaCameraPipe } from './pipe/iconaCamera.pipe';
import { IconaChiamataPipe } from './pipe/iconaChiamata.pipe';
import { IconaTicketPipe } from './pipe/iconaTicket.pipe';
import { StatoPipe } from './pipe/stato.pipe';
import { RuoloPipe } from './pipe/ruolo.pipe';
import { RuoloIconaPipe } from './pipe/ruolo.icona.pipe';
import { SistemazionePipe } from './pipe/sistemazione.pipe';
import { StrutturaPipe } from './pipe/struttura.pipe';
import { TicketPipe } from './pipe/ticket.pipe';
import { TroncaPipe } from './pipe/tronca.pipe';
import {EditorModule} from 'primeng/editor';
import { DataService } from './services/data.service';
import { UtilityService } from './services/utility.service';
import { ClienteService } from './services/cliente.service';
import { CameraService } from './services/camera.service';
import { PaymentService } from './services/payment.service';
import { NgxStripeModule, StripeService } from 'ngx-stripe';

@NgModule({
    imports: [

        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        
        NgxStripeModule.forRoot('pk_live_NJlPDiHOPwXcxqIJXsn9gYNe'), // Stripe live key
        // NgxStripeModule.forRoot('pk_test_XnwIradH4MgRqUnAdvnUYTcM'), // Stripe test key
   
        ReactiveFormsModule,
        EditorModule,
        MomentModule,
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        SelectButtonModule,
        SidebarModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule
    ],
    declarations: [

        // Pipes

        CellularePipe,
        DataPipe,
        OsPipe,
        IconaCameraPipe,
        IconaChiamataPipe,
        IconaTicketPipe,
        StatoPipe,
        RuoloPipe,
        RuoloIconaPipe,
        SistemazionePipe,
        StrutturaPipe,
        TicketPipe,
        TroncaPipe,

        // MY STUFF

        AccountComponent,
        AccountViewComponent,
        AppShopComponent,
        CameraComponent,
        CamereComponent,
        CamereViewComponent,
        ClienteComponent,
        ClientiViewComponent,
        DashboardComponent,
        MessaggiComponent,
        MessaggioComponent,
        MessaggiViewComponent,
        OperatoreComponent,
        OperatoriViewComponent,
        PagamentiViewComponent,
        PagamentiNewViewComponent,
        PagamentiComponent,
        PagamentoComponent,
        ParametriComponent,
        PreventivoComponent,
        PreventiviComponent,
        PreventiviViewComponent,
        RichiestePreventivoViewComponent,
        TariffeComponent,
        TicketsApertiComponent,
        TicketsViewComponent,
        TicketComponent,
        TicketsComponent,

        AppComponent,
        AppMainComponent,
        AppRightmenuComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppTopBarComponent,
        AppSearchComponent,
        AppFooterComponent,
        DashboardDemoComponent,
        FormLayoutDemoComponent,
        InputDemoComponent,
        ButtonDemoComponent,
        TableDemoComponent,
        ListDemoComponent,
        TreeDemoComponent,
        PanelsDemoComponent,
        OverlaysDemoComponent,
        MediaDemoComponent,
        MenusDemoComponent,
        MessagesDemoComponent,
        MessagesDemoComponent,
        MiscDemoComponent,
        ChartsDemoComponent,
        EmptyDemoComponent,
        FileDemoComponent,
        DocumentationComponent,
        DisplayComponent,
        ElevationComponent,
        FlexboxComponent,
        GridComponent,
        IconsComponent,
        WidgetsComponent,
        SpacingComponent,
        TypographyComponent,
        TextComponent,
        AppCrudComponent,
        AppCalendarComponent,
        AppLoginComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
    ],
    providers: [
        { provide:  LOCALE_ID, useValue: 'it-IT' },
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        AutenticatoreService, CameraService, ClienteService, ConfirmationService, DAService, DataService, EmailService, MessaggioService, PaymentService, PreventivoService, SessioneService, StripeService, TicketService, UtenteService, UtilityService,
        AuthGuard, CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService, MenuService, BreadcrumbService, MessageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
