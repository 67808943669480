
<div class="layout-dashboard">

        <div class="card" *ngIf="!cliente">
                <p-toolbar>
                    <div class="ui-toolbar-group-left">
                            <input [(ngModel)]="cognome" style="width: 200px;" (keyup.enter)="filtraPerCognome()" type="text" [maxLength]=10 onfocus="this.value=''" pInputText placeholder="Cerca per Cognome">
                            &nbsp;&nbsp;
                            <input [(ngModel)]="citta" style="width: 200px;" (keyup.enter)="filtraPerCitta()" type="text" [maxLength]=10 onfocus="this.value=''" pInputText placeholder="Cerca per Citta'">
                    </div>
                    <div class="ui-toolbar-group-right">
                        <button pButton type="button" label="NUOVO CLIENTE" title="CREA UN NUOVO CLIENTE"  (click)="nuovoCliente()" icon="fa fa-plus" class="p-button-rounded p-button-outlined"></button>
                    </div>
                </p-toolbar>
            </div>

    <div class="p-col-12" *ngIf="cliente && !preventivo">
        <app-cliente (chiudi)='deseleziona()' [cliente]="cliente"></app-cliente>
    </div>

    <div class="p-col-12" *ngIf="cliente && preventivo">
            <app-preventivo (chiudi)='deselezionaPreventivo()' [cliente]="cliente" [preventivo]="preventivo"></app-preventivo>
        </div>

    <div class="p-col-12 table-demo" *ngIf="clienti && clienti?.length > 0 && !cliente">

            <p-table #dt styleClass="p-datatable-customers" [columns]="cols" [responsive]="true" [value]="clienti" dataKey="id" exportFilename="clienti" sortField="DataChangePrimaryID" sortOrder="-1" [customSort]="true" [columns]="cols">

                <ng-template pTemplate="caption">
					<div class="table-header">
						Clienti
						<span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
						   placeholder="Filtra"/>
                </span>
					</div>
                </ng-template>
                
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 3em;text-align: center"></th>
                        <th style="width: 5em;text-align: center"></th>
                        <th style="text-align: center">Cognome</th>
                        <th style="text-align: center">Nome</th>
                        <th style="text-align: center">Citta'</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-cols="columns">
                    <tr>
                        <td>
                            <a href="#" [pRowToggler]="rowData">
                                <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                            </a>
                        </td>
                        <td style="text-align: center">
                            <a style="cursor: pointer;" (click)="creaPreventivo(rowData)"><i class="fa fa-eur" style="color: green;font-size: large"></i></a>
                            &nbsp;&nbsp;
                            <a style="cursor: pointer;" (click)="seleziona(rowData)"><i class="fa fa-search" style="color: yellow;font-size: large"></i></a>
                        </td>
                        <td style="text-align: center">{{rowData.cognome}}</td>
                        <td style="text-align: center">{{rowData.nome}}</td>
                        <td style="text-align: center">{{rowData.citta}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
                    <tr>
                        <td [attr.colspan]="columns.length + 2">
                            <div class="p-grid ui-fluid" style="font-size:16px;padding:20px">
                                        <div class="p-col-6">
                                            <b>Indirizzo:</b> {{rowData.indirizzo}}
                                        </div>
                                        <div class="p-col-6">
                                            <b>Telefono:</b> {{rowData.telefono}}
                                        </div>
                                        <div class="p-col-6">
                                            <b>E-mail:</b>&nbsp;&nbsp;<a href="mailto:{{rowData.email}}">{{rowData.email}}</a>
                                        </div>
                                        <div class="p-col-6">
                                            <b>Ultimo Preventivo:</b> {{rowData.preventivo ? rowData.preventivo : 'NP'}}
                                        </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

    </div>

    <div class="p-col-12" *ngIf="clienti?.length === 0 && !clienti">
        <p-panel header="Elenco Clienti">
            <h1>Non ho trovato alcun Cliente</h1>
        </p-panel>
    </div>

    </div>

