import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Camera } from 'src/app/domain/camera';
import { Account } from 'src/app/domain/account';
import { CameraService } from 'src/app/services/camera.service';
import { SessioneService } from 'src/app/services/sessione.service';

@Component({
    styleUrls: ['./tabella.scss'],
    selector: 'app-camere',
    templateUrl: './camere.component.html',
})
export class CamereComponent implements OnInit {

@Input() camere?: Camera[] = [];
@Output() seleziona = new EventEmitter<any>();
@Output() chiudi = new EventEmitter();

  cols: any[];

  data = new Date();

  utente?: Account;
  risultati?: any;

  constructor(
    private sessione: SessioneService,
    private cs: CameraService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}



  ngOnInit() {

    this.utente = this.sessione.operatoreLoggato;

    this.cols = [
        { field: 'nome', header: 'nome', style: 'center'},
        { field: 'sistemazione', header: 'Sistemazione', style: 'center'},
    ];

}

cameraPulita($event) {
    console.log('cameraPulita: ' + JSON.stringify($event));
    this.confirmationService.confirm({
        message: 'Sei sicuro, la camera e\' pulita ?',
        accept: () => {
            if (this.cs.cameraPulita($event, this.utente)) {
                this.messageService.add({ severity: 'success', summary: 'Camera', detail: 'Camera pulita !' });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Camera', detail: 'Spiacente, qualcosa e\' andato storto :(' });
            }

        }
    });
}

selezionaCamera($event) {
    this.seleziona.emit($event);
}

initCamera($event) {
    this.cs.inizializza($event);
}

}
