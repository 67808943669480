/**
 * AutenticatoreService
 *
 * Gestisce le proedure di autenticazione via Google e Facebook
 *
 * Per google utilizza AngularFireAuth
 * Per Facebook invece AuthService (angular4-social-login npm)
 *
 */
import { Injectable } from '@angular/core';
import { DAService } from './da.service';
import { Costanti } from './costanti';
import { SessioneService } from './sessione.service';
import { Preventivo } from '../domain/preventivo';
import { EmailService } from './email.service';
import { Cliente } from '../domain/cliente';
import { MessageService } from 'primeng/api';

@Injectable()
export class ClienteService {

  constructor(
    private messageService: MessageService,
    private emailService: EmailService,
    private sessione: SessioneService,
    private da: DAService
  ) {}

  setDocumentoRiconoscimentoOk(richiesta: Preventivo) {
    const data: any = {allegato: true};
    this.da.modifica(richiesta.id, Costanti.TABELLA_PREVENTIVI, data);
}

    /**
     *
     * Copia il varco passato come oggetto, modifica solo il nome
     *
     * @param tgate
     *
     */
    duplica(richiesta: Preventivo) {

        // rimuovo alcuni campi

        /*delete richiesta.id;
        delete richiesta.dataCreazione;
        delete richiesta.codice;

        // ne modifico altri

        const new_data = {
            nome: varco.nome + ' copy',
            dataCreazione: new Date(),
        };


        // duplico
        const data = {...richiesta, ...new_data};

        this.da.creaSenzaChiave(data, Costanti.TABELLA_RICHIESTE);*/
    }


elimina(cliente: Cliente) {
    console.log('elimina ' + cliente.id);
    this.da.elimina(cliente.id, Costanti.TABELLA_CLIENTI);
}


}
