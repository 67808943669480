import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ISubscription } from 'rxjs/Subscription';
import { Account } from 'src/app/domain/account';
import { Ticket } from 'src/app/domain/ticket';
import { StatoTicket } from 'src/app/enumeratori/stato-ticket';
import { SessioneService } from 'src/app/services/sessione.service';
import { TicketService } from 'src/app/services/ticket.service';

@Component({
    selector: 'app-tickets',
    templateUrl: './tickets.component.html',
})
export class TicketsComponent implements OnInit {

  @Input() tickets?: Ticket[] = [];
  @Output() seleziona = new EventEmitter<any>();

  cols: any[];

  data = new Date();

  utente?: Account;
  risultati?: any;

  private subscription: ISubscription;

  constructor(
    private sessione: SessioneService,
    private ticketService: TicketService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}



  ngOnInit() {

    this.utente = this.sessione.operatoreLoggato;

    this.cols = [
        { field: 'dataCreazioneHuman', header: 'Creato' },
        { field: 'creatoDaNome', header: 'Creato Da' },
        { field: 'creatoDaRuolo', header: 'Richiedente' },
        { field: 'oggetto', header: 'Oggetto' },
        { field: 'cameraNome', header: 'Camera' },
        { field: 'cameraStruttura', header: 'Struttura' },
        { field: 'ospite', header: 'Ospite' },
        { field: 'tempoLavorazione', header: 'Lavorato in' },
        { field: 'tempoChiusura', header: 'Chiuso in' },
    ];

}

  selezionaTicket(ticket: Ticket) {
    console.log('seleziona ticket ' + JSON.stringify(ticket));
    this.seleziona.emit(ticket);
}


inLavorazione(ticket: Ticket) {

    this.confirmationService.confirm({
        message: 'Sei sicuro di voler prendere in carico il Ticket ?',
        accept: () => {
            if (this.ticketService.inLavorazione(ticket, this.utente)) {
                this.messageService.add({ severity: 'success', summary: 'Ticket', detail: 'Il ticket e\' stato preso in carico' });
                ticket.stato = StatoTicket.IN_LAVORAZIONE;
            } else {
                this.messageService.add({ severity: 'error', summary: 'Ticket', detail: 'Spiacente, qualcosa e\' andato storto :(' });
            }

        }
    });

}

chiudiTicket(ticket: Ticket) {

    this.confirmationService.confirm({
        message: 'Sei sicuro di voler chiudere il Ticket ?',
        accept: () => {
            if (this.ticketService.chiudi(ticket, this.utente)) {
                this.messageService.add({ severity: 'success', summary: 'Ticket', detail: 'Il ticket e\' stato chiuso' });
                ticket.stato = StatoTicket.CHIUSO;
            } else {
                this.messageService.add({ severity: 'error', summary: 'Ticket', detail: 'Spiacente, qualcosa e\' andato storto :(' });
            }

        }
    });

}

annullaTicket(ticket: Ticket) {

    this.confirmationService.confirm({
        message: 'Sei sicuro di voler annullare il Ticket ?',
        accept: () => {
            if (this.ticketService.annulla(ticket, this.utente)) {
                this.messageService.add({ severity: 'success', summary: 'Ticket', detail: 'Il ticket e\' stato annullato' });
                ticket.stato = StatoTicket.ANNULLATO;
            } else {
                this.messageService.add({ severity: 'error', summary: 'Ticket', detail: 'Spiacente, qualcosa e\' andato storto :(' });
            }

        }
    });

}

elimina(ticket: Ticket) {

    this.confirmationService.confirm({
        message: 'Sei sicuro di voler cancellare il Ticket ?',
        accept: () => {
            this.ticketService.elimina(ticket);
        }
    });

}

}
