<div class="p-grid">
    <div class="p-col-12 p-lg-6">
        <div class="card p-fluid">
            <h5>Dialog</h5>
            <p-dialog header="Dialog" [(visible)]="display" modal="modal" showEffect="fade" [style]="{width: '400px'}">
                <div style="line-height: 1.5">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                    in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </div>
                <p-footer>
                    <button type="button" pButton icon="pi pi-check" (click)="display=false" label="Yes" class="p-button-text"></button>
                    <button type="button" pButton icon="pi pi-times" (click)="display=false" label="No" class="p-button-text"></button>
                </p-footer>
            </p-dialog>

            <button type="text" (click)="display=true" pButton icon="pi pi-external-link" label="Show"></button>
        </div>

        <div class="card p-fluid">
            <h5>Overlay Panel</h5>
            <div class="p-grid">
                <div class="p-col-6">
                    <button type="button" pButton label="Image" (click)="op1.toggle($event)" class="p-button-success"></button>
                    <p-overlayPanel #op1>
                        <img src="assets/demo/images/nature/nature1.jpg" alt="Nature 1" />
                    </p-overlayPanel>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton label="DataTable" (click)="op2.toggle($event)" class="p-button-success"></button>
                    <p-overlayPanel #op2 [showCloseIcon]="true" [style]="{width: '450px'}">
                        <ng-template pTemplate>
                            <p-table [value]="products" selectionMode="single" [(selection)]="selectedProduct" [paginator]="true" [rows]="5" (onRowSelect)="op2.hide()">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="name">Name<p-sortIcon field="name"></p-sortIcon></th>
                                        <th>Image</th>
                                        <th pSortableColumn="price">Price <p-sortIcon field="price"></p-sortIcon></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-product>
                                    <tr [pSelectableRow]="rowData">
                                        <td>{{product.name}}</td>
                                        <td><img src="assets/demo/images/product/{{product.image}}" [alt]="product.image" class="product-image" /></td>
                                        <td>{{product.price}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </ng-template>
                    </p-overlayPanel>
                </div>
            </div>
        </div>
    </div>

    <div class="p-col-12 p-lg-6">

        <div class="card p-fluid">
            <h5>Confirmation</h5>
            <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" message="Are you sure you want to proceed?"
                             [style]="{width: '425px'}" acceptButtonStyleClass="p-button-text" rejectButtonStyleClass="p-button-text"></p-confirmDialog>

            <button type="text" (click)="confirm()" pButton icon="pi pi-trash" label="Delete" class="p-button-danger"></button>
        </div>

        <div class="card">
            <h5>Sidebar</h5>
            <p-sidebar [(visible)]="visibleSidebar1" [baseZIndex]="10000">
                <h3 style="font-weight:normal">Left Sidebar</h3>
            </p-sidebar>

            <p-sidebar [(visible)]="visibleSidebar2" position="right" [baseZIndex]="10000">
                <h3 style="font-weight:normal">Right Sidebar</h3>
            </p-sidebar>

            <p-sidebar [(visible)]="visibleSidebar3" position="top" [baseZIndex]="10000">
                <h3 style="font-weight:normal">Top Sidebar</h3>
            </p-sidebar>

            <p-sidebar [(visible)]="visibleSidebar4" position="bottom" [baseZIndex]="10000">
                <h3 style="font-weight:normal">Bottom Sidebar</h3>
            </p-sidebar>

            <p-sidebar [(visible)]="visibleSidebar5" [fullScreen]="true" [baseZIndex]="10000">
                <h3 style="font-weight:normal">Full Screen Sidebar</h3>
            </p-sidebar>

            <button pButton type="button" (click)="visibleSidebar1 = true" icon="pi pi-arrow-right" class="p-button-warning"></button>
            <button pButton type="button" (click)="visibleSidebar2 = true" icon="pi pi-arrow-left" class="p-button-warning"></button>
            <button pButton type="button" (click)="visibleSidebar3 = true" icon="pi pi-arrow-down" class="p-button-warning"></button>
            <button pButton type="button" (click)="visibleSidebar4 = true" icon="pi pi-arrow-up" class="p-button-warning"></button>
            <button pButton type="button" (click)="visibleSidebar5 = true" icon="pi pi-th-large" class="p-button-warning"></button>
        </div>
    </div>
    <div class="p-col-12">
        <div class="card">
            <h5>Tooltip</h5>
            <div class="p-formgroup-inline">
                <div class="p-field">
                    <label for="firstname5" class="p-sr-only">Username</label>
                    <input id="firstname5" type="text" pInputText placeholder="Username" pTooltip="Enter your username">
                </div>
                <button pButton pRipple type="button" label="Submit" pTooltip="Click to proceed"></button>
            </div>
        </div>
    </div>
</div>
