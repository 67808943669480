/**
 * AutenticatoreService
 *
 * Gestisce le proedure di autenticazione via Google e Facebook
 *
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { Observable } from 'rxjs/Observable';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
// import { Subject } from 'rxjs/Subject';
// import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { BehaviorSubject, Observable, Subject } from 'rxjs-compat';

import { Costanti } from './costanti';
import { MessageService } from 'primeng/api';

@Injectable()
export class AutenticatoreService {
  [x: string]: any;

  public token: string;
  public loggato = false;

  public accontae: Account;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  // Variabili Osservate dall'esterno
  subjectUtenteLoggatoConEmailVerificata = new Subject<boolean>();
  subjectUtenteLoggatoEmail = new Subject<string>();
  subjectUtenteLoggatoUid = new Subject<string>();
  subjectUtenteLoggat = new Subject<firebase.User>();
  utenteLoggato: Observable<firebase.User>;
  utenteLoggatoUid: Observable<string>;

  user: BehaviorSubject<Account> = new BehaviorSubject(null);

  constructor(
    // private http: Http,
    private afa: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private messageService: MessageService
  ) {

    this.loggedin = false;

    /*this.afa.authState
      .switchMap(auth => {

        if (auth) {

          // entra qui' quando si e' gia' loggati ma si refresha la pagina,
          // si riavvia node oppure si esegue un normale login (praticamente entra qui' sempre !)

          console.log('********* AUTENTICATORE - if (auth) (' + auth.uid + ') *********');
          console.log('********* ' + JSON.stringify(auth) + ') *********');

          this.uid = auth.uid;
          // this.creaOppureModificaUnAccount(auth);
          this.sessione.selezionadisplayName(auth.displayName ? auth.displayName : auth.email);
          this.sessione.setOperatoreUid(auth.uid);

          this.loggato = true;

          return this.afs.firestore.doc(Costanti.TABELLA_UTENTI + '/' + auth.uid).get();

        } else {
          /// not signed in
          console.log('********* AUTENTICATORE - not signed in *********');
          this.loggato = false;
          // this.router.navigate(['/pages/login']);

          // this.sessione.selezionaCurrentUser(null);

          return Observable.of(null);
        }
      })
      .subscribe(user => {
        console.log('********* AUTENTICATORE - this.user.next(user) *********');
        // this.subjectUtenteLoggat.next(user);
        this.subjectUtenteLoggat.next(user);
        this.utenteLoggato = user;

        // this.modificaDisplayName();

        // this.userDetails = user;
        // this.updateUser(user);

        if (user) {
          // entra qui' quando si e' gia' loggati ma si refresha la pagina
          // o si riavvia node.
          // user is null ma this.uid e' ok !? Mah

          // console.log('********* AUTENTICATORE - user logged con uid ' + this.uid);
          this.loggato = true;

          this.updateLoginData();

          // this.das.inizializza();

          // valorizzo la variabile di 'sessione'

          this.sessione.setOperatoreUid(this.uid);
          // this.user = user;

          this.router.navigate(['/dash']);
        } else {
          console.log('********* AUTENTICATORE - user NOT logged *********');
          this.loggato = false;
          // this.router.navigate(['/pages/login']);
        }
      });*/
    }

    // updateLoginData(uid: string) {
    updateLoginData(authData) {
      console.log('********* updateLoginData, do nothing *********');
      const promise = new Promise((resolve, reject) => {
        this.http
        .get('https://jsonip.com')
        .toPromise()
        .then(res => {
          // Success
          console.log('"updateLoginData success ->' + JSON.stringify(res));

          console.log('updateLoginData .ip: ' + res.json().ip);
          console.log('updateLoginData .about: ' + res.json().about);
          console.log('updateLoginData .userAgent: ' + navigator.userAgent);
          console.log('updateLoginData .language: ' + navigator.language);

          const ip = res.json().ip;
          const about = res.json().about;
          const userAgent = navigator.userAgent;

          this.updateUserData(ip, authData, userAgent);

          resolve();

        })
        .catch(function(error) {
          console.log('Error updateLoginData -> ', error);
        });
    });
    return promise;
  }

  signInWithEmail(email: string, password: string) {
    this.authService.signInRegular(email, password)
       .then((res) => {
          console.log(res);

          this.router.navigate(['dashboard']);
       })
       .catch((err) => console.log('error: ' + err));
 }
  signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.afa.signInWithPopup(provider).then(credential => {
      this.creaOppureModificaUnAccount(credential.user);
    });
  }

  signInWithTwitter() {
    const provider = new firebase.auth.TwitterAuthProvider();
    return this.afa.signInWithPopup(provider).then(credential => {
      this.creaOppureModificaUnAccount(credential.user);
    });
  }

  signInWithFacebook() {
    const provider = new firebase.auth.FacebookAuthProvider();
    // return this.afa.auth.signInWithPopup(provider).then(credential => {
    return this.afa.signInWithPopup(provider).then(credential => {
      this.creaOppureModificaUnAccount(credential.user);
    });
  }

  signInViaEmail(username: string, password: string) {
    return this.afa.signInWithEmailAndPassword(username, password).then(
      credential => {
        console.log('Success Login ViaEmail !', credential);

        this.messageService.add({
          severity: 'success',
          summary: 'Login',
          detail: 'Access granted'
        });

        // this.creaOppureModificaUnAccount(credential);
        // this.router.navigate(['/dash']);
      },
      err => {
        console.log('Something went wrong:', err.message);
        this.messageService.add({
          severity: 'error',
          summary: 'Login',
          detail: 'Access denied'
        });
      }
    );
  }

  isLoggedIn() {
    // return t
    console.log('isLoggedIn ??????');
    console.log('isLoggedIn ?????? loggato -> ' + this.loggato);

    if (this.loggato === undefined) {
      console.log('isLoggedIn == undefined loggato indefinito');
      return false;
    }

    if (this.loggato === undefined) {
      console.log('isLoggedIn === undefined loggato indefinito');
      return false;
    }

    return this.loggato;
  }

  logout() {
    console.log('******** LOG-OUT ********');
    // this.afa.auth.signOut().then((res) => this.router.navigate(['/']));

    this.token = null;
    this.loggato = false;
    localStorage.removeItem('currentUser');

    this.afa.signOut().then(res => this.router.navigate(['/login']));
    // this.afa.signOut().then(res => this.router.navigate(['http://app-athenaresort.com']));
  }

  logoutNoRedirect() {
    console.log('******** LOG-OUT ********');

    this.token = null;
    this.loggato = false;
    localStorage.removeItem('currentUser');

    this.afa.signOut();
  }

  /**
   * Utilizzato da EditDatuPersonali
   *
   * @param _displayName il nuovo nome
   */
  updateDisplayName(_displayName: string) {
    const user = firebase.auth().currentUser;

    user
      .updateProfile({
        displayName: _displayName,
        photoURL: user.photoURL // lascio la stessa invece di qualcosa del tipo "https://[....]profile.jpg"
      })
      .then(() => {
          // this.toaster.tosta('top', 'center', Tipo.INFO, '<b>Registrazione completata</b><p>Registrazione completata con successo</p>', 5);
          console.log('Registrazione completata</b><p>Registrazione completata con successo'
          );
        })
      .catch((error) => {
          // this.toaster.tosta('top', 'center', Tipo.ERROR, '<b>Qualcosa e\' andato storto</b><p>Modifica fallita</p>', 5);
          console.error('Qualcosa e\' andato storto');
        });
  }

  /**
   * Update user login data
   *
   * updates database with user network info after a login
   *
   */
  private updateUserData(ip: string, authData: any, userAgent: string) {
    /*console.log('updateUserData-------- uid:' + authData.uid);
    console.log('updateUserData-------- lastLoginAt:' + authData.lastLoginAt);
    console.log('updateUserData-------- createdAt:' + authData.createdAt);
    console.log('updateUserData-------- providerData:' + authData.providerData);
    console.log('updateUserData-------- OS:' + authData.apiKey);*/

    this.afs.firestore
      .doc(Costanti.TABELLA_ACCOUNT + '/' + authData.uid)
      .get()
      .then(docSnapshot => {
        if (docSnapshot.exists) {

          //   console.log('updateLoginData con utente esiste, aggiorno');

          this.afs
            .collection(Costanti.TABELLA_ACCOUNT)
            .doc(authData.uid)
            .set(
              {
                ultimoAccesso: new Date(),
                lastIp: ip,
                userAgent: userAgent,
                // lastLoginAt: authData.lastLoginAt,
                // createdAt: authData.createdAt,
                // location: new firebase.firestore.GeoPoint(lat, lon),
                // lat: lat,
                // lon: lon,
                // regionCode: rcode,
                // regionName: rname,
                // timeZone: timeZone,
                // zipCode: zipCode,
                // userAgent: userAgent
              },
              { merge: true }
            );
        } else {
          // l'utente e' all'inerno del sito ma non e' passato dal login (che crea la riga su /users) !!!');
          console.log(Costanti.TABELLA_ACCOUNT + '/' + authData.uid + ' -> updateLoginData con utente inesistente !!!');
        }


      });
  }

  public getUtenteLoggatoEmail(): Observable<any> {
    return this.subjectUtenteLoggatoEmail.asObservable();
  }
  public getUtenteLoggatoUid(): Observable<any> {
    return this.subjectUtenteLoggatoUid.asObservable();
  }
  public isUtenteLoggatoConEmailVerificata(): Observable<boolean> {
    return this.subjectUtenteLoggatoConEmailVerificata.asObservable();
  }
}
