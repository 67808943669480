
import { Pipe, PipeTransform } from '@angular/core';
import { Struttura } from '../domain/struttura';
import { PreventivoService } from '../services/preventivo.service';

@Pipe({ name: 'struttura' })
export class StrutturaPipe implements PipeTransform {

    constructor(private ps: PreventivoService) {}

  transform(value: Struttura): string {
    return this.ps.getStrutturaHuman(value);
}

}
