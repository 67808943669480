import { firestore } from 'firebase';

export class Account {
    id?:                               string;
    uid?:                              string;
    email?:                            string;
    dataCreazione:                     firestore.Timestamp;
    displayName?:                      string;
    ruolo?:                            string;
    citta?:                            string;

    constructor(authData?) {

        console.log('Account(authData) *******************');

        if (authData && authData.displayName) {
            console.log('authData.displayName is not NULL but ' + authData.email);
            this.displayName = authData.displayName;
        } else {
            console.log('authData.displayName is NULL ******************;');
        }

        // this.displayName    = authData.displayName;
      }

}
