<div class="ui-g" *ngIf="preventivi">
        <div class="card" *ngIf="showFiltri">
            <p-toolbar>
                <div class="p-toolbar-group-left">
                    <span class="p-buttonset">
                        <button pButton pRipple label="Per data" [ngClass]="!filtro ? '' : 'p-button-secondary'" (click)="filtraPerTutti()"></button>
                        <button pButton pRipple label="In Corso" [ngClass]="filtro === 'IN_CORSO' ? '' : 'p-button-secondary'" (click)="filtraInCorso()"></button>
                        <button pButton pRipple label="Accettati" [ngClass]="filtro === 'ACCETTATO' ? '' : 'p-button-secondary'" (click)="filtraAccettati()"></button>
                        <button pButton pRipple label="Scaduti" [ngClass]="filtro === 'SCADUTO' ? '' : 'p-button-secondary'" (click)="filtraScaduti()"></button>
                        <button pButton pRipple label="Cancellati" [ngClass]="filtro === 'CANCELLATO' ? '' : 'p-button-secondary'" (click)="filtraCancellati()"></button>
                    </span>
                </div>
                <div  *ngIf="!filtro" class="p-toolbar-group-right">
                    <p-calendar placeholder="Data" [maxDate]="soglia_data" [readonlyInput]="true" dateFormat="dd/mm/yy" [(ngModel)]="data"></p-calendar>
                    <button pButton pRipple type="button" class="p-button-rounded p-button-outlined p-mr-2 p-mb-2" (click)="filtra()" icon="fa fa-filter"></button>
                </div>
            </p-toolbar>
        </div>

        <p-table #dt *ngIf="!!preventivi && preventivi.length > 0" [columns]="cols" [responsive]="true" [value]="preventivi" dataKey="id">

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width: 3em;text-align: center"></th>
                    <th style="width: 6em;text-align: center"></th>
                    <th style="width: 9em;text-align: center">Data Creazione</th>
                    <th style="width: 9em;text-align: center">Numero</th>
                    <th style="text-align: center">Cliente</th>
                    <th style="text-align: center">Pax</th>
                    <th style="text-align: center">Modalita'</th>
                    <th style="text-align: center">Sistemazione</th>
                    <th>Operatore</th>
                    <th style="width: 9em;text-align: center">Data Scadenza</th>
                    <th style="width: 9em;text-align: center"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-cols="columns">
                <tr>
                    <td>
                        <a href="#" [pRowToggler]="rowData">
                            <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                        </a>
                    </td>
                    <td style="text-align: center">
                        <i [ngClass]="rowData | stato" title="{{rowData | stato : 'human'}}" [style.color]="rowData | stato : 'colore'"></i>
                    </td>
                    <td style="text-align: center">{{rowData.dataCreazione.toDate() | date :'shortDate'}}</td>
                    <td style="text-align: center">{{rowData.numero}}</td>
                    <td style="text-align: center">{{rowData.clienteNominativo}}</td>
                    <td style="text-align: center">{{rowData.adulti}}</td>
                    <td style="text-align: center">{{rowData.modalita || 'TELEFONO'}}</td>
                    <td style="text-align: center">{{rowData.sistemazione}}</td>
                    <td style="text-align: center">{{rowData.operatore}}</td>
                    <td style="text-align: center">{{rowData.dataScadenza.toDate() | date :'shortDate'}}</td>
                    <td style="text-align: center"><a style="cursor: pointer;" (click)="selezionaItem(rowData)">[dettaglio]</a></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
                    <tr>
                        <td colspan="11">
                            <div class="p-grid" style="font-size:16px;padding:20px">
                                <div class="p-col-12 p-md-3" style="text-align:center">
                                    <img [attr.alt]="rowData.brand" src="assets/images/logo.png" width="100px">
                                </div>
                                <div class="p-col-12 p-md-9">
                                    <div class="ui-g">

                                        <div class="ui-g-6">
                                            <b>Date:</b> dal {{rowData.checkIn.toDate() | date: 'shortDate'}} al {{rowData.checkOut.toDate() | date: 'shortDate'}}
                                        </div>

                                        <div class="ui-g-6">
                                            <b>Operatore:</b> {{rowData.operatore}}
                                        </div>

                                        <div class="ui-g-6">
                                            <b>Importo:</b> {{rowData.totale | currency :'EUR'}}
                                        </div>
                                    </div>
                                </div>
                                <button pButton label="ReInvia" (click)="invia(rowData)" icon="fa fa-paper-plane-o" type="button" class="p-button-rounded p-button-outlined p-mr-2 p-mb-2" [disabled]="rowData.stato === 'SCADUTO'"></button>
                            </div>
                        </td>
                    </tr>
            </ng-template>
        </p-table>
    </div>

<div class="ui-g" *ngIf="!preventivi">
    <h1>NON HO TROVATO ALCUN PREVENTIVO</h1>
</div>

