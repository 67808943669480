<div class="p-grid" *ngIf="preventivo" style="padding: 20px;">

	<p-toast></p-toast>
	
	<div class="p-col-12 p-md-6" #cardElement>
			<!--<div class="ui-g-6 ui-md-12" *ngIf="pagamentoInSospeso">-->
			<!--<div class="ui-g-6" *ngIf="pagamentoInSospeso">-->
			  <h1>Athena On-line Payments System</h1>
			  <p>Inserisci i dati della tua carta di credito e premi su <b>paga</b> per bloccare il tuo preventivo</p>
			  <!--<ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions" (change)="cardUpdated($event)" (error)="error = $event"></ngx-stripe-card>-->
			  <ngx-stripe-card #carta [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
			  <button pButton [disabled]="preventivo?.stato !== 'IN_CORSO' || !preventivo.clientSecret" label="PAGA {{anticipo  | currency :'EUR'}}" type="button" (click)="paga()" class="p-mb-2 p-mr-2" style="display:block;margin: 20px; margin-left: 6px;"></button>
	</div>

	<div class="p-col-12 p-md-6">
	<div class="p-col">
		<div class="card" >
			<div id="invoice-content">
				<div class="invoice">
					<div class="invoice-header">
						<div class="invoice-company">
							<!--<img id="invoice-logo" class="logo-image" src="assets/layout/images/logo-dark.svg" alt="diamond-layout" />-->
							<img id="invoice-logo" class="logo-image" src="assets/images/logo.png" alt="athena-logo" />
							<div class="company-name">Athena Resort</div>
							<div>FREEDOM OF HOLIDAY S.R.L.</div>
							<div>VIA G. FALCONE N. 7</div>
							<div>97100 RAGUSA RG</div>
						</div>
						<div>
							<div class="invoice-title">PREVENTIVO</div>
							<div class="invoice-details">
								<div class="invoice-label">PREVENTIVO_ID</div>
								<div class="invoice-value">{{preventivo.numero}}</div>
								<div class="invoice-label">CHECK-IN</div>
								<div class="invoice-value">{{preventivo.checkIn.toDate() | date :'shortDate'}}</div>
								<div class="invoice-label">CHECK-OUT</div>
								<div class="invoice-value">{{preventivo.checkOut.toDate() | date :'shortDate'}}</div>
								<div class="invoice-label">STATO</div>
								<div class="invoice-value">{{preventivo | stato :'pagamento'}}</div>
							</div>
						</div>
					</div>
					<div class="invoice-to">
						<div class="bill-to">CLIENTE</div>
						<div class="invoice-to-info">
							<div>{{preventivo.clienteNominativo}}</div>
							<div>{{preventivo.clienteEmail}}</div>
						</div>
					</div>
					<div class="invoice-items">
						<table>
							<thead>
							<tr>
								<th>Descrizione</th>
								<th>Quantita</th>
								<th>Prezzo unitario</th>
								<th>Totale</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td>Soggiorno dal {{preventivo.checkIn.toDate() | date :'shortDate'}} al {{preventivo.checkOut.toDate() | date :'shortDate'}}</td>
								<td>1</td>
								<td>{{preventivo.totale  | currency :'EUR'}}</td>
								<td>{{preventivo.totale  | currency :'EUR'}}</td>
							</tr>
							</tbody>
						</table>
					</div>
					<div class="invoice-summary">
						<div class="invoice-notes">
							<b>NOTE</b>
							<p>{{preventivo.note}}</p>
							<div>

							</div>
						</div>
						<div>
							<div class="invoice-details">
								<div class="invoice-label">TOTALE_SOGGIORNO</div>
								<div class="invoice-value">{{preventivo.totale  | currency :'EUR'}}</div>
								<!--<p>{{preventivo.totale  | currency :'EUR'}}</p>-->

								<div class="invoice-label">ANTICIPO_DOVUTO</div>
								<div class="invoice-value"><u>{{anticipo  | currency :'EUR'}}</u></div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<button pButton label="STAMPA" type="button" icon="pi pi-print" (click)="print()" class="p-mb-2 p-mr-2 p-button-secondary" style="display:block;margin: 20px; margin-left: 6px;"></button>
	</div>
	</div>

</div>
