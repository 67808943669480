import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ISubscription } from 'rxjs/Subscription';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { DAService } from 'src/app/services/da.service';
import { SessioneService } from 'src/app/services/sessione.service';
import { Account } from '../../domain/account';
import { Operatore } from '../../domain/operatore';

@Component({
  styleUrls: ['./tabella.scss'],
  templateUrl: './operatori-view.component.html',
})
export class OperatoriViewComponent implements OnInit, OnDestroy {

operatori?: Operatore[] = [];
operatore?: Operatore;

  cols: any[];

  data = new Date();

  utente?: Account;
  risultati?: any;

  private subscription: ISubscription;

  constructor(
    private sessione: SessioneService,
    private dao: DAService,
    private breadcrumbService: BreadcrumbService,
    private messageService: MessageService
  ) {}



  ngOnInit() {

    this.utente = this.sessione.operatoreLoggato;

    this.cols = [
        { field: 'cognome', header: 'Cognome', style: 'center' },
        { field: 'nome', header: 'nome', style: 'center'},
        { field: 'email', header: 'Email', style: 'center'},
    ];

    this.breadcrumbService.setItems([
        { label: ' Elenco Operatori', routerLink: [''] }

    ]);

    this.filtra();

}

filtra() {

    this.disiscrivi();

    this.operatori = [];

    this.subscription = this.dao.getOperatori().subscribe(items => {

        this.operatori = items;
        if (!items || items.length === 0) {
            console.log('RichiesteViewComponent, nessun items');
            this.risultati = null;
            this.messageService.add({severity: 'error', summary: 'Elenco Operatori', detail: 'Non c\'e nessun Operatore'});
        }
    });

}

deseleziona() {
    this.operatore = null;
}

nuovoOperatore() {
    this.operatore = new Operatore();
  }

  seleziona($event) {
    console.log('seleziona ' + JSON.stringify($event));

    this.operatore = $event;

  }

  ngOnDestroy() {
    this.disiscrivi();
  }

  disiscrivi() {
    if (this.subscription) {this.subscription.unsubscribe(); }
  }


}
