import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Parametro } from '../domain/parametro';
import { Tariffa } from '../domain/tariffa';
import { Costanti } from '../services/costanti';
import { SessioneService } from '../services/sessione.service';
import { UtenteService } from '../services/utente.service';

@Component({
  styles: ['.ui-confirmdialog-message {white-space: pre-line;}'],
  selector: 'app-login',
  templateUrl: './app.login.component.html',
})
export class AppLoginComponent {

  username: any;
  password: any;
  password2: any;
  targa: any;

    mostraResetPassword = false;
    mostraSignup = false;

    constructor(
      private utenteService: UtenteService,
      private router: Router,
      private messageService: MessageService,
      private afs: AngularFirestore,
      private sessione: SessioneService,
      private afa: AngularFireAuth
  ) {}

  resetPassword() {

    if (!this.username) {
      this.messageService.add({
        severity: 'error',
        summary: 'Reset password',
        detail: 'Inserisci l\'email associata al tuo account'
      });
      console.error('Inserisci l\'email associata al tuo account');
      return;
      }

      // this.afa.auth.sendPasswordResetEmail(this.username).then(res => this.router.navigate(['/login']));
    this.afa
      .sendPasswordResetEmail(this.username)
        .then(res => {

            this.mostraResetPassword = false;

            this.messageService.add({
                severity: 'success',
                summary: 'Reset password',
                detail: 'Ti abbiamo inviato un link per modificare la tua password all\'indirizzo ' + this.username
                // detail: 'We sent you a link to reset your password'
            });
        })
        .catch(res => {

            this.username = null;

            this.messageService.add({
                severity: 'error',
                summary: 'Reset password',
                detail: 'Qualcosa e\' andato storto. ' + res
            });
        });

}

registrati() {
  console.log('signup');

  if (!this.username || !this.password || !this.password2) {
      this.messageService.add({
          severity: 'error',
          summary: 'Sign in',
          detail: 'Insert your email and choose a password'
      });
      return;
  }

  if (this.password.lenght < 8) {
      this.messageService.add({
          severity: 'error',
          summary: 'Sign in',
          detail: 'Password must have at least 8 characters'
      });
      return;
  }

  if (this.password !== this.password2) {
      this.messageService.add({
          severity: 'error',
          summary: 'Sign in',
          detail: 'Passwords must match !'
      });
      return;
  }

  return this.afa.createUserWithEmailAndPassword(this.username, this.password)
      .then(() => {
          this.messageService.add({
              severity: 'success',
              summary: 'Registrsati',
              detail: 'Account creato con successo !'
          });

          this.nascondiTutto();

          this.signInViaEmail();

      })
      .catch((e) => {
          this.messageService.add({
              severity: 'error',
              summary: 'Sign in',
              detail: e
          });
      });
}

nascondiTutto() {
  this.mostraResetPassword = false;
  this.mostraSignup = false;
}

signInViaEmail() {
  if (!this.username || !this.password) {
      this.messageService.add({
          severity: 'error',
          summary: 'Login',
          detail: 'Inserisci username e password'
      });
      return;
  }

  return this.afa
      .signInWithEmailAndPassword(this.username, this.password)
      .then(
          credential => {
              console.log('Success Login ViaEmail !', credential);
              console.log('providerData -> ', JSON.stringify(credential.user.providerData));

              this.sessione.setOperatoreUid(credential.user.uid);

              this.router.navigate(['/dashboard']);

              this.messageService.add({severity: 'success', summary: 'Login', detail: 'Accesso consentito'});

              // aggiorno i dati relativi al login

              this.utenteService.aggiornaDatiLogin(credential.user.uid);

              // CARICO I PARAEMTRI DI SISTEMA e TARIFFE

              const subscription1 = this.afs.doc<Parametro>(Costanti.TABELLA_PARAMETRI + '/sistema').valueChanges().subscribe(_item => {
                this.sessione.parametriDiSistema = _item;
                subscription1.unsubscribe();
            } );

              const subscription2 = this.afs.doc<Tariffa>(Costanti.TABELLA_TARIFFE + '/valide').valueChanges().subscribe(_item => {
                this.sessione.tariffe = _item;
                subscription2.unsubscribe();
            } );


          },
          err => {
              console.log('LoginComponent Something went wrong:', err.message);

              this.messageService.add({
                  severity: 'error',
                  summary: 'Login',
                  detail: 'Accesso negato'
              });
          }
      );
}



}
