import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    OnChanges,
    Output,
    EventEmitter
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { ISubscription } from 'rxjs/Subscription';
import { Cliente } from '../../domain/cliente';
import { Preventivo } from '../../domain/preventivo';
import { Costanti } from '../../services/costanti';
import { DAService } from '../../services/da.service';
import { SessioneService } from '../../services/sessione.service';
import { DataService } from '../../services/data.service';
import { Sistemazioni } from 'src/app/domain/sistemazioni';
import { Parametro } from 'src/app/domain/parametro';
import { Account } from 'src/app/domain/account';
import { PreventivoService } from 'src/app/services/preventivo.service';
import { EmailService } from 'src/app/services/email.service';

@Component({
    selector: 'app-preventivo',
    templateUrl: './preventivo.component.html',
    styles: [`
        .errore {
            /*font-family: monospace;
            background-color: #dee4e9;
            color: #424242;
            padding: 1em;
            font-size: 14px;
            border-radius: 3px;
            overflow: auto;
            font-weight: bold;*/
            font-family: monospace;
            color: red;
            font-weight: bold;
        }`
    ]
})
export class PreventivoComponent implements OnInit, OnChanges, OnDestroy {

    @Input() preventivo?: Preventivo;
    @Input() cliente?: Cliente;
    @Output() chiudi = new EventEmitter();

    parametri: Parametro;

    subscription1: ISubscription;
    subscription2: ISubscription;

    locale: any;
    hoGiaCalcolato = false;
    myForm: FormGroup;
    account?: Account;
    sistemazioni: SelectItem[] = Costanti.COMBO_SISTEMAZIONI;
    tipiResidence: SelectItem[] = Costanti.COMBO_TIPI_RESIDENCE;
    comboSettori: SelectItem[] = Costanti.COMBO_SETTORI;
    comboCitta: SelectItem[] = Costanti.COMBO_CITTA;
    comboModalita: SelectItem[] = Costanti.COMBO_MODALITA;
    comboOperatori?: SelectItem[] = [];

    operatoreId?: string;
    operatore?: string;

    tariffaMedia = 0;
    // minDate = new Date(2020, 3, 5);
    // maxDate = new Date(2020, 8, 27);
    minDate = Costanti.APERTURA_RESORT;
    maxDate = Costanti.CHIUSURA_RESORT;

    tessereInfant = 0; // € 7,00 per giorno
    numeroTessereClub = 0;
    tessereClub = 0; // € 6,00 per giorno

    pax = 0;
    totaleAdulti = 0; // adulti x tariffa media
    subTotaleLordo = 0;
    subTotale = 0;
    totaleLordo = 0;
    totale = 0;
    letti?: string;

    subTotaleScontato = 0;
    totaleSoggiorno = 0;

    uploadedFiles: any[] = [];
    profileUrl: Observable<string | null>;
    evidenzeUrl: Observable<string | null>;
    // Main task
    downloadURL: Observable<string>;

    // riepilogo

    notti = 0;
    sconto = 0;

    aliquotaSconto = 0

    // supplementi e riduzioni
    supplementoNotteAliquota = Costanti.SUPPLEMENTO_NOTTI;
    supplementoSingolaAliquota = Costanti.SUPPLEMENTO_SINGOLA;
    supplementoNotte = 0;
    supplementoVistaMare = 0;
    supplementoDus = 0;
    supplementoBalconeVeranda = 0;
    supplementoAnimali = 0;
    supplementoSingola = 0;
    supplemento = 0;
    riduzioneMezzaPensione = 0;
    supplementoLetto3 = 0;
    supplementoLetto5 = 0;
    supplementoLetto6 = 0;
    forfaitConsumi = 0;

    costoChd3 = 0;
    costoChd4 = 0;
    costoAdl3 = 0;
    costoAdl4 = 0;

    aliquotaScontoHp = 0;
    aliquotaScontoHv = 0;
    aliquotaScontoFr = 0;
    aliquotaScontoRe = 0;

    supplementiHV = [
        {label: 'Paypal', value: 'PayPal', icon: 'fa fa-fw fa-cc-paypal'},
        {label: 'Visa', value: 'Visa', icon: 'fa fa-fw fa-cc-visa'},
        {label: 'MasterCard', value: 'MasterCard', icon: 'fa fa-fw fa-cc-mastercard'}
    ];


    vistaMare = false;
    balcone = false;
    finestra = false;
    economy = false;
    dus = false;
    animali = false;
    mezzaPensione = false;

    invalidDates: Array<Date>;

    constructor(
        private sessione: SessioneService,
        private dao: DAService,
        private ds: DataService,
        private storage: AngularFireStorage,
        private afs: AngularFirestore,
        private confirmationService: ConfirmationService,
        private preventivoService: PreventivoService,
        private emailService: EmailService,
        private messageService: MessageService,
        ) {
        }

    myUploader(event, cartella) {

        if (!this.preventivo.numero) {
            this.messageService.add({ severity: 'error', summary: 'File Uploaded', detail: 'devi prima salvare la tua Richiesta' });
            return;
        }

        for (const file of event.files) {

            console.log('upload file ' + file.name);

            this.uploadedFiles.push(file);

            // The storage path
            const path = cartella + `/${this.preventivo.numero}`;

            const storageRef = this.storage.ref(path);

            const storageRef2 = storageRef.getDownloadURL().subscribe(_item => {

                console.log('upload file, attivita\' su getDownloadURL -> ');
                console.log('upload file, attivita\' su getDownloadURL -> ' + JSON.stringify(_item));
                console.log('File available at', _item);
            });

            const uploadTask = storageRef.put(file);

        }

        this.messageService.add({ severity: 'success', summary: 'File Uploaded', detail: 'il documento e\' stato caricato con successo' });

    }

    print() {

        this.calcola();

        const printContent = document.getElementById('da_stampare');
        const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
        WindowPrt.document.write(printContent.innerHTML);
        WindowPrt.document.close();
        WindowPrt.focus();
        WindowPrt.print();
        WindowPrt.close();

    }

    initValue() {
        this.tariffaMedia = 0;
        this.tessereInfant = 0; // € 7,00 per giorno
        this.tessereClub = 0; // € 6,00 per giorno
        this.numeroTessereClub = 0;
        this.pax = 0;
        this.subTotaleLordo = 0;
        this.subTotale = 0;
        this.totaleLordo = 0;
        this.totale = 0;
        this.totaleAdulti = 0;
        this.subTotaleScontato = 0;
        this.totaleSoggiorno = 0;
        this.notti = 0;
        this.sconto = 0;
        this.supplementoNotte = 0;
        this.supplementoVistaMare = 0;
        this.supplementoDus = 0;
        this.supplementoBalconeVeranda = 0;
        this.supplementoAnimali = 0;
        this.supplementoLetto3 = 0;
        this.supplementoLetto5 = 0;
        this.supplementoLetto6 = 0;
        this.supplemento = 0;
        this.riduzioneMezzaPensione = 0;
        this.forfaitConsumi = 0;

    }

    ngOnInit() {

        this.locale = this.ds.getLocale();

        this.comboOperatori.push({ label: ' - scegli - ', value: '' });

        this.sistemazioni = Costanti.COMBO_SISTEMAZIONI;
        /*this.sistemazioni = [];
        this.sistemazioni.push({ label: 'PALACE', value: 'PALACE' });
        this.sistemazioni.push({ label: 'VILLAGE', value: 'VILLAGE' });
        this.sistemazioni.push({ label: 'FAMILY ROOM', value: 'FAMILY_ROOM' });*/


        this.subscription2 = this.dao.getOperatori().subscribe(items => {
            items.forEach(item => {
                const operatore =  item.nome + ' ' + item.cognome;
                this.comboOperatori.push({ label: operatore, value: operatore });
            });
        });

        if (this.preventivo && this.preventivo.cliente){
            // ha gia' un cliente, lo carico
            console.log('ha gia\' un cliente, lo carico: ' + this.preventivo.cliente);

            this.subscription1 = this.afs.doc<Cliente>(Costanti.TABELLA_CLIENTI + '/' + this.preventivo.cliente).valueChanges().subscribe(item => {
                this.cliente =  item;
                console.log('cliente trovato: ' + this.cliente.cognome);
                });
            }
            }

    /**
     * invocato ogni volta che l'input del componente cambia
     */
    ngOnChanges() {

        this.account = this.sessione.operatoreLoggato;
        this.parametri = this.sessione.parametriDiSistema;

        this.aliquotaScontoHp = this.parametri.scontoHp;
        this.aliquotaScontoHv = this.parametri.scontoHv;
        this.aliquotaScontoFr = this.parametri.scontoFr;
        this.aliquotaScontoRe = this.parametri.scontoRe;

        this.myForm = new FormGroup({

                dataCreazione: new FormControl(this.preventivo.dataCreazione ? this.preventivo.dataCreazione.toDate() : new Date()),
                dataScadenza: new FormControl(this.preventivo.dataScadenza ? this.preventivo.dataScadenza.toDate() : new Date(), Validators.required),
                uid: new FormControl(this.preventivo.uid),
                date: new FormControl(this.preventivo.date),
                adulti: new FormControl(this.preventivo.adulti, Validators.required),
                infanti: new FormControl(this.preventivo.infanti,  Validators.pattern('^[0-9]*$'), ),
                note: new FormControl(this.preventivo.note),
                stato: new FormControl(this.preventivo.stato),
                sistemazione: new FormControl(this.preventivo.sistemazione),
                operatore: new FormControl(this.preventivo.operatore, Validators.required),
                modalita: new FormControl(this.preventivo.modalita, Validators.required),
                // aliquotaSconto: new FormControl(this.parametri.sconto, Validators.pattern('^[0-9]*$'), ),

                // RESIDENCE
                tipoResidence: new FormControl(this.preventivo.tipoResidence),
                // 3° letto monolocale o 5° e 6° letto trilocale
                letto3: new FormControl(this.preventivo.letto3),
                letto5: new FormControl(this.preventivo.letto5),
                letto6: new FormControl(this.preventivo.letto6),
                forfaitConsumi: new FormControl(this.preventivo.forfaitConsumi),

                // supplementi e riduzioni
                vistaMare: new FormControl(this.preventivo.vistaMare),
                balcone: new FormControl(this.preventivo.balcone),
                // finestra: new FormControl(this.preventivo.finestra),
                dus: new FormControl(this.preventivo.dus),
                animali: new FormControl(this.preventivo.animali),
                mezzaPensione: new FormControl(this.preventivo.mezzaPensione),
                economy: new FormControl(this.preventivo.economy),

                // 3° e 4° letto
                specialCase: new FormControl(this.preventivo.specialCase),
                chd3: new FormControl(this.preventivo.chd3),
                chd4: new FormControl(this.preventivo.chd4),
                adult3: new FormControl(this.preventivo.adult3),
                adult4: new FormControl(this.preventivo.adult4),

                // cliente, read only
                cliente: new FormControl(this.preventivo.cliente),
                clienteNominativo: new FormControl(this.preventivo.clienteNominativo),
                clienteNome: new FormControl(this.preventivo.clienteNome),
                clienteCognome: new FormControl(this.preventivo.clienteCognome),
                clienteCitta: new FormControl(this.preventivo.clienteCitta),
                clienteTelefono: new FormControl(this.preventivo.clienteTelefono),
                clienteEmail: new FormControl(this.preventivo.clienteEmail),

            });
        //});

        // carico i docuementi

        /*if (this.preventivo.numero) {
            // carico il riferimento al documenti di identita'
            console.log('carico il riferimento al documenti di identita\': ' + this.preventivo.numero);
            const ref = this.storage.ref('identita/' + this.preventivo.numero);
            this.profileUrl = ref.getDownloadURL();

            // carico il riferimento al documento con le evidenze
            console.log('carico il riferimento al documenti di identita\': ' + this.preventivo.numero);
            const ref1 = this.storage.ref('evidenze/' + this.preventivo.numero);
            this.evidenzeUrl = ref1.getDownloadURL();
        }*/

        // this.calcola();
    }

    onSubmit() {


        if (this.myForm.status !== 'VALID') {
            console.error('form is not valid, cannot save to database');
            return;
        }

        if (!this.cliente) {
            this.messageService.add({ severity: 'error', summary: 'Cliente', detail: 'Devi ancora inserire il Cliente' });
            return;
        }

        if (!this.myForm.value.adulti || this.myForm.value.adulti === 0) {
            this.messageService.add({ severity: 'error', summary: 'Salva Preventivo', detail: 'Devi inserire almeno un adulto' });
            return;
        }

        if (!this.myForm.value.date || this.myForm.value.date.length !== 2) {
            this.messageService.add({ severity: 'error', summary: 'Salva Preventivo', detail: 'Devi inserire check-in e check-out' });
            return;
        }

        if (!this.calcola()) {
            this.messageService.add({ severity: 'error', summary: 'Salva Preventivo', detail: 'Qualcosa e\' andato storto :(' });
            return;
        }

        let data = this.myForm.value;

        data = {
            checkIn: data.date[0],
            checkOut: data.date[1],
            notti: this.notti,
            pax: this.pax,
            letti: this.letti || 0,

            aliquotaSconto: this.aliquotaSconto,

            tariffa: this.tariffaMedia,
            costoChd3: this.costoChd3,
            costoChd4: this.costoChd4,
            costoAdl3: this.costoAdl3,
            costoAdl4: this.costoAdl4,

            totaleAdulti: this.totaleAdulti,

            subTotaleLordo: this.subTotaleLordo,

            economy: this.economy,
            supplementoNotte: this.supplementoNotte,
            supplementoVistaMare: this.supplementoVistaMare,
            supplementoDus: this.supplementoDus,
            supplementoBalconeVeranda: this.supplementoBalconeVeranda,
            supplementoAnimali: this.supplementoAnimali,
            supplementoSingola: this.supplementoSingola,
            supplementoLetto3: this.supplementoLetto3,
            supplementoLetto5: this.supplementoLetto5,
            supplementoLetto6: this.supplementoLetto6,
            forfaitConsumi: this.forfaitConsumi,

            subTotale: this.subTotale,

            riduzioneMezzaPensione: this.riduzioneMezzaPensione,
            totaleLordo: this.totaleLordo,
            sconto: this.sconto,
            tessereInfant: this.tessereInfant,
            tessereClub: this.tessereClub, // importo
            numeroTessereClub: this.numeroTessereClub,

            totale: this.totale,

            ...data
        };

        if (this.preventivo.id) {
            // update
            data = {opModifica: data.operatore, dataModifica: new Date(), ...data};
            this.dao.modifica(this.preventivo.id, Costanti.TABELLA_PREVENTIVI, data, false);
            this.messageService.add({ severity: 'success', summary: 'Preventivo', detail: 'Il Preventivo e\' stato modificato con successo' });
            this.emailService.inviaPreventivo(this.preventivo.id); // invio una email

        } else {
            // create
            data = { opCreazione: data.operatore, ...data};
            this.dao.creaSenzaChiave(data, Costanti.TABELLA_PREVENTIVI);
            this.messageService.add({ severity: 'success', summary: 'Preventivo', detail: 'Il Preventivo e\' stato creato' });
            this.annulla();
        }

        // comunque sia al termine invia la mail

        // console.log('comunque sia al termine invia la mail');
        // this.preventivoService.inviaEmail(this.preventivo);

    }

    calcola(): boolean {

        this.initValue();

        if (!this.myForm.value.adulti || this.myForm.value.adulti === 0) {
            this.messageService.add({ severity: 'error', summary: 'Calcolo Preventivo', detail: 'Devi inserire almeno un adulto' });
            return false;
        }

        if (!this.myForm.value.date || this.myForm.value.date.length !== 2) {
            this.messageService.add({ severity: 'error', summary: 'Calcolo Preventivo', detail: 'Devi inserire check-in e check-out' });
            return false;
        }

        if (this.myForm.value.sistemazione === Sistemazioni.RESIDENCE) {
            return this.calcolaResidence();
        }

        const checkIn = this.myForm.value.date[0];

        this.notti = this.preventivoService.calcolaNotti(this.myForm.value.date);
        this.pax = this.myForm.value.adulti;


        this.tariffaMedia = this.preventivoService.calcolaTariffaMedia(this.myForm.value.date, this.myForm.value.sistemazione, this.myForm.value.tipoResidence);

        if (this.myForm.value.sistemazione === Sistemazioni.FAMILY_ROOM) {

            if (!(this.pax > 2 && this.pax < 7) && !this.myForm.value.specialCase) {
                this.messageService.add({ severity: 'error', summary: 'Calcolo Preventivo', detail: 'Nella Family Room possono esserci solo pax che vanno da 4 a 6' });
                return false;
            }

            if (this.myForm.value.specialCase) {
                this.subTotaleLordo =  this.tariffaMedia * this.notti * 1.5;
                console.log('SPECIAL CASE: ' + this.tariffaMedia + ' * ' + this.notti + ' * 1.5 = ' + this.subTotaleLordo);
                this.totaleAdulti = 1;
            } else {
                // PER LA TARIFFA FAMILY SI PARTE DA QUELLA
                // VILLAGE AUMENTATA DEL 20%
                // this.subTotaleLordo = this.getSubtotaleLordoFR() * 1.2;
                this.subTotaleLordo = this.getSubtotaleLordoFR();
                this.totaleAdulti = this.subTotaleLordo;
            }

        } else {

            if (this.myForm.value.specialCase) {
                this.subTotaleLordo =  this.tariffaMedia * this.notti * 1.5;
                console.log('SPECIAL CASE: ' + this.tariffaMedia + ' * ' + this.notti + ' * 1.5 = ' + this.subTotaleLordo);
                this.totaleAdulti = 1;
            } else {
                this.totaleAdulti = this.myForm.value.adulti * this.tariffaMedia * this.notti;
                this.subTotaleLordo = this.getSubtotaleLordoHVeHP();
            }
        }

        // SUPPLEMENTI
        this.supplementoNotte = 0;
        if (checkIn > Costanti.INIZIO_PAUSA_SUPPLEMENTO_SOGGIORNI_BREVI && checkIn < Costanti.FINE_PAUSA_SUPPLEMENTO_SOGGIORNI_BREVI) {
            console.log('NON devo calcolare l\'eventuale supplemento per soggiorni brevi');
        } else {
            console.log('devo calcolare l\'eventuale supplemento per soggiorni brevi');
            if (this.notti < 3) {
                // SUPPLEMENTO SOGGIORNI BREVI 1-2 NOTTI: +20% E 3° LETTO 3/12 ANNI SCONTATO AL 50% (ESCLUSO PERIODO 06/08 – 27/08);
                this.supplementoNotte = (this.subTotaleLordo *  Costanti.SUPPLEMENTO_NOTTI) / 100;
            }
            // rimosso come da whatsapp del 21/1/21
            // } else if (this.notti < 5) {
            //     // SUPPLEMENTO SOGGIORNI BREVI 3-4 NOTTI: + 20% (ESCLUSO PERIODO 06/08 – 27/08) ;
            //     this.supplementoNotte = (this.subTotaleLordo *  Costanti.SUPPLEMENTO_NOTTI) / 100;
            // }
        }

        this.supplementoVistaMare = this.myForm.value.vistaMare ? (this.subTotaleLordo *  Costanti.SUPPLEMENTO_VISTA_MARE) / 100 : 0;
        console.log('supplementoVistaMare = subTotaleLordo + 10% -> ' + this.subTotaleLordo + ' + 10% = ' + this.supplementoVistaMare);
        // this.supplementoDus = this.myForm.value.dus ? (this.subTotaleLordo *  Costanti.SUPPLEMENTO_DUS) / 100 : 0;
        this.supplementoDus = this.myForm.value.dus ? (this.subTotaleLordo * this.preventivoService.calcolaDUS(this.myForm.value.date)) / 100 : 0;
        this.supplementoBalconeVeranda = this.balcone ? (this.subTotaleLordo *  Costanti.SUPPLEMENTO_BALCONE_VERANDA) / 100 : 0;
        this.supplementoAnimali = this.myForm.value.animali ? this.myForm.value.animali *  this.parametri.animale : 0;
        if (!this.myForm.value.specialCase && this.myForm.value.sistemazione !== Sistemazioni.FAMILY_ROOM) {
            this.supplementoSingola = this.myForm.value.adulti === 1 ? (this.subTotaleLordo *  Costanti.SUPPLEMENTO_SINGOLA) / 100 : 0;
        }

        // RIDUZIONI
        this.riduzioneMezzaPensione = this.getRiduzioneMezzaPensione();

        // SUB TOTALE
        this.subTotale = this.subTotaleLordo + this.supplementoNotte - this.riduzioneMezzaPensione;

        // LETTI
        this.letti = this.getLetti();

        if (this.myForm.value.sistemazione !== Sistemazioni.FAMILY_ROOM) {
            // solo per HP e HV
            this.subTotale = this.subTotale +
                this.supplementoVistaMare +
                this.supplementoDus +
                // this.supplementoAnimali +
                this.supplementoBalconeVeranda +
                this.supplementoSingola;

        }

        // SCONTO
        // this.sconto = this.myForm.value.aliquotaSconto * this.subTotale / 100; // old
        this.loadSconto();
        this.sconto = this.aliquotaSconto * this.subTotale / 100;

        this.totaleLordo = this.subTotale - this.sconto;

        // TESSERE INFANT e CLUB

        this.tessereInfant = this.myForm.value.infanti * this.notti * this.parametri.tesseraInfant;

        if (this.myForm.value.specialCase) {
            this.numeroTessereClub = 2;
        } else {
            this.numeroTessereClub = this.myForm.value.adulti + (this.myForm.value.chd3 ? 1 : 0) +  (this.myForm.value.chd4 ? 1 : 0) + (this.myForm.value.adult3 ? 1 : 0) + (this.myForm.value.adult4 ? 1 : 0);
        }

        this.tessereClub = this.numeroTessereClub * this.notti * this.parametri.tesseraClub;

        console.log('tessereInfanti=' + this.tessereInfant);
        console.log('tessereClub=' + this.tessereClub);
        console.log('supplementoAnimali=' + this.supplementoAnimali);

        // this.totale = this.tessereInfant + this.tessereClub + this.totaleLordo;
        this.totale = this.tessereInfant + this.tessereClub + this.supplementoAnimali + this.totaleLordo;

        return true;

    }

    /**
     * 
     * Pre modifica alla gestione del supplemento animali,
     * tolto dal subtotale da scontare e messo, su calcola(),
     * alla fine. Senza cioe' godere di alcuno sconto.
     * 
    */
    calcolaOk(): boolean {

        this.initValue();

        if (!this.myForm.value.adulti || this.myForm.value.adulti === 0) {
            this.messageService.add({ severity: 'error', summary: 'Calcolo Preventivo', detail: 'Devi inserire almeno un adulto' });
            return false;
        }

        if (!this.myForm.value.date || this.myForm.value.date.length !== 2) {
            this.messageService.add({ severity: 'error', summary: 'Calcolo Preventivo', detail: 'Devi inserire check-in e check-out' });
            return false;
        }

        if (this.myForm.value.sistemazione === Sistemazioni.RESIDENCE) {
            return this.calcolaResidence();
        }

        const checkIn = this.myForm.value.date[0];

        this.notti = this.preventivoService.calcolaNotti(this.myForm.value.date);
        this.pax = this.myForm.value.adulti;


        this.tariffaMedia = this.preventivoService.calcolaTariffaMedia(this.myForm.value.date, this.myForm.value.sistemazione, this.myForm.value.tipoResidence);

        if (this.myForm.value.sistemazione === Sistemazioni.FAMILY_ROOM) {

            if (!(this.pax > 2 && this.pax < 7) && !this.myForm.value.specialCase) {
                this.messageService.add({ severity: 'error', summary: 'Calcolo Preventivo', detail: 'Nella Family Room possono esserci solo pax che vanno da 4 a 6' });
                return false;
            }

            if (this.myForm.value.specialCase) {
                this.subTotaleLordo =  this.tariffaMedia * this.notti * 1.5;
                console.log('SPECIAL CASE: ' + this.tariffaMedia + ' * ' + this.notti + ' * 1.5 = ' + this.subTotaleLordo);
                this.totaleAdulti = 1;
            } else {
                // PER LA TARIFFA FAMILY SI PARTE DA QUELLA
                // VILLAGE AUMENTATA DEL 20%
                // this.subTotaleLordo = this.getSubtotaleLordoFR() * 1.2;
                this.subTotaleLordo = this.getSubtotaleLordoFR();
                this.totaleAdulti = this.subTotaleLordo;
            }

        } else {

            if (this.myForm.value.specialCase) {
                this.subTotaleLordo =  this.tariffaMedia * this.notti * 1.5;
                console.log('SPECIAL CASE: ' + this.tariffaMedia + ' * ' + this.notti + ' * 1.5 = ' + this.subTotaleLordo);
                this.totaleAdulti = 1;
            } else {
                this.totaleAdulti = this.myForm.value.adulti * this.tariffaMedia * this.notti;
                this.subTotaleLordo = this.getSubtotaleLordoHVeHP();
            }
        }

        // SUPPLEMENTI
        this.supplementoNotte = 0;
        if (checkIn > Costanti.INIZIO_PAUSA_SUPPLEMENTO_SOGGIORNI_BREVI && checkIn < Costanti.FINE_PAUSA_SUPPLEMENTO_SOGGIORNI_BREVI) {
            console.log('NON devo calcolare l\'eventuale supplemento per soggiorni brevi');
        } else {
            console.log('devo calcolare l\'eventuale supplemento per soggiorni brevi');
            if (this.notti < 3) {
                // SUPPLEMENTO SOGGIORNI BREVI 1-2 NOTTI: +20% E 3° LETTO 3/12 ANNI SCONTATO AL 50% (ESCLUSO PERIODO 06/08 – 27/08);
                this.supplementoNotte = (this.subTotaleLordo *  Costanti.SUPPLEMENTO_NOTTI) / 100;
            }
            // rimosso come da whatsapp del 21/1/21
            // } else if (this.notti < 5) {
            //     // SUPPLEMENTO SOGGIORNI BREVI 3-4 NOTTI: + 20% (ESCLUSO PERIODO 06/08 – 27/08) ;
            //     this.supplementoNotte = (this.subTotaleLordo *  Costanti.SUPPLEMENTO_NOTTI) / 100;
            // }
        }

        this.supplementoVistaMare = this.myForm.value.vistaMare ? (this.subTotaleLordo *  Costanti.SUPPLEMENTO_VISTA_MARE) / 100 : 0;
        console.log('supplementoVistaMare = subTotaleLordo + 10% -> ' + this.subTotaleLordo + ' + 10% = ' + this.supplementoVistaMare);
        this.supplementoDus = this.myForm.value.dus ? (this.subTotaleLordo *  Costanti.SUPPLEMENTO_DUS) / 100 : 0;
        // this.supplementoBalconeVeranda = this.myForm.value.balcone ? (this.subTotaleLordo *  Costanti.SUPPLEMENTO_BALCONE_VERANDA) / 100 : 0;
        this.supplementoBalconeVeranda = this.balcone ? (this.subTotaleLordo *  Costanti.SUPPLEMENTO_BALCONE_VERANDA) / 100 : 0;
        this.supplementoAnimali = this.myForm.value.animali ? this.myForm.value.animali *  this.parametri.animale : 0;
        if (!this.myForm.value.specialCase && this.myForm.value.sistemazione !== Sistemazioni.FAMILY_ROOM) {
            this.supplementoSingola = this.myForm.value.adulti === 1 ? (this.subTotaleLordo *  Costanti.SUPPLEMENTO_SINGOLA) / 100 : 0;
        }

        // RIDUZIONI
        this.riduzioneMezzaPensione = this.getRiduzioneMezzaPensione();

        // SUB TOTALE
        this.subTotale = this.subTotaleLordo + this.supplementoNotte - this.riduzioneMezzaPensione;

        // LETTI
        this.letti = this.getLetti();

        if (this.myForm.value.sistemazione !== Sistemazioni.FAMILY_ROOM) {
            // solo per HP e HV
            this.subTotale = this.subTotale +
                this.supplementoVistaMare +
                this.supplementoDus +
                this.supplementoAnimali +
                this.supplementoBalconeVeranda +
                this.supplementoSingola;

        }

        // SCONTO
        // this.sconto = this.myForm.value.aliquotaSconto * this.subTotale / 100; // old
        this.loadSconto();
        this.sconto = this.aliquotaSconto * this.subTotale / 100;

        this.totaleLordo = this.subTotale - this.sconto;

        // TESSERE INFANT e CLUB

        this.tessereInfant = this.myForm.value.infanti * this.notti * this.parametri.tesseraInfant;

        if (this.myForm.value.specialCase) {
            this.numeroTessereClub = 2;
        } else {
            this.numeroTessereClub = this.myForm.value.adulti + (this.myForm.value.chd3 ? 1 : 0) +  (this.myForm.value.chd4 ? 1 : 0) + (this.myForm.value.adult3 ? 1 : 0) + (this.myForm.value.adult4 ? 1 : 0);
        }

        this.tessereClub = this.numeroTessereClub * this.notti * this.parametri.tesseraClub;

        console.log('tessereInfanti=' + this.tessereInfant);
        console.log('tessereClub=' + this.tessereClub);

        this.totale = this.tessereInfant + this.tessereClub + this.totaleLordo;

        return true;

    }

    calcolaResidence(): boolean {


        console.log('calcolaResidence');

        this.notti = this.preventivoService.calcolaNotti(this.myForm.value.date);
        this.pax = this.myForm.value.adulti;

        this.tariffaMedia = this.preventivoService.calcolaTariffaMedia(this.myForm.value.date, Sistemazioni.RESIDENCE, this.myForm.value.tipoResidence);


        // this.totaleAdulti = this.myForm.value.adulti * this.tariffaMedia * this.notti;
        // this.subTotaleLordo = this.getSubtotaleLordoResidence();
        this.totaleAdulti = this.tariffaMedia * this.notti;
        this.subTotaleLordo = this.totaleAdulti;

         // SCONTO
        this.loadSconto();
        this.sconto = this.aliquotaSconto * this.subTotaleLordo / 100;

        this.subTotale = this.subTotaleLordo - this.sconto;

        // SUPPLEMENTI

        // 3° 5° o 6° letto, € 10,00 al giorno
        if (this.myForm.value.letto3) { this.supplementoLetto3 = 10 * this.notti; }
        if (this.myForm.value.letto5) { this.supplementoLetto5 = 10 * this.notti; }
        if (this.myForm.value.letto6) { this.supplementoLetto6 = 10 * this.notti; }

        // this.supplementoNotte = this.notti < 4 ? (this.subTotaleLordo *  Costanti.SUPPLEMENTO_NOTTI) / 100 : 0;
        this.supplementoNotte = this.notti < 4 ? (this.subTotale *  Costanti.SUPPLEMENTO_NOTTI) / 100 : 0;

        this.supplementoAnimali = this.myForm.value.animali ? this.myForm.value.animali *  this.parametri.animale : 0;

        // FORFAIT

        this.forfaitConsumi = 30 * this.notti;

        // SUB TOTALE
        // this.subTotale = this.subTotaleLordo + this.supplementoNotte - this.riduzioneMezzaPensione;
        this.totaleLordo = this.subTotale +
            this.supplementoLetto3 +
            this.supplementoLetto5 +
            this.supplementoLetto6 +
            this.supplementoNotte +
            this.supplementoAnimali +
            this.forfaitConsumi;

        // LETTI
        // this.letti = this.getLetti();

        // TESSERE INFANT e CLUB

        this.tessereInfant = this.myForm.value.infanti * this.notti * this.parametri.tesseraInfant;

        // this.numeroTessereClub = this.myForm.value.adulti + (this.myForm.value.chd3 ? 1 : 0) +  (this.myForm.value.chd4 ? 1 : 0) + (this.myForm.value.adult3 ? 1 : 0) + (this.myForm.value.adult4 ? 1 : 0);
        this.numeroTessereClub = this.myForm.value.adulti;

        this.tessereClub = this.numeroTessereClub * this.notti * this.parametri.tesseraClub;

        console.log('tessereInfanti=' + this.tessereInfant);
        console.log('tessereClub=' + this.tessereClub);

        this.totale = this.tessereInfant + this.tessereClub + this.totaleLordo;

        return true;

    }

    onCheckEconomy(event) {
        console.log('onCheckEconomy con event=' + event);
        this.balcone = this.economy;
    }

    economyAvailable() {

        const risultato =
            this.myForm.value.sistemazione === Sistemazioni.VILLAGE &&
            this.myForm.value.adulti >= 1 &&
            this.myForm.value.adulti <= 3 &&
            this.myForm.value.date &&
            this.myForm.value.date.length === 2 &&
            this.myForm.value.date[0] >= Costanti.ECONOMY_INIZIO &&
            this.myForm.value.date[0] <= Costanti.ECONOMY_FINE &&
            this.myForm.value.date[1] >= Costanti.ECONOMY_INIZIO &&
            this.myForm.value.date[1] <= Costanti.ECONOMY_FINE;
        return risultato;
    }


    /**
     * Configura lo sconto in base alla sistemazione
     *
     */
    loadSconto() {

        if (this.myForm.value.sistemazione === Sistemazioni.HOTEL) {
            this.aliquotaSconto = this.aliquotaScontoHp;
        }

        if (this.myForm.value.sistemazione === Sistemazioni.RESIDENCE) {
            this.aliquotaSconto = this.aliquotaScontoRe;
        }

        if (this.myForm.value.sistemazione === Sistemazioni.VILLAGE) {

            if (this.economy) {
                this.aliquotaSconto = Number(this.aliquotaScontoHv) + Number(Costanti.SCONTO_EXTRA_ECONOMY);
                console.info('E\' un Preventivo Economy, e\' abilitata l\'opzione Balcone alla quale applico uno sconto del ' + Costanti.SCONTO_EXTRA_ECONOMY + ' % per un totale di ' + this.aliquotaSconto + ' %');
            } else {
                console.info('Non e\' un Preventivo Economy, applico la normale scontistica');
                this.aliquotaSconto = this.aliquotaScontoHv;
            }
        }
        if (this.myForm.value.sistemazione === Sistemazioni.FAMILY_ROOM) {
            this.aliquotaSconto = this.aliquotaScontoFr;
        }
    }


    /**
     * Calcola il Subtotale lordo per Family Room
     * 4 pax = 3,2 quote, 5 pax = 3,7 quote, 6 pax = 4,5 quote
     *
     */
    getSubtotaleLordoFR(): number {

        console.log('getSubtotaleLordoFR');

        let coefficente = 0;

        if (this.pax === 3) { coefficente = 3; }
        if (this.pax === 4) { coefficente = 3.2; } // 3.2
        if (this.pax === 5) { coefficente = 3.7 ; }
        if (this.pax === 6) { coefficente = 4.5; }

        return this.tariffaMedia * this.notti * coefficente;

    }

     /**
     * Calcola il Subtotale lordo per Family Room
     * 4 pax = 3 quote, 5 pax = 3,5 quote, 6 pax = 4 quote
     *
     */
    getLetti(): string {

        let messaggio = '1 camera con letto matrimoniale';

        if (this.myForm.value.sistemazione === Sistemazioni.FAMILY_ROOM) {
            if (this.pax === 4) { messaggio = messaggio + ', 1 camera con lettino singolo, divano letto in cucina'; }
            if (this.pax === 5) { messaggio = messaggio + ', 1 camera con 2 lettini singoli, divano letto in cucina'; }
            if (this.pax === 6) { messaggio = messaggio + ', 1 camera con 2 lettini singoli, divano letto a castello in cucina'; }
        }

        if (this.myForm.value.sistemazione === Sistemazioni.HOTEL) {
            if (this.myForm.value.adult4 || this.myForm.value.chd4) {
                messaggio = messaggio + ' + 1 letto aggiunto o letto matrimonale + letto a castello';
            } else if (this.myForm.value.adult3 || this.myForm.value.chd3) {
                messaggio = messaggio + ' + 1 letto';
            }
        }

        if (this.myForm.value.sistemazione === Sistemazioni.VILLAGE) {
            if (this.myForm.value.adult4 || this.myForm.value.chd4) {
                messaggio = messaggio + ' + 1 letto a castello';
            } else if (this.myForm.value.adult3 || this.myForm.value.chd3) {
                messaggio = messaggio + ' + 1 letto';
            }
        }

        return messaggio;

    }

    /**
    * Calcola i PAX
    *
    */
    getRiduzioneMezzaPensione(): number {

        if (!this.myForm.value.mezzaPensione) {
            return 0;
        }

        let paganti = this.myForm.value.adulti;

        if (this.myForm.value.sistemazione === Sistemazioni.FAMILY_ROOM) {
            return paganti * Costanti.RIDUZIONE_MEZZA_PENSIONE * this.notti;
        }


        if (this.myForm.value.adult4) {
            paganti++;
        }
        if (this.myForm.value.adult3) {
            paganti++;
        }
        if (this.myForm.value.chd4) {
            paganti = paganti + 0.5;
        }

        return paganti * Costanti.RIDUZIONE_MEZZA_PENSIONE * this.notti;

    }


    getSubtotaleLordoHVeHP(): number {

        console.log('getSubtotaleLordoHVeHP');


        // verifichiamo inanzitutto lo special case 1 + 1

        if (this.myForm.value.adulti === 1 && this.myForm.value.chd3 && !this.myForm.value.chd4 && !this.myForm.value.adult3 && !this.myForm.value.adult4) {
                console.log('***** SPECIAL CASE 1 + 1  *****');
                // console.log('***** SPECIAL CASE 1 + 1 tariffaMedia --> ' + this.tariffaMedia + ' *****');
                // console.log('***** SPECIAL CASE 1 + 1 tariffaMedia aumentata --> ' + this.tariffaMedia * 1.5 + ' *****');
                // console.log('***** SPECIAL CASE 1 + 1 notti --> ' + this.notti + ' *****');
                // console.log('***** SPECIAL CASE 1 + 1 --> ' + this.tariffaMedia * 1.5 * this.notti + ' *****');
                const tariffaMediaMaggiorata = this.tariffaMedia * 1.5;
                this.totaleAdulti = tariffaMediaMaggiorata * this.notti;
                return tariffaMediaMaggiorata * this.notti;
        }

        let subTotaleLordo = this.tariffaMedia * this.notti * this.myForm.value.adulti;

        // if (this.myForm.value.chd3 || this.myForm.value.chd4) {
        //     console.log('c\'e\' un chd3 o chd4, va\' al 50%');
        //     // subTotaleLordo = subTotaleLordo + ((this.tariffaMedia / 2 ) * this.notti * this.myForm.value.chd4);
        //     this.costoChd4 = ((this.tariffaMedia / 2 ) * this.notti);
        //     subTotaleLordo = subTotaleLordo + this.costoChd4;
        // }
        if (this.myForm.value.chd3) {
            console.log('c\'e\' un chd3, va\' al 50%');
            // subTotaleLordo = subTotaleLordo + ((this.tariffaMedia / 2 ) * this.notti * this.myForm.value.chd4);
            this.costoChd3 = ((this.tariffaMedia / 2 ) * this.notti);
            // subTotaleLordo = subTotaleLordo + this.costoChd3;
        }else{
            this.costoChd3 = 0;
        }
        
        if (this.myForm.value.chd4) {
            console.log('c\'e\' un chd4, va\' al 50%');
            // subTotaleLordo = subTotaleLordo + ((this.tariffaMedia / 2 ) * this.notti * this.myForm.value.chd4);
            this.costoChd4 = ((this.tariffaMedia / 2 ) * this.notti);
            // subTotaleLordo = subTotaleLordo + this.costoChd4;
        }else{
            this.costoChd4 = 0;
        }

        subTotaleLordo = subTotaleLordo + this.costoChd3 + this.costoChd4;
        
        if (this.myForm.value.adult3) {
            console.log('c\'e\' un adult3, va\' al 30%');
            // subTotaleLordo = subTotaleLordo + (((this.tariffaMedia * 30 ) / 100) * this.notti * this.myForm.value.adult3);
            this.costoAdl3 = (((this.tariffaMedia * 70 ) / 100) * this.notti);
            subTotaleLordo = subTotaleLordo + this.costoAdl3;
        }
        if (this.myForm.value.adult4) {
            console.log('c\'e\' un adult4, va\' al 30%');
            // subTotaleLordo = subTotaleLordo + (((this.tariffaMedia * 30 ) / 100) * this.notti * this.myForm.value.adult4);
            this.costoAdl4 = (((this.tariffaMedia * 70 ) / 100) * this.notti);
            subTotaleLordo = subTotaleLordo + this.costoAdl4;
        }

        return subTotaleLordo;

    }

    getSubtotaleLordoResidence(): number {

        console.log('getSubtotaleLordoResidence');

        let subTotaleLordo = this.tariffaMedia * this.notti;

        console.log('getSubtotaleLordoResidence A: ' + subTotaleLordo);

        if (this.myForm.value.chd3) {
            console.log('c\'e\' un chd3, e\' free');
        }
        if (this.myForm.value.chd4) {
            console.log('c\'e\' un chd4, va\' al 50%');
            // subTotaleLordo = subTotaleLordo + ((this.tariffaMedia / 2 ) * this.notti * this.myForm.value.chd4);
            this.costoChd4 = ((this.tariffaMedia / 2 ) * this.notti);
            subTotaleLordo = subTotaleLordo + this.costoChd4;
        }
        if (this.myForm.value.adult3) {
            console.log('c\'e\' un adult3, va\' al 30%');
            // subTotaleLordo = subTotaleLordo + (((this.tariffaMedia * 30 ) / 100) * this.notti * this.myForm.value.adult3);
            this.costoAdl3 = (((this.tariffaMedia * 70 ) / 100) * this.notti);
            subTotaleLordo = subTotaleLordo + this.costoAdl3;
        }
        if (this.myForm.value.adult4) {
            console.log('c\'e\' un adult4, va\' al 30%');
            // subTotaleLordo = subTotaleLordo + (((this.tariffaMedia * 30 ) / 100) * this.notti * this.myForm.value.adult4);
            this.costoAdl4 = (((this.tariffaMedia * 70 ) / 100) * this.notti);
            subTotaleLordo = subTotaleLordo + this.costoAdl4;
        }

        return subTotaleLordo;

    }

    annulla() {
        this.chiudi.emit();
    }

    elimina() {
        this.confirmationService.confirm({
            message: 'Sei sicuro di voler cancellare il preventivo ?',
            accept: () => {
                this.preventivoService.elimina(this.preventivo);
                this.chiudi.emit();
            }
        });
    }


    accetta() {
        this.confirmationService.confirm({
            message: 'Sei sicuro di voler accettare il preventivo ?',
            accept: () => {
                this.preventivoService.accetta(this.preventivo);
                this.chiudi.emit();
            }
        });
    }

    salva() {
        this.confirmationService.confirm({
            message: 'Sei sicuro di voler salvare e inviare il preventivo al Cliente ?',
            accept: () => {
                this.onSubmit();
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription1) {this.subscription1.unsubscribe(); }
        if (this.subscription2) {this.subscription2.unsubscribe(); }
    }
}
