
<div class="layout-dashboard">

    <div class="p-col-12" *ngIf="!preventivo">
        <app-preventivi [showFiltri]="true"  (seleziona)='seleziona($event)'></app-preventivi>
    </div>
    
    <div class="p-col-12" *ngIf="preventivo">
        <app-preventivo (chiudi)='deseleziona()' [preventivo]="preventivo"></app-preventivo>
    </div>

</div>
