import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ISubscription } from 'rxjs/Subscription';
import { Messaggio } from '../../domain/messaggio';
import { Account } from '../../domain/account';
import { DAService } from '../../services/da.service';
import { MessaggioService } from '../../services/messaggio.service';
import { SessioneService } from '../../services/sessione.service';

@Component({
    selector: 'app-messaggi',
    templateUrl: './messaggi.component.html',
})
export class MessaggiComponent implements OnInit {

  @Input() messaggi?: Messaggio[] = [];
  @Input() messaggiLetti?: Messaggio[] = [];
  @Output() seleziona = new EventEmitter<any>();

  cols: any[];

  data = new Date();

  utente?: Account;
  risultati?: any;

  constructor(
    private sessione: SessioneService,
    private confirmationService: ConfirmationService,
    private messaggioService: MessaggioService,
    private messageService: MessageService
  ) {}



  ngOnInit() {

    this.utente = this.sessione.operatoreLoggato;

    this.cols = [
        { field: 'data', header: 'Data' },
        { field: 'oggetto', header: 'Oggetto' },
        { field: 'operatoreNominativo', header: 'Operatore' },
    ];

}

  selezionaMessaggio(messaggio: Messaggio) {
      console.log('seleziona messaggio ' + JSON.stringify(messaggio));
      this.seleziona.emit(messaggio);
    }

    leggi(messaggio: Messaggio) {

    this.confirmationService.confirm({
        message: 'Sei sicuro di voler segnare il Messaggio come \'letto\' ?',
        accept: () => {
            this.messaggioService.letto(messaggio, this.utente.uid);
            this.messageService.add({ severity: 'success', summary: 'Messaggio', detail: 'Messaggio letto !' });
        }
    });

}

}
