import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ISubscription } from 'rxjs/Subscription';
import { Ticket } from '../../domain/ticket';
import { DAService } from '../../services/da.service';
import { DataService } from '../../services/data.service';
import { SessioneService } from '../../services/sessione.service';

@Component({
    selector: 'app-tickets-aperti',
    templateUrl: './tickets-aperti.component.html',
})
export class TicketsApertiComponent implements OnInit {

  @Input() tickets?: Ticket[] = [];
  @Output() seleziona = new EventEmitter<any>();

  cols: any[];

  data = new Date();

  utente?: Account;
  risultati?: any;

  private subscription: ISubscription;

  constructor(
    private sessione: SessioneService,
    private dao: DAService,
    private dataService: DataService,
    private messageService: MessageService
  ) {}



  ngOnInit() {

    this.cols = [
        { field: 'data', header: 'Data' },
        { field: 'tipo', header: 'Tipo' },
        { field: 'oggetto', header: 'Oggetto' },
    ];

}

  selezionaTicket(ticket: Ticket) {
    console.log('seleziona ticket ' + JSON.stringify(ticket));
    this.seleziona.emit(ticket);
}

}
