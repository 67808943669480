import { Component, OnInit, OnDestroy } from '@angular/core';
import { ISubscription } from 'rxjs/Subscription';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { DAService } from 'src/app/services/da.service';
import { SessioneService } from 'src/app/services/sessione.service';
import { Account } from '../../domain/account';
import { Preventivo } from '../../domain/preventivo';

@Component({
  templateUrl: './preventivi-view.component.html',
})
export class PreventiviViewComponent implements OnInit, OnDestroy {

preventivi?: Preventivo[] = [];
preventivo?: Preventivo;

  cols: any[];

  data = new Date();

  utente?: Account;
  risultati?: any;

   // BOX
   totalePreventivi = 0;
   preventiviAccettati = 0;
   preventiviInCorso = 0;
   totaleClienti = 0;

  private subscription: ISubscription;
  private subscribe1: ISubscription;
  private subscribe2: ISubscription;
  private subscribe3: ISubscription;
  private subscribe4: ISubscription;

  constructor(
    private sessione: SessioneService,
    private dao: DAService,
    private breadcrumbService: BreadcrumbService,
  ) {}



  ngOnInit() {

    this.utente = this.sessione.operatoreLoggato;

    this.subscribe1 = this.dao.getAllPreventivi(null).subscribe(item => this.totalePreventivi = item.length);
    this.subscribe2 = this.dao.getAllPreventivi('ACCETTATO').subscribe(item => this.preventiviAccettati = item.length);
    this.subscribe3 = this.dao.getAllPreventivi('IN_CORSO').subscribe(item => this.preventiviInCorso = item.length);
    this.subscribe4 = this.dao.getClienti().subscribe(item => this.totaleClienti = item.length);

    this.cols = [
        { field: 'protocollo', header: 'Protocollo', style: 'center' },
        { field: 'nomeStruttura', header: 'Struttura', style: 'center'},
        { field: 'stato', header: 'Stato', style: 'center'},
    ];

    this.breadcrumbService.setItems([
        { label: ' Elenco Preventivi', routerLink: [''] }

    ]);

}

  deseleziona() {
    this.preventivo = null;
  }

  seleziona($event) {
    this.preventivo = $event;
  }

  ngOnDestroy() {
    if (this.subscription) {this.subscription.unsubscribe(); }
    if (this.subscribe1) {this.subscribe1.unsubscribe(); }
    if (this.subscribe2) {this.subscribe2.unsubscribe(); }
    if (this.subscribe3) {this.subscribe3.unsubscribe(); }
    if (this.subscribe4) {this.subscribe4.unsubscribe(); }
  }

  disiscrivi() {
    if (this.subscription) {this.subscription.unsubscribe(); }
  }


}
