import { Pipe, PipeTransform } from '@angular/core';
import { Preventivo } from '../domain/preventivo';
import { StatoPreventivo } from '../domain/stato-preventivo';

@Pipe({
    name: 'stato'
})
export class StatoPipe implements PipeTransform {

    transform(value: Preventivo, args: string): string {

        if (!value) {
            return 'fa fa-times';
        }

        if (!args) {

            // ICONA

            if (value.stato === StatoPreventivo.IN_CORSO) {
                return 'fa fa-cogs';
            }
            if (value.stato === StatoPreventivo.ACCETTATO) {
                return 'fa fa-thumbs-o-up';
            }
            if (value.stato === StatoPreventivo.CANCELLATO) {
                return 'fa fa-trash';
            }
            if (value.stato === StatoPreventivo.SCADUTO) {
                return 'fa fa-clock-o';
            }

            return 'fa fa-times';

        }

        if (args === 'colore') {

            // COLORE

            if (value.stato === StatoPreventivo.IN_CORSO) {
                return 'gray';
            }
            if (value.stato === StatoPreventivo.ACCETTATO) {
                return 'green';
            }
            if (value.stato === StatoPreventivo.SCADUTO) {
                return 'orange';
            }
            if (value.stato === StatoPreventivo.CANCELLATO) {
                return 'red';
            }

            return '';
        }

        if (args === 'human') {

            if (value.stato === StatoPreventivo.IN_CORSO) {
                return 'IN CORSO';
            }
            if (value.stato === StatoPreventivo.ACCETTATO) {
                return 'ACCETTATO';
            }
            if (value.stato === StatoPreventivo.CANCELLATO) {
                return 'CANCELLATO';
            }
            if (value.stato === StatoPreventivo.SCADUTO) {
                return 'SCADUTO';
            }
            return '???';
        }

        if (args === 'pagamento') {

            if (value.stato === StatoPreventivo.IN_CORSO) {
                return 'DA SALDARE';
            }
            if (value.stato === StatoPreventivo.ACCETTATO) {
                return 'SALDATO';
            }
            if (value.stato === StatoPreventivo.CANCELLATO) {
                return 'CANCELLATO';
            }
            if (value.stato === StatoPreventivo.SCADUTO) {
                return 'SCADUTO';
            }
            return '?!?';
        }


    }
}
