<div class="layout-dashboard" *ngIf="cliente">

    <div class="p-col-12" *ngIf="!preventivo">
        <p-toolbar>
            <div class="ui-toolbar-group-left">
                <span>
                    <button pButton label="SALVA" (click)="onSubmit()" icon="pi pi-save" type="button" [disabled]="!myForm.valid" class="p-button-rounded p-button-outlined p-mr-2 p-mb-2"></button>
                    <button *ngIf="cliente.id" pButton label="NUOVO PREVENTIVO" (click)="nuovoPreventivo()" icon="fa fa-eur" type="button" class="p-button-rounded p-button-outlined p-mr-2 p-mb-2"></button>
                    <button label="CHIUDI" (click)="annulla()" icon="pi pi-times" pButton type="button" class="p-button-rounded p-button-outlined p-button-secondary p-mr-2 p-mb-2" ></button>
                </span>
            </div>
        </p-toolbar>
    </div>

    <div class="p-col-12 p-grid" *ngIf="!preventivo">

        <div class="p-col-12 p-md-6">
            <p-panel header="Dati Cliente">
                <div class="p-grid form-group">
                    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
                        <div class="p-col-12">
                            <input id="nome" placeholder="nome" style="width: 100%" type="text" formControlName="nome" pInputText/>
                            <span class="errore" *ngIf="(myForm.get('nome').touched || myForm.get('nome').dirty ) && myForm.get('nome').errors">
                               <span *ngIf="myForm.get('nome').errors">manca il nome del Cliente</span>
                            </span>
                        </div>
                        <div class="p-col-12">
                            <input id="cognome" placeholder="cognome" style="width: 100%" type="text" formControlName="cognome" pInputText/>
                            <span class="errore" *ngIf="(myForm.get('cognome').touched || myForm.get('cognome').dirty ) && myForm.get('cognome').errors">
                                <span *ngIf="myForm.get('cognome').errors">manca il cognome del Cliente</span>
                             </span>
                        </div>


            </form>
                </div>
            </p-panel>
        </div>

        <div class="p-col-12 p-md-6">

            <p-panel header="Recapiti">

                    <div class="ui-g form-group">
                        <form [formGroup]="myForm" (ngSubmit)="onSubmit()">

                                <div class="p-col-12">
                                        <input id="email" placeholder="email" style="width: 100%" type="text" formControlName="email" pInputText/>
                                        <span class="errore" *ngIf="(myForm.get('email').touched || myForm.get('email').dirty ) && myForm.get('email').errors">
                                            <span *ngIf="myForm.get('email').errors">manca una e-mail</span>
                                         </span>
                                    </div>

                                    <div class="p-col-12">
                                        <input id="telefono" placeholder="telefono" style="width: 100%" type="text" formControlName="telefono" pInputText/>
                                        <span class="errore" *ngIf="(myForm.get('telefono').touched || myForm.get('telefono').dirty ) && myForm.get('telefono').errors">
                                            <span *ngIf="myForm.get('telefono').errors">manca un recapito telefonico</span>
                                         </span>
                                    </div>

                                    <div class="p-col-12 ui-md-8">
                                        <input id="citta" placeholder="citta'" style="width: 100%" type="text" formControlName="citta" pInputText/>
                                            <span class="errore" *ngIf="(myForm.get('citta').touched || myForm.get('citta').dirty ) && myForm.get('citta').errors">
                                                <span *ngIf="myForm.get('citta').errors">manca la citta'</span>
                                             </span>
                                    </div>
                        </form>
                    </div>
                </p-panel>
</div>
    </div>

    <div class="p-col-12" *ngIf="preventivo">
        <app-preventivo (chiudi)='deselezionaPrevenitvo()' [cliente]="cliente" [preventivo]="preventivo"></app-preventivo>
    </div>

    <p-tabView *ngIf="!preventivo">
        <p-tabPanel *ngIf="preventivi && preventivi.length > 0" header="Preventivi">
                <app-preventivi (seleziona)='selezionaPreventivo($event)' [showFiltri]="false" [preventivi]="preventivi"></app-preventivi>
        </p-tabPanel>
        <p-tabPanel *ngIf="pagamenti && pagamenti.length > 0" header="Pagamenti">
                <app-pagamenti [pagamenti]="pagamenti" [showFilter]="false"></app-pagamenti>
            </p-tabPanel>
    </p-tabView>

</div>
