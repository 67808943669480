<div class="p-grid p-fluid">
    <div class="p-col-12 p-lg-6">
        <div class="card">
            <h5 class="centerText">Linear Chart</h5>
            <p-chart type="line" [data]="lineData"></p-chart>
        </div>

        <div class="card">
            <h5 class="centerText">Pie Chart</h5>
            <p-chart type="pie" [data]="pieData"></p-chart>
        </div>

        <div class="card">
            <h5 class="centerText">Polar Area Chart</h5>
            <p-chart type="polarArea" [data]="polarData"></p-chart>
        </div>
    </div>
    <div class="p-col-12 p-lg-6">
        <div class="card">
            <h5 class="centerText">Bar Chart</h5>
            <p-chart type="bar" [data]="barData"></p-chart>
        </div>

        <div class="card">
            <h5 class="centerText">Doughnut Chart</h5>
            <p-chart type="doughnut" [data]="pieData"></p-chart>
        </div>

        <div class="card">
            <h5 class="centerText">Radar Chart</h5>
            <p-chart type="radar" [data]="radarData"></p-chart>
        </div>
    </div>
</div>
