import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    OnChanges,
    Output,
    EventEmitter,
    Inject
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ISubscription } from 'rxjs/Subscription';
import { Camera } from 'src/app/domain/camera';
import { Account } from 'src/app/domain/account';
import { Ticket } from 'src/app/domain/ticket';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Costanti } from 'src/app/services/costanti';
import { SessioneService } from 'src/app/services/sessione.service';
import { DAService } from 'src/app/services/da.service';
import { CameraService } from 'src/app/services/camera.service';
Account
@Component({
    selector: 'app-camera',
    templateUrl: './camera.component.html',
    styles: [`
        .errore {
            font-family: monospace;
            color: red;
            font-weight: bold;
        }`
    ]
})
export class CameraComponent implements OnInit, OnChanges, OnDestroy {

    @Input() camera?: Camera;
    @Output() chiudi = new EventEmitter();

    myForm: FormGroup;

    account?: Account;
    ticket?: Ticket;

    auditor?: any;

    accetto = false;

    showCheckList = false;

    comboAuditor?: SelectItem[] = [];

    strutture: SelectItem[] = Costanti.COMBO_STRUTTURE;

    uploadedFiles: any[] = [];

    profileUrl: Observable<string | null>;
    evidenzeUrl: Observable<string | null>;

    private subscription1: ISubscription;
    private subscription2: ISubscription;


    constructor(
        private sessione: SessioneService,
        private dao: DAService,
        private confirmationService: ConfirmationService,
        private cameraService: CameraService,
        private messageService: MessageService,
    ) {
        // const ref = this.storage.ref('test/1542406388322_3512009862.pdf');
        // this.profileUrl = ref.getDownloadURL();
    }

    ngOnInit() {}

    /**
     * invocato ogni volta che l'input del componente cambia
     */
    ngOnChanges() {

        this.account = this.sessione.operatoreLoggato;

        this.myForm = new FormGroup({
            dataCreazione: new FormControl(this.camera.dataCreazione ? this.camera.dataCreazione.toDate() : new Date()),
            nome: new FormControl(this.camera.nome, Validators.required),
            sistemazione: new FormControl(this.camera.sistemazione, Validators.required),
        });

    }

    flipShowCheckList() {
        this.showCheckList = !this.showCheckList;
    }

    onSubmit() {

        // TODO: Use EventEmitter with form value

        console.log('ACCETTO: ' + this.accetto);

        if (this.myForm.status !== 'VALID') {
            console.error('form is not valid, cannot save to database');
            return;
        }

        /*if (!this.myForm.value.partitaIva && !this.myForm.value.codiceFiscale) {
            this.messageService.add({ severity: 'error', summary: 'Dati Fiscali', detail: 'Devi inseire il Codice Fiscale o la Partita IVA' });
            return;
        }*/

        console.log(this.myForm.value);

        if (this.camera.id) {
            this.dao.modifica(this.camera.id, Costanti.TABELLA_CAMERE, this.myForm.value, true);
        } else {
            this.dao.creaSenzaChiave(this.myForm.value, Costanti.TABELLA_CAMERE);
            this.annulla();
        }

    }

    annulla() {
        this.chiudi.emit();
    }

    elimina() {

        this.confirmationService.confirm({
            message: 'Sei sicuro di voler cancellare la camera ?',
            accept: () => {
                this.cameraService.elimina(this.camera);
                this.chiudi.emit();
            }
        });

    }

    salva() {
        // this.myForm.chiudi.emit();
        this.onSubmit();
        // this.chiudi.emit();
    }

    nuovoTicket() {
        this.ticket = new Ticket('', this.camera.id, this.camera.nome, this.camera.sistemazione);
    }

    deselezionaTicket() {
        this.ticket = null;
    }

    ngOnDestroy() {
        if(this.subscription1) {this.subscription1.unsubscribe(); }
        if(this.subscription2) {this.subscription2.unsubscribe(); }
    }
}
