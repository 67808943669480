import {Component, OnDestroy} from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { BreadcrumbService } from './app.breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { MyAccount } from './domain/my.account';
import { Account } from './domain/account';
import { SessioneService } from './services/sessione.service';
import { AutenticatoreService } from './services/autenticatore.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnDestroy{

    subscription: Subscription;
    subscription2: Subscription;
    subscription3: Subscription;

    items: MenuItem[];

    utenteLoggato?: Account;
    myAccount?: MyAccount;

    constructor(public breadcrumbService: BreadcrumbService, public app: AppMainComponent, private sessione: SessioneService, private autenticatore: AutenticatoreService,
        ) {
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });
        this.subscription2 = this.sessione.operatore.subscribe(_item => {
            console.log('AppTopBarComponent, attivita\' su utenteLoggato -> ' + JSON.stringify(_item));
            this.utenteLoggato = _item;
        });
        this.subscription3 = this.sessione.myAccountObservable.subscribe(_item => {
            console.log('AppTopBarComponent, attivita\' su myAccountObservable -> ' + JSON.stringify(_item));
            this.myAccount = _item;
        });
    }

    logout() {
        this.sessione.deselezionaDispositivo();
        // this.autenticatore.logoutNoRedirect();
        // this.router.navigate(['/login/']);
        this.autenticatore.logout();
      }

    ngOnDestroy() {
        if (this.subscription) {this.subscription.unsubscribe(); }
        if (this.subscription2) {this.subscription2.unsubscribe(); }
        if (this.subscription3) {this.subscription3.unsubscribe(); }
    }
}
