import { Component, OnInit, OnDestroy } from '@angular/core';
import { ISubscription } from 'rxjs/Subscription';
import { Account } from '../../domain/account';
import { Camera } from '../../domain/camera';
import { DAService } from 'src/app/services/da.service';
import { SessioneService } from 'src/app/services/sessione.service';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { MessageService } from 'primeng/api';

@Component({
  templateUrl: './camere-view.component.html',
})
export class CamereViewComponent implements OnInit, OnDestroy {

camere?: Camera[] = [];
camera?: Camera;
cols: any[];
filtro : any;
data = new Date();

utente?: Account;
risultati?: any;

private subscription: ISubscription;

constructor(
    private sessione: SessioneService,
    private dao: DAService,
    private breadcrumbService: BreadcrumbService,
    private messageService: MessageService
) {}



  ngOnInit() {

    this.utente = this.sessione.operatoreLoggato;

    this.cols = [
        { field: 'nome', header: 'nome', style: 'center'},
        { field: 'sistemazione', header: 'Sistemazione', style: 'center'},
    ];

    this.breadcrumbService.setItems([
        { label: ' Elenco Camere', routerLink: [''] }

    ]);

    this.filtra();

}

filtra() {

    this.disiscrivi();

    this.camere = [];

    this.subscription = this.dao.getCamere(this.filtro).subscribe(items => {

        console.log('CameraViewComponent, novita sul listener ' + JSON.stringify(items));

        this.camere = items;
        if (!items || items.length === 0) {
            console.log('CameraViewComponent, nessun items');
            this.risultati = null;
            this.messageService.add({severity: 'error', summary: 'Elenco Camere', detail: 'Non hai alcuna Camera'});
        }
    });

}

deseleziona() {
    this.camera = null;
}

nuovaCamera() {
    this.camera = new Camera('');
  }

  seleziona($event) {
    console.log('seleziona ' + JSON.stringify($event));
    this.camera = $event;
  }

  ngOnDestroy() {
    this.disiscrivi();
  }

  disiscrivi() {
    if (this.subscription) {this.subscription.unsubscribe(); }
  }


}
