<div class="ui-g dashboard">

    <p-table #dt [columns]="cols" exportFilename="elenco_tickets" sortField="DataChangePrimaryID" [responsive]="true" [value]="tickets" dataKey="id">

            <ng-template pTemplate="caption">
                    <div style="text-align: right">
                        <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                        <input type="text" pInputText size="25" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')"
                            style="width:auto">
                    </div>
                </ng-template>


            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width: 3em;text-align: center"></th>
                    <th style="width: 6em;text-align: center"></th>
                    <th style="width: 9em;text-align: center">Creato</th>
                    <th style="text-align: center">Oggetto</th>
                    <th style="text-align: center">Camera</th>
                    <th style="text-align: center">Responsabile</th>
                    <th style="text-align: center"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-cols="columns">
                <tr>
                    <td>
                        <a href="#" [pRowToggler]="rowData">
                            <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                        </a>
                    </td>
                    <td style="text-align: center"><i [ngClass]="rowData | iconaTicket" [style.color]="rowData | iconaTicket : 'colore'"></i></td>
                    <td style="text-align: center">{{rowData.dataCreazione.toDate() | amLocale:'it' | amTimeAgo}}</td>
                    <td style="text-align: center">{{rowData.oggetto}}</td>
                    <td style="text-align: center">{{rowData.cameraNome}}</td>
                    <td style="text-align: center">{{rowData.operatoreNominativo}}</td>
                    <td style="text-align: center" *ngIf="rowData.stato === 'APERTO'">aperto {{rowData.dataCreazione.toDate() | amLocale:'it' | amTimeAgo}}</td>
                    <td style="text-align: center" *ngIf="rowData.stato === 'CHIUSO'">chiuso {{rowData.dataChiusura.toDate() | amLocale:'it' | amTimeAgo}}</td>
                    <td style="text-align: center" *ngIf="rowData.stato === 'IN_LAVORAZIONE'">in lavorazione da {{rowData.dataLavorazione.toDate() | amLocale:'it' | amTimeAgo}}</td>
                    <td style="text-align: center" *ngIf="rowData.stato === 'ANNULLATO'">annullato</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
                <tr>
                    <td [attr.colspan]="7">
                        <div class="ui-g ui-fluid" style="font-size:16px;padding:20px">
                            <div class="ui-g-12 ui-md-9">
                                <div class="p-grid">

                                    <div class="p-col-12 p-md-4"><b>Data apertura:</b>&nbsp;{{rowData.dataCreazione.toDate() | date :'shortDate'}} ore {{rowData.dataCreazione.toDate() | date :'shortTime'}}</div>
                                    <div class="p-col-12 p-md-4" title="ID. {{rowData.creatoDa}}"><b>Creato da:</b>&nbsp;{{rowData.creatoDaNome}}</div>
                                    <div class="p-col-12 p-md-4"><b>Richiedente:</b>&nbsp;{{rowData.creatoDaRuolo}}</div>
                                    <div class="p-col-12 p-md-4" *ngIf="rowData.cameraId"><b>Camera:</b>&nbsp;{{rowData.cameraNome}}</div>
                                    <div class="p-col-12 p-md-4" *ngIf="rowData.cameraStruttura"><b>Struttura:</b>&nbsp;{{rowData.cameraStruttura}}</div>
                                    <div class="p-col-12 p-md-4" *ngIf="rowData.ospite"><b>Ospite:</b>&nbsp;{{rowData.ospite}}</div>
                                    <div class="p-col-12 p-md-4" *ngIf="rowData.tempoLavorazione"><b>Lavorato in:</b>&nbsp;{{rowData.tempoLavorazione / 1000 / 60 | number:'1.0-0'}} minuti</div>
                                    <div class="p-col-12 p-md-4" *ngIf="rowData.tempoChiusura"><b>Chiuso in:</b>&nbsp;{{rowData.tempoChiusura / 1000 / 60 | number:'1.0-0'}} minuti</div>
                                    <div class="p-col-12 p-md-4"><b>Oggetto:</b>&nbsp;{{rowData.oggetto}}</div>

                                </div>
                            </div>
                            <div class="p-d-flex">
                                    <div class="p-mb-2 p-mr-2"><button *ngIf="rowData.stato === 'APERTO'" label="Prendi in carico" (click)="inLavorazione(rowData)" icon="fa fa-legal" pButton type="button"></button></div>
                                    <div class="p-mb-2 p-mr-2"><button *ngIf="rowData.stato === 'APERTO'" label="Annulla il Ticket" (click)="annullaTicket(rowData)" icon="fa fa-times-rectangle" pButton type="button"></button></div>
                                    <div class="p-mb-2 p-mr-2"><button *ngIf="rowData.stato === 'IN_LAVORAZIONE'" label="Chiudi il Ticket" (click)="chiudiTicket(rowData)" icon="fa fa-check"  pButton type="button"></button></div>
                                    <div class="p-mb-2 p-mr-2"><button *ngIf="utente.ruolo === 'AMMINISTRATORE'" label="Elimina" (click)="elimina(rowData)" icon="fa fa-trash" pButton type="button" class="p-button-danger"></button></div>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                    <div style="text-align: left">
                        <button type="button" pButton icon="fa fa-file-excel-o" iconPos="left" title="Export data" class="green-btn" label="Export data" (click)="dt.exportCSV()"></button>
                    </div>
                </ng-template>
    </p-table>

</div>
