
<div class="layout-dashboard">
    <div class="p-col-12">

                <p-table #dt *ngIf="!!preventivi && preventivi.length > 0" [columns]="cols" exportFilename="elenco_richiesta_preventivo" sortField="DataChangePrimaryID" [responsive]="true" [value]="preventivi" dataKey="id">

                    <ng-template pTemplate="caption">
                            <div style="text-align: right">
                                <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                                <input type="text" pInputText size="25" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    style="width:auto">
                            </div>
                        </ng-template>


                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th style="width: 3em;text-align: center"></th>
                            <th style="width: 9em;text-align: center">Data Creazione</th>
                            <th style="text-align: center">Cliente</th>
                            <th style="text-align: center">E-mail</th>
                            <th style="text-align: center">Sistemazione</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-cols="columns">
                        <tr>
                            <td>
                                <a href="#" [pRowToggler]="rowData">
                                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                                </a>
                            </td>
                            <td style="text-align: center">{{rowData.dataCreazione.toDate() | date :'shortDate'}}</td>
                                <td style="text-align: center">{{rowData.clienteNome}} {{rowData.clienteCognome}}</td>
                                <td style="text-align: center">{{rowData.clienteEmail}}</td>
                                <td style="text-align: center">{{rowData.sistemazione | sistemazione}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
                        <tr>
                            <td [attr.colspan]="5">
                                <div class="ui-g ui-fluid" style="font-size:16px;padding:20px">
                                    <div class="ui-g-12 ui-md-4">
                                        <div class="ui-g">

                                            <div class="ui-g-12 ui-md-6"><b>Adulti:</b></div>
                                            <div class="ui-g-12 ui-md-6">{{rowData.adulti}}</div>

                                            <div class="ui-g-12 ui-md-6"><b>Bambini:</b></div>
                                            <div class="ui-g-12 ui-md-6">{{rowData.bambini}}</div>

                                        </div>
                                    </div>
                                    <div class="ui-g-12 ui-md-4">
                                        <div class="ui-g">

                                            <div class="ui-g-12 ui-md-6"><b>Check in:</b></div>
                                            <div class="ui-g-12 ui-md-6">{{rowData.checkIn.toDate() | date}}</div>

                                            <div class="ui-g-12 ui-md-6"><b>Check out</b></div>
                                            <div class="ui-g-12 ui-md-6">{{rowData.checkOut.toDate() | date}}</div>

                                        </div>
                                    </div>
                                    <div class="ui-g-12 ui-md-4">
                                            <!--<p><button label="Trasforma in Preventivo" (click)="trasformaInPreventivo(rowData)" icon="fa fa-check" class="green-btn" pButton type="button"></button></p>-->
                                            <p><button label="Elimina" (click)="elimina(rowData)" icon="fa fa-trash" pButton type="button" class="red-btn"></button></p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                            <div style="text-align: left">
                                <button type="button" pButton icon="fa fa-file-excel-o" iconPos="left" title="Export data" class="green-btn" label="Export data" (click)="dt.exportCSV()"></button>
                            </div>
                        </ng-template>
            </p-table>


            </div>

            <div class="p-col-12" *ngIf="!preventivi || preventivi.length === 0">
                <h1>NON HO TROVATO ALCUNA RICHIESTA DI PREVENTIVO</h1>
            </div>
        </div>
