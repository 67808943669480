import { Component, OnInit, OnDestroy } from '@angular/core';
import { Account } from '../../domain/account';
import { MyAccount } from '../../domain/my.account';
import { FormGroup, FormControl } from '@angular/forms';
import { ISubscription } from 'rxjs/Subscription';
import { Parametro } from '../../domain/parametro';
import { AngularFirestore } from '@angular/fire/firestore';
import { SessioneService } from 'src/app/services/sessione.service';
import { DAService } from 'src/app/services/da.service';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { Costanti } from 'src/app/services/costanti';

@Component({
    templateUrl: './parametri.component.html'
})
export class ParametriComponent implements OnInit, OnDestroy {


    account?: Account;
    myAccount?: MyAccount;
    myForm: FormGroup;

    parametro?: Parametro;
    nominativo?: string;
    lastIp?: string;
    lastLogin?: any;
    uid?: string;
    subscription: ISubscription;

    nuovaPassword1: string;
    nuovaPassword2: string;

    constructor(
        private sessione: SessioneService,
        private afs: AngularFirestore,
        private dao: DAService,
        private breadcrumbService: BreadcrumbService
    ) {
        this.breadcrumbService.setItems([
            { label: 'Parametri di Sistema' }
        ]);
    }

    ngOnInit() {

        this.account = this.sessione.operatoreLoggato;
        this.myAccount = this.sessione.myAccount;

        this.subscription = this.afs.doc<Parametro>(Costanti.TABELLA_PARAMETRI + '/sistema').valueChanges().subscribe(_parametro => {
            this.parametro = _parametro;
            this.myForm = new FormGroup({
                sconto: new FormControl(this.parametro.sconto),
                scontoHp: new FormControl(this.parametro.scontoHp),
                scontoHv: new FormControl(this.parametro.scontoHv),
                scontoFr: new FormControl(this.parametro.scontoFr),
                scontoRe: new FormControl(this.parametro.scontoRe),
                animale: new FormControl(this.parametro.animale),
                tesseraClub: new FormControl(this.parametro.tesseraClub),
                tesseraInfant: new FormControl(this.parametro.tesseraInfant),
            });
        });

    }

    onSubmit() {

        if (this.myForm.status !== 'VALID') {
            console.error('form is not valid, cannot save to database');
            return;
        }

        // console.log(this.myForm.value);
        const new_data = {dataUltimaModifica: new Date(), ...this.myForm.value};
        this.dao.modifica('sistema', Costanti.TABELLA_PARAMETRI, new_data, true);

    }

    ngOnDestroy(): void {
        if (this.subscription) {this.subscription.unsubscribe(); }
    }

}
