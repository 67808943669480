import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ISubscription } from 'rxjs/Subscription';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { DAService } from 'src/app/services/da.service';
import { SessioneService } from 'src/app/services/sessione.service';
import { Account } from '../../domain/account';

@Component({
  styleUrls: ['./tabella.scss'],
  templateUrl: './account-view.component.html',
})
export class AccountViewComponent implements OnInit, OnDestroy {

accounts?: Account[] = [];
account?: Account;

  cols: any[];

  data = new Date();

  filtro?: any;

  risultati?: any;

  private subscription: ISubscription;

  constructor(
    private sessione: SessioneService,
    private dao: DAService,
    private breadcrumbService: BreadcrumbService,
    private messageService: MessageService
  ) {}



  ngOnInit() {
    this.cols = [
        { field: 'email', header: 'Email', style: 'center' },
        { field: 'displayName', header: 'Nominativo', style: 'center' },
        { field: 'dataCreazione', header: 'Data Creazione', style: 'center' },
    ];

    this.breadcrumbService.setItems([
        { label: ' Elenco Account', routerLink: [''] }

    ]);

    this.filtra();

}

filtraPerTutti() {
    this.filtro = null;
    this.messageService.add({ severity: 'success', summary: 'Filtra', detail: 'Mostro tutti gli accounts'});
    this.filtra();
}

filtraPerAmministratori() {
    this.filtro = 'AMMINISTRATORE';
    this.messageService.add({ severity: 'success', summary: 'Filtra', detail: 'Mostro solo gli Amministratori'});
    this.filtra();
}

filtraPerOperatori() {
    this.filtro = 'OPERATORE';
    this.messageService.add({ severity: 'success', summary: 'Filtra', detail: 'Mostro solo gli Operatori'});
    this.filtra();
}

filtra() {

    this.disiscrivi();

    this.accounts = [];

    this.subscription = this.dao.getAccounts(this.filtro).subscribe(items => {

        console.log('AccountViewComponent, novita sul listener ' + JSON.stringify(items));

        this.accounts = items;
        if (!items || items.length === 0) {
            console.log('AccountViewComponent, nessun accounts');
            this.risultati = null;
            this.messageService.add({severity: 'error', summary: 'Accounts', detail: 'Non c\'e\' alcun account'});
        }
    });

}

  deseleziona() {
    this.account = null;
  }

  nuovoAccount() {
    this.account = new Account(null);
  }

  seleziona($event) {
    console.log('seleziona ' + JSON.stringify($event));

    this.account = $event;

  }

  ngOnDestroy() {
    this.disiscrivi();
  }

  disiscrivi() {
    if (this.subscription) {this.subscription.unsubscribe(); }
  }


}
