import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    OnChanges,
    Output,
    EventEmitter
} from '@angular/core';
import { ISubscription } from 'rxjs/Subscription';
import * as moment from 'moment-timezone';
import { Preventivo } from '../../domain/preventivo';
import { Account } from '../../domain/account';
import { DAService } from '../../services/da.service';
import { DataService } from '../../services/data.service';
import { MessageService } from 'primeng/api';
import { EmailService } from 'src/app/services/email.service';

@Component({
    selector: 'app-preventivi',
    templateUrl: './preventivi.component.html',
})
export class PreventiviComponent implements OnInit, OnChanges, OnDestroy {

    @Input() preventivi?: Preventivo[];
    @Input() showFiltri?: boolean;
    preventivo?: Preventivo;
    @Output() chiudi = new EventEmitter();
    @Output() seleziona = new EventEmitter<any>();
    private subscription: ISubscription;

    account?: Account;
    filtro?: any;

    risultati?: any;
    cols?: any;
    data = new Date();
    soglia_data = new Date();
    locale?: any;

    constructor(
        private dao: DAService,
        private ds: DataService,
        private emailService: EmailService,
        private messageService: MessageService,
    ) {
    }

    invia(preventivo: Preventivo) {
        this.emailService.inviaPreventivo(preventivo.id);
        this.messageService.add({ severity: 'success', summary: 'ReInvia', detail: 'E-mail reinviata'});

    }

    ngOnInit() {

        this.locale = this.ds.getLocale();

        this.cols = [
            { field: 'protocollo', header: 'Protocollo', style: 'center' },
            { field: 'nomeStruttura', header: 'Struttura', style: 'center'},
            { field: 'stato', header: 'Stato', style: 'center'},
            { field: 'operatore', header: 'Operatore', style: 'center'},
        ];

    }

    ngOnChanges() {

        if (!this.preventivi) {
            this.filtra();
        }

    }

    filtraPerTutti() {
        this.filtro = null;
        // this.messageService.add({ severity: 'success', summary: 'Filtra', detail: 'Mostra tutti i preventivi'});
        this.filtra();
    }

    filtraInCorso() {
        this.filtro = 'IN_CORSO';
        // this.messageService.add({ severity: 'success', summary: 'Filtra', detail: 'Mostra solo quelli IN CORSO'});
        this.disiscrivi();

        this.preventivi = [];

        this.subscription = this.dao.getAllPreventivi(this.filtro).subscribe(items => {

            this.preventivi = items;
            if (!items || items.length === 0) {
                this.risultati = null;
                this.messageService.add({severity: 'error', summary: 'Preventivi', detail: 'Nessun risultato'});
            }
        });
    }

    filtraAccettati() {
        this.filtro = 'ACCETTATO';
        // this.messageService.add({ severity: 'success', summary: 'Filtra', detail: 'Mostra solo quelli ACCETTATI'});
        this.disiscrivi();

        this.preventivi = [];

        this.subscription = this.dao.getAllPreventivi(this.filtro).subscribe(items => {

            this.preventivi = items;
            if (!items || items.length === 0) {
                this.risultati = null;
                this.messageService.add({severity: 'error', summary: 'Preventivi', detail: 'Nessun risultato'});
            }
        });
    }

    filtraScaduti() {
       this.filtro = 'SCADUTO';
        // this.messageService.add({ severity: 'success', summary: 'Filtra', detail: 'Mostra solo quelli SCADUTI'});
       this.disiscrivi();

       this.preventivi = [];

       this.subscription = this.dao.getAllPreventivi(this.filtro).subscribe(items => {

           this.preventivi = items;
           if (!items || items.length === 0) {
               this.risultati = null;
               this.messageService.add({severity: 'error', summary: 'Preventivi', detail: 'Nessun risultato'});
           }
       });
    }

    filtraCancellati() {
        this.filtro = 'CANCELLATO';
        // this.messageService.add({ severity: 'success', summary: 'Filtra', detail: 'Mostra solo quelli CANCELLATI'});
        this.disiscrivi();

        this.preventivi = [];

        this.subscription = this.dao.getAllPreventivi(this.filtro).subscribe(items => {

            this.preventivi = items;
            if (!items || items.length === 0) {
                this.risultati = null;
                this.messageService.add({severity: 'error', summary: 'Preventivi', detail: 'Nessun risultato'});
            }
        });
    }

    filtra() {


        const _data = moment(this.data).tz('Europe/Rome').format('YYYY-MM-DD');

        console.log('Preventivi per giorno ' + _data);
        const da_data$ = moment(_data + ' 00:00:00');
        const a_data$ = moment(_data + ' 23:59:59');
        console.log('Preventivi da_data$: ' + da_data$.toDate());
        console.log('Preventivi a_data$: ' + a_data$.toDate());

        console.log('Preventivi per giorno ' + this.data);

        this.disiscrivi();

        this.preventivi = [];

        this.subscription = this.dao.getPreventivi(da_data$.toDate(), a_data$.toDate(), this.filtro).subscribe(items => {

            this.preventivi = items;
            if (!items || items.length === 0) {
                this.risultati = null;
                this.messageService.add({severity: 'error', summary: 'Preventivi', detail: 'Nessun risultato'});
            }
        });

    }

    annulla() {
        this.chiudi.emit();
    }

    ngOnDestroy() {
        this.disiscrivi();
      }

      selezionaItem(item) {
        this.seleziona.emit(item);
    }

      disiscrivi() {
        if (this.subscription) {this.subscription.unsubscribe(); }
      }

}
