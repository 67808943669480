
<div class="layout-dashboard">

    <!--<p-toolbar *ngIf="!account">
        <div class="ui-toolbar-group-left">
            <span>
                <button pButton [ngClass]="!filtro ? 'green-btn' : 'blue-btn'" type="button" label="TUTTI" (click)="filtraPerTutti()" icon="fa fa-bars" style="margin-top:0"></button>
                <button pButton [ngClass]="filtro === 'AMMINISTRATORE' ? 'green-btn' : 'blue-btn'" type="button" label="AMMINISTRATORI"  (click)="filtraPerAmministratori()" icon="fa fa-black-tie" style="margin-top:0"></button>
                <button pButton [ngClass]="filtro === 'OPERATORE' ? 'green-btn' : 'blue-btn'" type="button" label="OPERATORI" (click)="filtraPerOperatori()" icon="fa fa-users" style="margin-top:0"></button>
            </span>
        </div>
        <div class="ui-toolbar-group-right">
            <button pButton pRipple type="button" pTooltip="NUOVO ACCOUNT" class="p-button-rounded p-button-outlined p-mr-2 p-mb-2" (click)="nuovoAccount()" icon="fa fa-plus"></button>
        </div>
    </p-toolbar>-->
    
    
    <button *ngIf="!account" pButton pRipple type="button" pTooltip="NUOVO ACCOUNT" class="p-button-rounded p-button-outlined p-mr-2 p-mb-2" (click)="nuovoAccount()" icon="fa fa-plus" style="float: right;"></button>

        <div class="card"  *ngIf="accounts && accounts?.length > 0 && !account">
            <h4>Accounts</h4>
			<p>Elenco degli utenti autorizzati ad accedere alla <mark>piattaforma</mark></p>

            <p-table #dt [columns]="cols" [responsive]="true" [value]="accounts" dataKey="id">

                <ng-template pTemplate="caption">
					<div class="table-header">
						<span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
						   placeholder="Filtra"/>
                </span>
					</div>
				</ng-template>

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 13em"></th>
                        <th *ngFor="let col of columns">
                            {{col.header}}
                        </th>
                        <th style="width: 9em;text-align: center"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-cols="columns">
                    <tr>
                        <td><span *ngIf="rowData.ruolo" [class]="'product-badge status-' + rowData.ruolo.toLowerCase()">{{rowData.ruolo}}</span></td>
                        <td>{{rowData.email}}</td>
                        <td>{{rowData.displayName}}</td>
                        <td>{{rowData.dataCreazione.toDate() | date : 'shortDate'}}</td>
                        <td style="text-align: center" ><a (click)="seleziona(rowData)" style="cursor: pointer;">[dettaglio]</a></td>
                    </tr>
                </ng-template>
                <!--<ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
                    <tr>
                        <td [attr.colspan]="4">
                            <div class="p-grid" style="font-size:16px;padding:20px">
                                <div class="p-col-12 p-md-3" style="text-align:center">
                                    <i [ngClass]="rowData | ruoloIcona : 'icona'" [style.color]="rowData | ruoloIcona : 'colore'" style="font-size: 100px"></i>
                                </div>
                                <div class="p-grid">
                                    <div class="p-col-6">
                                        <b>Email:</b> {{rowData.email}}
                                    </div>
                                    <div class="p-col-6">
                                        <b>Nominativo:</b> {{rowData.displayName}}
                                    </div>
                                    <div class="p-col-6">
                                        <b>Ruolo:</b> {{rowData.ruolo}}
                                    </div>
                                    <div class="p-col-6">
                                        <b>UID:</b> {{rowData.uid}}
                                    </div>
                                    <div class="p-col-6">
                                        <b>Data Creazione:</b> {{rowData.dataCreazione.toDate() | date : 'shortDate'}}
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>-->
            </p-table>

        </div>
    </div>

    <app-account *ngIf="account" (chiudi)='deseleziona()' [account]="account"></app-account>

    <div class="card" *ngIf="accounts?.length === 0 && !account">

        <p-panel header="Accounts">
            <h1>Non c'e' alcun account</h1>
        </p-panel>
    </div>
