<div class="login-body">
    <p-toast></p-toast>
    <div class="login-wrapper">
        <div class="login-panel">
            <div *ngIf="!mostraResetPassword && !mostraSignup">
                <img src="assets/layout/images/logo-dark.svg" class="logo" alt="diamond-layout" />
                <div class="login-form">
                    <h2>Entra su Athena Resort</h2>
                    <input pInputText id="email" [(ngModel)]='username' placeholder="Email" />
                    <input [feedback]='false' [showPassword]='false' pPassword [(ngModel)]='password' placeholder="Password" />
                    <button pButton label="LOGIN" (click)='signInViaEmail()' type="button" ></button>
                </div>
                <br/>
                <p><a style="cursor: pointer;" (click)='mostraResetPassword = true'>Hai dimenticato la password ?</a></p>
            </div>
            <div *ngIf="mostraResetPassword">
                <img src="assets/layout/images/logo-dark.svg" class="logo" alt="diamond-layout" />
                <div class="login-form">
                    <h2>Password dimenticata</h2>
                    <p>Inserisci la tua e-mail e ti mandaremo un link per ripristinarla</p>
                    <input pInputText id="email" [(ngModel)]='username' placeholder="Email" />
                    <button pButton label="CONTINUA" (click)='resetPassword()' type="button" ></button>
                    <br/>
                    <p>Lascia perdere, torna al <a (click)='nascondiTutto()'>Login</a>.</p>
                </div>
            </div>
        </div>
        <div class="login-image">
            <div class="login-image-content">
                <h1>Access to your</h1>
                <h1>Account</h1>
                <h3>L'applicazione per la gestione di Athena Resort.</h3>
            </div>
            <div class="image-footer">
                <p>Seguici sui nostri social</p>
                <div class="icons">
                    <a target="_new" href="https://www.facebook.com/athenaresort/"><i class="pi pi-facebook"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
