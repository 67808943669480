/**
 * AutenticatoreService
 *
 * Gestisce le proedure di autenticazione via Google e Facebook
 *
 * Per google utilizza AngularFireAuth
 * Per Facebook invece AuthService (angular4-social-login npm)
 *
 */
import { Injectable } from '@angular/core';
import { DAService } from './da.service';
import { Costanti } from './costanti';

@Injectable()
export class EmailService {


  constructor(
    private da: DAService
  ) {}

  invia(a: string, oggetto$: string, testo$: string, bcc$?: string) {

    console.log('invia a ' + a + ': ' + oggetto$);

    if (!a || !oggetto$ || !testo$) {
        console.error('invia email senza alcuni dati');
        return;
    }

    const data = {
            to: a,
            bcc: bcc$ || null,
            oggetto: oggetto$,
            testo: testo$,
            data: new Date(),
        };

    this.da.creaSenzaChiave(data, Costanti.TABELLA_EMAILS);

}

inviaPreventivo(preventivoId: string) {

    console.log('inviaPreventivo ' + preventivoId);

    const data = {
            data: new Date(),
            chiave: preventivoId,
            tipo: 'preventivo',
        };

    this.da.creaSenzaChiave(data, Costanti.TABELLA_EMAILS);

}

}
