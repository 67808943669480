<div class="ui-g dashboard">

    <p-table #dt [columns]="cols" [responsive]="true" [value]="tickets" dataKey="id">

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width: 3em;text-align: center"></th>
                    <th style="width: 9em;text-align: center">Creato</th>
                    <th style="text-align: center">Oggetto</th>
                    <th style="text-align: center">Camera</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-cols="columns">
                <tr>
                    <td>
                        <a href="#" [pRowToggler]="rowData">
                            <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                        </a>
                    </td>
                    <td style="text-align: center">{{rowData.dataCreazione.toDate() | amLocale:'it' | amTimeAgo}}</td>
                    <td style="text-align: center">{{rowData.oggetto}}</td>
                    <td style="text-align: center">{{rowData.cameraNome}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
                <tr>
                    <td [attr.colspan]="4">
                        <div class="ui-g ui-fluid" style="font-size:16px;padding:20px">
                            <div class="ui-g-12">
                                <div class="ui-g-12 ui-md-6"><b>Data apertura:</b></div>
                                <div class="ui-g-12 ui-md-6">{{rowData.dataCreazione.toDate() | date :'shortDate'}} ore {{rowData.dataCreazione.toDate() | date :'shortTime'}}</div>
                                <div class="ui-g-12 ui-md-6" title="ID. {{rowData.creatoDa}}"><b>Creato da:</b></div>
                                <div class="ui-g-12 ui-md-6" title="ID. {{rowData.creatoDa}}">{{rowData.creatoDaNome}}</div>
                                <div class="ui-g-12 ui-md-6"><b>Richiedente:</b></div>
                                <div class="ui-g-12 ui-md-6">{{rowData.creatoDaRuolo}}</div>
                                <div class="ui-g-12 ui-md-6" *ngIf="rowData.cameraNome"><b>Camera:</b></div>
                                <div class="ui-g-12 ui-md-6" *ngIf="rowData.cameraNome">{{rowData.cameraNome}}</div>
                                <div class="ui-g-12 ui-md-6" *ngIf="rowData.cameraStruttura"><b>Struttura:</b></div>
                                <div class="ui-g-12 ui-md-6" *ngIf="rowData.cameraStruttura">{{rowData.cameraStruttura}}</div>
                                <div class="ui-g-12 ui-md-6" *ngIf="rowData.ospite"><b>Ospite:</b></div>
                                <div class="ui-g-12 ui-md-6" *ngIf="rowData.ospite">{{rowData.ospite}}</div>
                            </div>
                            <div class="ui-g-12">
                                <div class="ui-g">
                                    <div class="ui-g-12"><b>Oggetto</b></div>
                                    <div class="ui-g-12">{{rowData.oggetto}}</div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
    </p-table>

</div>
