import { Component, OnInit, OnDestroy } from '@angular/core';
import { Account } from '../../domain/account';
import { MyAccount } from '../../domain/my.account';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ISubscription } from 'rxjs/Subscription';
import { Tariffa } from '../../domain/tariffa';
import { SessioneService } from 'src/app/services/sessione.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Costanti } from 'src/app/services/costanti';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { DAService } from 'src/app/services/da.service';

@Component({
    templateUrl: './tariffe.component.html'
})
export class TariffeComponent implements OnInit, OnDestroy {

    account?: Account;
    myAccount?: MyAccount;
    myForm: FormGroup;

    tariffa?: Tariffa;
    uid?: string;
    subscription: ISubscription;

    constructor(
        private sessione: SessioneService,
        private afs: AngularFirestore,
        private dao: DAService,
        private breadcrumbService: BreadcrumbService
    ) {
        this.breadcrumbService.setItems([
            { label: 'Tariffe Annuali' }
        ]);
    }

    ngOnInit() {

        this.account = this.sessione.operatoreLoggato;
        this.myAccount = this.sessione.myAccount;
        // const pattern = '^[0-9]*$';
        const pattern = '/^[0-9]+(\.[0-9]{1,2})?$/';


        this.subscription = this.afs.doc<Tariffa>(Costanti.TABELLA_TARIFFE + '/valide').valueChanges().subscribe(_item => {
        // console.log('tariffe trovate:' + JSON.stringify(_item));

            if (!_item) { return ; }
            this.tariffa = _item;
            this.myForm = new FormGroup({
                tariffa1: new FormControl(this.tariffa.tariffa1),
                tariffa2: new FormControl(this.tariffa.tariffa2),
                tariffa3: new FormControl(this.tariffa.tariffa3),
                tariffa4: new FormControl(this.tariffa.tariffa4),
                tariffa5: new FormControl(this.tariffa.tariffa5),
                tariffa6: new FormControl(this.tariffa.tariffa6),
                tariffa7: new FormControl(this.tariffa.tariffa7),
                tariffa8: new FormControl(this.tariffa.tariffa8),
                tariffa9: new FormControl(this.tariffa.tariffa9),
                tariffa10: new FormControl(this.tariffa.tariffa10),
                tariffa11: new FormControl(this.tariffa.tariffa11),
                tariffa12: new FormControl(this.tariffa.tariffa12),
                tariffa13: new FormControl(this.tariffa.tariffa13),
                tariffa14: new FormControl(this.tariffa.tariffa14),

                tariffaHp1: new FormControl(this.tariffa.tariffaHp1),
                tariffaHp2: new FormControl(this.tariffa.tariffaHp2),
                tariffaHp3: new FormControl(this.tariffa.tariffaHp3),
                tariffaHp4: new FormControl(this.tariffa.tariffaHp4),
                tariffaHp5: new FormControl(this.tariffa.tariffaHp5),
                tariffaHp6: new FormControl(this.tariffa.tariffaHp6),
                tariffaHp7: new FormControl(this.tariffa.tariffaHp7),
                tariffaHp8: new FormControl(this.tariffa.tariffaHp8),
                tariffaHp9: new FormControl(this.tariffa.tariffaHp9),
                tariffaHp10: new FormControl(this.tariffa.tariffaHp10),
                tariffaHp11: new FormControl(this.tariffa.tariffaHp11),
                tariffaHp12: new FormControl(this.tariffa.tariffaHp12),
                tariffaHp13: new FormControl(this.tariffa.tariffaHp13),
                tariffaHp14: new FormControl(this.tariffa.tariffaHp14),

                tariffaResidenceMono1: new FormControl(this.tariffa.tariffaResidenceMono1),
                tariffaResidenceMono2: new FormControl(this.tariffa.tariffaResidenceMono2),
                tariffaResidenceMono3: new FormControl(this.tariffa.tariffaResidenceMono3),
                tariffaResidenceMono4: new FormControl(this.tariffa.tariffaResidenceMono4),
                tariffaResidenceMono5: new FormControl(this.tariffa.tariffaResidenceMono5),
                tariffaResidenceMono6: new FormControl(this.tariffa.tariffaResidenceMono6),
                tariffaResidenceMono7: new FormControl(this.tariffa.tariffaResidenceMono7),
                tariffaResidenceMono8: new FormControl(this.tariffa.tariffaResidenceMono8),
                tariffaResidenceMono9: new FormControl(this.tariffa.tariffaResidenceMono9),
                tariffaResidenceMono10: new FormControl(this.tariffa.tariffaResidenceMono10),
                tariffaResidenceMono11: new FormControl(this.tariffa.tariffaResidenceMono11),
                tariffaResidenceMono12: new FormControl(this.tariffa.tariffaResidenceMono12),
                tariffaResidenceMono13: new FormControl(this.tariffa.tariffaResidenceMono13),
                tariffaResidenceMono14: new FormControl(this.tariffa.tariffaResidenceMono14),

                tariffaResidenceTrilo1:  new FormControl(this.tariffa.tariffaResidenceTrilo1),
                tariffaResidenceTrilo2:  new FormControl(this.tariffa.tariffaResidenceTrilo2),
                tariffaResidenceTrilo3:  new FormControl(this.tariffa.tariffaResidenceTrilo3),
                tariffaResidenceTrilo4:  new FormControl(this.tariffa.tariffaResidenceTrilo4),
                tariffaResidenceTrilo5:  new FormControl(this.tariffa.tariffaResidenceTrilo5),
                tariffaResidenceTrilo6:  new FormControl(this.tariffa.tariffaResidenceTrilo6),
                tariffaResidenceTrilo7:  new FormControl(this.tariffa.tariffaResidenceTrilo7),
                tariffaResidenceTrilo8:  new FormControl(this.tariffa.tariffaResidenceTrilo8),
                tariffaResidenceTrilo9:  new FormControl(this.tariffa.tariffaResidenceTrilo9),
                tariffaResidenceTrilo10: new FormControl(this.tariffa.tariffaResidenceTrilo10),
                tariffaResidenceTrilo11: new FormControl(this.tariffa.tariffaResidenceTrilo11),
                tariffaResidenceTrilo12: new FormControl(this.tariffa.tariffaResidenceTrilo12),
                tariffaResidenceTrilo13: new FormControl(this.tariffa.tariffaResidenceTrilo13),
                tariffaResidenceTrilo14: new FormControl(this.tariffa.tariffaResidenceTrilo14),
            });
        });

    }

    ngOnInitOk() {

        this.account = this.sessione.operatoreLoggato;
        this.myAccount = this.sessione.myAccount;
        // const pattern = '^[0-9]*$';
        const pattern = '/^[0-9]+(\.[0-9]{1,2})?$/';


        this.subscription = this.afs.doc<Tariffa>(Costanti.TABELLA_TARIFFE + '/valide').valueChanges().subscribe(_item => {
        // console.log('tariffe trovate:' + JSON.stringify(_item));

            if (!_item) { return ; }
            this.tariffa = _item;
            this.myForm = new FormGroup({
                tariffa1: new FormControl(this.tariffa.tariffa1,   Validators.pattern(pattern), ),
                tariffa2: new FormControl(this.tariffa.tariffa2,   Validators.pattern(pattern), ),
                tariffa3: new FormControl(this.tariffa.tariffa3,   Validators.pattern(pattern), ),
                tariffa4: new FormControl(this.tariffa.tariffa4,   Validators.pattern(pattern), ),
                tariffa5: new FormControl(this.tariffa.tariffa5,   Validators.pattern(pattern), ),
                tariffa6: new FormControl(this.tariffa.tariffa6,   Validators.pattern(pattern), ),
                tariffa7: new FormControl(this.tariffa.tariffa7,   Validators.pattern(pattern), ),
                tariffa8: new FormControl(this.tariffa.tariffa8,   Validators.pattern(pattern), ),
                tariffa9: new FormControl(this.tariffa.tariffa9,   Validators.pattern(pattern), ),
                tariffa10: new FormControl(this.tariffa.tariffa10,  Validators.pattern(pattern), ),
                tariffa11: new FormControl(this.tariffa.tariffa11,  Validators.pattern(pattern), ),
                tariffa12: new FormControl(this.tariffa.tariffa12,  Validators.pattern(pattern), ),
                tariffa13: new FormControl(this.tariffa.tariffa13,  Validators.pattern(pattern), ),
                tariffa14: new FormControl(this.tariffa.tariffa14,  Validators.pattern(pattern), ),

                tariffaHp1: new FormControl(this.tariffa.tariffaHp1,   Validators.pattern(pattern), ),
                tariffaHp2: new FormControl(this.tariffa.tariffaHp2,   Validators.pattern(pattern), ),
                tariffaHp3: new FormControl(this.tariffa.tariffaHp3,   Validators.pattern(pattern), ),
                tariffaHp4: new FormControl(this.tariffa.tariffaHp4,   Validators.pattern(pattern), ),
                tariffaHp5: new FormControl(this.tariffa.tariffaHp5,   Validators.pattern(pattern), ),
                tariffaHp6: new FormControl(this.tariffa.tariffaHp6,   Validators.pattern(pattern), ),
                tariffaHp7: new FormControl(this.tariffa.tariffaHp7,   Validators.pattern(pattern), ),
                tariffaHp8: new FormControl(this.tariffa.tariffaHp8,   Validators.pattern(pattern), ),
                tariffaHp9: new FormControl(this.tariffa.tariffaHp9,   Validators.pattern(pattern), ),
                tariffaHp10: new FormControl(this.tariffa.tariffaHp10,  Validators.pattern(pattern), ),
                tariffaHp11: new FormControl(this.tariffa.tariffaHp11,  Validators.pattern(pattern), ),
                tariffaHp12: new FormControl(this.tariffa.tariffaHp12,  Validators.pattern(pattern), ),
                tariffaHp13: new FormControl(this.tariffa.tariffaHp13,  Validators.pattern(pattern), ),
                tariffaHp14: new FormControl(this.tariffa.tariffaHp14,  Validators.pattern(pattern), ),
            });
        });

    }

    onSubmit() {

        if (this.myForm.status !== 'VALID') {
            console.error('form is not valid, cannot save to database');
            return;
        }

        // console.log(this.myForm.value);
        const new_data = {dataUltimaModifica: new Date(), ...this.myForm.value};
        this.dao.modifica('valide', Costanti.TABELLA_TARIFFE, new_data, true);

    }

    ngOnDestroy(): void {
        if (this.subscription) {this.subscription.unsubscribe(); }
    }

}
