<div class="layout-dashboard">
    <div class="p-col-12" *ngIf="!pagamento">
        <div class="card">
				<div class="card-header">
					<h4>Elenco Pagamenti</h4>
				</div>
				<!--<p>I pagamenti che hai ricevuto tramite carta di credito.</p>-->
				<!--
				<p><b>REQUIRES_PAYMENT_METHOD</b> vuol dire che il pagamento e' stato generato ma il cliente non ha ancora inserito i dati della carta di credito.
				<p><b>SUCCEEDED</b> o <b>CHARGED</b> vuol dire che il pagamento e' stato eseguito.</p>
				-->
				<p><span [class]="'product-badge status-requires_payment_method'">REQUIRES_PAYMENT_METHOD</span> vuol dire che il pagamento e' stato generato ma il cliente non ha ancora inserito i dati della carta di credito.</p>
				<p><span [class]="'product-badge status-succeeded'">SUCCEEDED</span> o <span [class]="'product-badge status-succeeded'">CHARGED</span> vuol dire invece che il pagamento e' stato completato con successo.</p>

                <p-table [value]="pagamenti" [paginator]="true" [rows]="5">
					<ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="dataCreazione" style="text-align: left">Data Creazione<p-sortIcon field="dataCreazione"></p-sortIcon></th>
                            <th pSortableColumn="amount" style="text-align: right;">Importo<p-sortIcon field="amount"></p-sortIcon></th>
                            <th style="text-align: center">E-mail</th>
                            <th pSortableColumn="stato" style="text-align: center">Stato<p-sortIcon field="stato"></p-sortIcon></th>
                            <th style="text-align: center">Preventivo</th>
                            <!--<th style="width: 3em"></th>-->
                        </tr>
					</ng-template>
					<ng-template pTemplate="body" let-rowData>
						<tr>
							<td style="text-align: left;">{{rowData.dataCreazione.toDate() | date :'medium'}}</td>
							<td style="text-align: right;">{{rowData.amount / 100 | currency :'EUR'}}</td>
							<!--<td style="text-align: center">{{rowData.token.email}}</td>-->
							<td style="text-align: center">{{rowData.preventivo?.clienteEmail || '***'}}</td>
							<td style="text-align: center">
								<span *ngIf="rowData.stato" [class]="'product-badge status-' + rowData.stato.toLowerCase()">{{rowData.stato}}</span>
								<!--<span *ngIf="!rowData.stato" [class]="'product-badge status-not_charged'">NOT CHARGED</span>-->
								<span *ngIf="!rowData.stato" [class]="'product-badge status-' + rowData.status">{{rowData.status}}</span>
							</td>
							<td style="text-align: center">{{rowData.preventivo?.numero || '***'}}</td>
							<!--<td style="text-align: center"><button pButton type="button" (click)="pagamento=rowData" icon="pi pi-search" class="p-button-rounded p-button-outlined p-mr-2 p-mb-2"></button></td>-->
						</tr>
					</ng-template>
                </p-table>
                
			</div>
		</div>

	</div>
    <!--<div class="p-col-12" *ngIf="pagamento">
        <app-pagamento (chiudi)="deseleziona()" [pagamento]="pagamento"></app-pagamento>
    </div>-->

	<div class="p-col-12" *ngIf="pagamento">
        <app-preventivo (chiudi)='deseleziona()' [preventivo]="pagamento.preventivo"></app-preventivo>
    </div>
