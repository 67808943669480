import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ISubscription } from 'rxjs/Subscription';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { Customer } from 'src/app/demo/domain/customer';
import { CustomerService } from 'src/app/demo/service/customerservice';
import { DAService } from 'src/app/services/da.service';
import { SessioneService } from 'src/app/services/sessione.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Account } from '../../domain/account';
import { Cliente } from '../../domain/cliente';
import { Preventivo } from '../../domain/preventivo';

@Component({
  templateUrl: './clienti-view.component.html',
  styleUrls: ['./tabledemo.scss']
})
export class ClientiViewComponent implements OnInit, OnDestroy {

clienti?: Cliente[] = [];
cliente?: Cliente;
preventivo?: Preventivo;
customers1: Customer[];

  cols: any[];

  data = new Date();

  utente?: Account;
  risultati?: any;

  cognome?: string;
  citta?: string;

  private subscription: ISubscription;

  constructor(
    private sessione: SessioneService,
    private dao: DAService,
    private breadcrumbService: BreadcrumbService,
    private utility: UtilityService,
    private messageService: MessageService,
    private customerService: CustomerService
  ) {}



  ngOnInit() {

    this.utente = this.sessione.operatoreLoggato;
    this.customerService.getCustomersMedium().then(customers => this.customers1 = customers);

    this.cols = [
        { field: 'cognome', header: 'Cognome', style: 'center' },
        { field: 'nome', header: 'nome', style: 'center'},
        { field: 'citta', header: 'Citta\'', style: 'center'},
    ];

    this.breadcrumbService.setItems([
        // { label: ' ', routerLink: [''] },
        { label: ' Elenco Clienti', routerLink: [''] }

    ]);

    this.filtra();

}

filtra() {

    this.disiscrivi();

    this.clienti = [];

    /*this.subscription = this.dao.getClienti().subscribe(items => {

        console.log('RichiesteViewComponent, novita sul listener ' + JSON.stringify(items));

        this.clienti = items;
        if (!items || items.length === 0) {
            console.log('RichiesteViewComponent, nessun items');
            this.risultati = null;
            this.messageService.add({severity: 'error', summary: 'Elenco Clienti', detail: 'Non hai alcuna Cliente'});
        }
    });*/

}

filtraPerCognome() {

    const filtro = this.utility.formattaFiltroDiRicerca(this.cognome);

    if (!filtro) {
        console.log('filtro invalido');
        this.clienti = [];
        this.messageService.add({ severity: 'info', summary: 'Cerca', detail: 'filtro non valido' });
        return;
    }
    this.disiscrivi();
    this.subscription = this.dao.getClientiPerCognome(filtro[0], filtro[1]).subscribe(items => this.clienti =  items);

}

filtraPerCitta() {

    const filtro = this.utility.formattaFiltroDiRicerca(this.citta);

    if (!filtro) {
        console.log('filtro invalido');
        this.clienti = [];
        this.messageService.add({ severity: 'info', summary: 'Cerca', detail: 'filtro non valido' });
        return;
    }
    this.disiscrivi();
    this.subscription = this.dao.getClientiPerCitta(filtro[0], filtro[1]).subscribe(items => this.clienti =  items);

}

deselezionaPreventivo() {
    this.preventivo = null;
}

deseleziona() {
    this.cliente = null;
}

nuovoCliente() {
    this.cliente = new Cliente(this.utente.email);
  }

  seleziona($event) {
    console.log('seleziona ' + JSON.stringify($event));

    this.cliente = $event;

  }

  creaPreventivo($event) {
    console.log('creaPreventivo ' + JSON.stringify($event));
    this.cliente = $event;
    this.preventivo = new Preventivo(this.utente.uid, this.cliente);
  }

  ngOnDestroy() {
    this.disiscrivi();
  }

  disiscrivi() {
    if (this.subscription) {this.subscription.unsubscribe(); }
  }


}
