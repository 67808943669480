export class Costanti {

  // ATTENZIONE AL CORRISPETTIVO SULLE CLOUD FUNCTIONS

  public static TABELLA_ACCESS_LOGS = 'access-logs';
  public static TABELLA_ACCOUNT = 'account';
  public static TABELLA_MY_ACCOUNT = 'my-account';
  public static TABELLA_CAMERE = 'camere';
  public static TABELLA_PREVENTIVI = 'preventivi';
  public static TABELLA_RICHIESTE_PREVENTIVO = 'richieste-preventivo';
  public static TABELLA_CLIENTI = 'clienti';
  public static TABELLA_EMAILS = 'email';
  public static TABELLA_CHIAVI = 'chiavi';
  public static TABELLA_CHIAMATE = 'chiamate';
  public static TABELLA_CREDITI = 'crediti';
  public static TABELLA_DM = 'data-mining';
  public static TABELLA_SMARTPHONES = 'smartphones'; // 'smartphones'; smarpthones1
  public static TABELLA_TEST = 'test';
  public static TABELLA_TGATES = 'tgates';
  public static TABELLA_MESSAGGI = 'messaggi';
  public static TABELLA_MESSAGGI_USCITA = 'messaggi-uscita';
  public static TABELLA_OPERATORI = 'operatori';
  public static TABELLA_PAGAMENTI = 'pagamenti';
  public static TABELLA_PARAMETRI = 'parametri';
  public static TABELLA_TARIFFE = 'tariffe';
  public static TABELLA_TICKETS = 'tickets';
  public static TABELLA_VARCHI = 'varchi';

  public static BCC_DEFAULT = 'vtumino@resec.it';

  public static SUPPLEMENTO_NOTTI = 20;
  public static SUPPLEMENTO_VISTA_MARE = 10;
  public static SUPPLEMENTO_SINGOLA = 20;
  public static SUPPLEMENTO_DUS = 30; // 
  public static SUPPLEMENTO_BALCONE_VERANDA = 10;
  public static SUPPLEMENTO_ANIMALI = 42;
  public static RIDUZIONE_MEZZA_PENSIONE = 5;

  // public static PAUSA_INIZIO =  new Date(2018, 8, 28); // 7/10
  // public static PAUSA_FINE =  new Date(2018, 3, 5); // 14/4

  public static APERTURA_RESORT =  new Date(2024, 3, 12); // (2023, 3, 17);
  public static CHIUSURA_RESORT =  new Date(2024, 8, 29); // (2023, 8, 24);

  // ECONOMY STUFF
  // dal 2 Luglio al 3 Settembre;

  public static SCONTO_EXTRA_ECONOMY = 15;
  public static ECONOMY_INIZIO =  new Date(2021, 6, 2);
  public static ECONOMY_FINE =  new Date(2021, 8, 3);

  public static INIZIO_PAUSA_SUPPLEMENTO_SOGGIORNI_BREVI =  new Date(2021, 7, 6); // 6/8
  public static FINE_PAUSA_SUPPLEMENTO_SOGGIORNI_BREVI =  new Date(2021, 7, 27); // 27/8


  public static ANNO =  2024;

  public static RUOLO_AMMINISTRATORE = 'AMMINISTRATORE';
  public static RUOLO_OPERATORE = 'OPERATORE';
  public static RUOLO_AUTISTA = 'AUTISTA';

  // public static LINK_DISCIPLINARE = 'http://www.distrettoturisticodegliiblei.it/attachments/article/53/Documentazione%20di%20sistema_Disciplinari.pdf';
  public static LINK_DISCIPLINARE = 'http://www.distrettoturisticodegliiblei.it/attachments/article/53/Modulo-evidenze-Requisiti-facoltativi.pdf';

  public static FORMATTER_DATA_SHORT = 'DD/MM HH:mm';


  public static LABEL_SEPARATOR = ';';
  public static STANDARD_SEPARATOR = ';';

  public static ALIQUOTA_ANTICIPO = 30; // ALIQUOTA RICHIESTA PER BLOCCARE IL PREVENTIVO

  public static GIORNI_SCADENZA_PREVENTIVO = 14; // era 7


  public static URL = 'https://atena-12f1e.firebaseapp.com/';

  public static COMBO_SISTEMAZIONI = [
    { label: 'HOTEL', value: 'HP' },
    { label: 'VILLAGE', value: 'HV' },
    { label: 'FAMILY ROOM', value: 'FR' },
    { label: 'RESIDENCE', value: 'RE' },
    ];

public static COMBO_TIPI_RESIDENCE = [
    { label: 'MONOLOCALE', value: 'MONOLOCALE' },
    { label: 'TRILOCALE', value: 'TRILOCALE' },
];

public static COMBO_STRUTTURE = [
    { label: 'HOTEL PALACE', value: 'HP' },
    { label: 'HOTEL VILLAGE', value: 'HV' },
    { label: 'FAMILY ROOM', value: 'FR' },
    { label: 'COTTAGE', value: 'CO' },
];

  public static TENDINA_SISTEMAZIONI = [
    { name: 'HOTEL PALACE', code: 'HP' },
    { name: 'HOTEL VILLAGE', code: 'HV' },
    { name: 'FAMILY ROOM', code: 'FR' },
];

  public static COMBO_SETTORI = [
    { label: '-- TIPO ATTIVITA\' --', value: null },
    { label: 'Struttura ricettiva con ristorazione', value: 'A' },
    { label: 'Struttura ricettiva senza ristorazione', value: 'B' },
    { label: 'Struttura di ristorazione tradizionale', value: 'C' },
    { label: 'Struttura di ristorazione veloce', value: 'D' },
    { label: 'Struttura balneare', value: 'E' },
    { label: 'Vendita prodotti tipici del settore agroalimentare', value: 'F' },
    { label: 'Altro', value: 'ALTRO' },
  ];

  public static MESI = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];

  public static COMBO_CITTA = [
    { label: '-- CITTA\' --', value: null },
    { label: 'Acate', value: 'Acate' },
    { label: 'Chiaramonte Gulfi', value: 'Chiaramonte Gulfi' },
    { label: 'Comiso', value: 'Comiso' },
    { label: 'Giarratana', value: 'Giarratana' },
    { label: 'Grammichele', value: 'Grammichele' },
    { label: 'Ispica', value: 'Ispica' },
    { label: 'Licodia', value: 'Licodia' },
    { label: 'Mazzarrone', value: 'Mazzarrone' },
    { label: 'Modica', value: 'Modica' },
    { label: 'Monterosso Almo', value: 'Monterosso Almo' },
    { label: 'Pozzallo', value: 'Pozzallo' },
    { label: 'Ragusa', value: 'Ragusa' },
    { label: 'Rosolini', value: 'Rosolini' },
    { label: 'Pachino', value: 'Pachino' },
    { label: 'Portopalo', value: 'Portopalo' },
    { label: 'Santa Croce Camerina', value: 'Santa Croce Camerina' },
    { label: 'Scicli', value: 'Scicli' },
    { label: 'Vittoria', value: 'Vittoria' },
    { label: 'Vizzini', value: 'Vizzini' },
  ];

  public static COMBO_MODALITA = [
    { label: '-- MODALITA\' --', value: null },
    { label: 'TELEFONO', value: 'TELEFONO' },
    { label: 'E-MAIL', value: 'E-MAIL' },
    { label: 'WHATSAPP', value: 'WHATSAPP' },
  ];

  public static COMBO_RUOLI = [
    { label: '-- RUOLI --', value: null },
    { label: 'AMMINISTRATORE', value: 'AMMINISTRATORE' },
    { label: 'OPERATORE', value: 'OPERATORE' },
    { label: 'RESPONSABILE', value: 'RESPONSABILE' },
    { label: 'GOVERNANTE', value: 'GOVERNANTE' },
    { label: 'AUTISTA', value: 'AUTISTA' },
  ];

  public static COMBO_TIPO_VARCO_EN = [
    { label: '-- GATE TYPE --', value: null },
    { label: 'OTHER', value: 'ALTRO' },
    { label: 'DRIVEWAY', value: 'CANCELLO_CARRABILE' },
    // { label: 'ROOM', value: 'CAMERA' },
    // { label: 'SERVICE', value: 'SERVIZI' },
    { label: 'PEDESTRIAN', value: 'CANCELLO_PEDONALE' },
    { label: 'BOX', value: 'BOX' },
    { label: 'LIGHT', value: 'LUCI' },
    // { label: 'PARTY', value: 'EVENTO_TEMPORANEO' },
    // { label: 'PARTY', value: 'EVENTO_TEMPORANEO' },
    { label: 'DOOR', value: 'PORTA' },
  ];

  public static COMBO_TIPO_TICKET = [
    { label: 'ALTRO', value: 'ALTRO' },
    { label: 'MANUTENZIONE', value: 'MANUTENZIONE' },
    { label: 'SPAZI_ESTERNI', value: 'SPAZI_ESTERNI' },
    { label: 'BAR', value: 'BAR' },
    { label: 'ANIMAZIONE', value: 'ANIMAZIONE' },
  ];

}
