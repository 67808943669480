<div class="p-grid" *ngIf="parametro">

    <div class="p-col-12">
        <div class="card">
            <p-toolbar>
                <div class="p-toolbar-group-left">
                    <button pButton label="Save" (click)="onSubmit()" icon="fa fa-save" type="button" [disabled]="!myForm?.valid"></button>
                </div>
            </p-toolbar>
        </div>
    </div>


    <!-- Left Side -->
    <div class="p-col-12 p-md-6">
        <div class="card">
        <div class="card-header">
            <h1>Parametri di Sistema</h1>
        </div>
            <div class="ui-g form-group">
                <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
                    <div class="p-d-flex p-flex-column p-flex-md-row">

                            <div class="p-mr-2">
                                <label for="Animali">Animali</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon"><i class="fa fa-paw"></i></span>
                                    <input id="Animali" placeholder="Animali" type="text" formControlName="animale" pInputText/>
                                    <span class="p-inputgroup-addon">€</span>
                                    <span class="p-inputgroup-addon">.00</span>
                                </div>
                            </div>

                            <div class="p-mr-2">
                                <label for="Infant">Infant</label>
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon"><i class="fa fa-child"></i></span>
                                <input id="Infant" placeholder="Infant" type="text" formControlName="tesseraInfant" pInputText/>
                                <span class="p-inputgroup-addon">€</span>
                                <span class="p-inputgroup-addon">.00</span>
                      </div>
                    </div>

                    <div class="p-mr-2">
                        <label for="Tessere">Tessere Club</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon"><i class="fa fa-credit-card"></i></span>
                                <input id="Tessere" placeholder="Tessere Club" type="text" formControlName="tesseraClub" pInputText/>
                                <span class="p-inputgroup-addon">€</span>
                                <span class="p-inputgroup-addon">.00</span>
                        </div>
                    </div>
                    </div>
                </form>
            </div>

        </div>
    </div>

    <!-- Right Side -->
    <div class="p-col-12 p-md-6">
        <div class="card">
            <div class="card-header">
            <h1>Politiche di Sconto</h1>
            </div>
            <div class="form-group">
                <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
                    <div class="p-d-flex p-flex-column p-flex-md-row">

                        <div class="p-mr-2">
                            <label for="sconto">Sconto HP</label>
                            <div class="p-inputgroup">
                                <input id="sconto" placeholder="Sconto %" type="text" formControlName="scontoHp" pInputText/>
                                <span class="p-inputgroup-addon">%</span>
                        </div>
                    </div>

                    <div class="p-mr-2">
                        <label for="sconto">Sconto HV</label>
                            <div class="p-inputgroup">
                                <input id="sconto" placeholder="Sconto %" type="text" formControlName="scontoHv" pInputText/>
                                <span class="p-inputgroup-addon">%</span>
                        </div>
                    </div>

                    <div class="p-mr-2">
                        <label for="sconto">Sconto FR</label>
                        <div class="p-inputgroup">
                            <input id="sconto" placeholder="Sconto %" type="text" formControlName="scontoFr" pInputText/>
                                <span class="p-inputgroup-addon">%</span>
                        </div>
                    </div>

                    <div class="p-mr-2">
                        <label for="sconto">Sconto Residence</label>
                        <div class="p-inputgroup">
                            <input id="sconto" placeholder="Sconto %" type="text" formControlName="scontoRe" pInputText/>
                                <span class="p-inputgroup-addon">%</span>
                        </div>
                    </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>
