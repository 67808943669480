import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Preventivo } from '../domain/preventivo';
import { Costanti } from '../services/costanti';
import { ISubscription } from 'rxjs/Subscription';
// import { ElementOptions, ElementsOptions, StripeService } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import * as strippe from 'ngx-stripe';
import { PreventivoService } from '../services/preventivo.service';
import { MessageService } from 'primeng/api';

@Component({
    templateUrl: './app.shop.component.html'
})
export class AppShopComponent implements OnInit, OnDestroy {

      // parametri http
      idPreventivo: any;

      nominativo: any;
      cvc: any;
      scadenza: any;
      preventivo?: Preventivo;
      anticipo = 0;
      amount = 0;
      aliquotaAnticipo = Costanti.ALIQUOTA_ANTICIPO;
  
      handler: any;
      
      
      private subscription: ISubscription;
      
    // STRIPE
    @ViewChild(StripeCardComponent) carta: StripeCardComponent;
    @ViewChild('cardElement') cardElement: ElementRef;
    // @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
    // @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;


    element: strippe.StripeElementsService;
    error: any;
    complete = false;
    elementsOptions: StripeElementsOptions = {
      locale: 'en'
    };

  cardOptions: StripeCardElementOptions = {
     /*style: {
       base: {
         iconColor: '#276fd3',
         color: '#31325F',
         lineHeight: '40px',
         fontWeight: 300,
         fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
         fontSize: '18px',
         '::placeholder': {
           color: '#CFD7E0'
         }
       }
     },*/
    hidePostalCode: true,
    /*style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#fff',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#87BBFD',
        },
      },
      invalid: {
        iconColor: '#FFC7EE',
        color: '#FFC7EE',
      },
    },*/
  };

    constructor(
        private afs: AngularFirestore,
        private route: ActivatedRoute,
        private ps: PreventivoService,
        private messageService: MessageService,
        private stripeService: StripeService,
    ) {

        console.log('ShopperComponent CONSTRUCTOR');

    }

    print() {
        window.print();
    }

     ngOnDestroy(): void {
         if (this.subscription) {this.subscription.unsubscribe(); }
     }
 
     ngOnInit() {
         this.idPreventivo = this.route.snapshot.paramMap.get('cp');
         console.log('ShopperComponent CP --------> ' + this.idPreventivo);
 
         this.subscription = this.afs.doc<Preventivo>(Costanti.TABELLA_PREVENTIVI + '/' + this.idPreventivo).valueChanges().subscribe(_preventivo => {
             console.log('ShopperComponent novita\' sul listener,  _preventivo --------> ' + JSON.stringify(_preventivo));
             this.preventivo = _preventivo;
             this.preventivo.id = this.idPreventivo;
             this.anticipo = (this.preventivo.totale * Costanti.ALIQUOTA_ANTICIPO ) / 100;
             this.amount = this.preventivo.totale * Costanti.ALIQUOTA_ANTICIPO;
         });
     }

  createToken(): void {
      // const name = this.stripeTest.get('name').value;
      const name = 'vincenzo';
      this.stripeService
        .createToken(this.carta.element, { name })
        .subscribe((result) => {
          if (result.token) {
            console.log('token is: ' + result.token.id);
          } else if (result.error) {
            console.error('error is: ' + result.error.message);
          }
        });
      }
      
      paga() {

        const parameter = {
          payment_method: {
            card: this.carta.element
          }
        };

        this.stripeService.confirmCardPayment(this.preventivo.clientSecret, parameter).subscribe((result) => {
          if (result.error) {
            console.error('Payment failed -> ' + result.error.message);
            this.ps.erroreNelPagamento(this.preventivo, result.error.message);
            this.messageService.add({severity: 'error', summary: 'Acconto su Preventivo', detail: 'Qualcosa e\' andato storto, addebito fallito: ' + result.error.message});
          } else {
            console.log('Payment has succeeded.');
            this.ps.pagamentoConSuccesso(this.preventivo);
            this.messageService.add({severity: 'info', summary: 'Acconto su Preventivo', detail: 'Addebito avvenuto con successo. Grazie :)'});
        }
        });

    }

 }
