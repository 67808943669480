/**
 * AutenticatoreService
 *
 * Gestisce le proedure di autenticazione via Google e Facebook
 *
 * Per google utilizza AngularFireAuth
 * Per Facebook invece AuthService (angular4-social-login npm)
 *
 */
import { Injectable } from '@angular/core';
import { DAService } from './da.service';
import { Costanti } from './costanti';
import { Preventivo } from '../domain/preventivo';

@Injectable()
export class PaymentService {

  constructor(
    private da: DAService
  ) {}

  // processPayment(token: any, amount: number, preventivo: string) {
  processPayment(token: any, amount: number, preventivo: Preventivo, idPreventivo: string) {

    const dataCreazione = new Date();
    const cliente = preventivo.cliente;
    const nome = preventivo.clienteNome;
    const cognome = preventivo.clienteCognome;
    const payment = { token, amount, dataCreazione, cliente , nome, cognome, idPreventivo };

    console.log('processPayment --------> ' + JSON.stringify(payment));
    // return this.da.crea(idPreventivo, payment, Costanti.TABELLA_PAGAMENTI);
    return this.da.creaSenzaChiave(payment, Costanti.TABELLA_PAGAMENTI);

  }
}
