import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { AngularFirestore } from '@angular/fire/firestore';
import { ReplaySubject } from 'rxjs';
import { Account } from '../domain/account';
import { Costanti } from './costanti';
import { Tgate } from '../domain/tgate';
import { MyAccount } from '../domain/my.account';
import { Parametro } from '../domain/parametro';
import { Tariffa } from '../domain/tariffa';

@Injectable()
export class SessioneService {

  // private varcoSelezionatoSource: BehaviorSubject<Varco>;
  private operatoreUidSource: Subject<string> = new ReplaySubject<string>();
  private displayNameSource: Subject<string> = new ReplaySubject<string>();
  private codiceHwSource: Subject<string> = new ReplaySubject<string>();
  private operatoreSource: Subject<Account> = new ReplaySubject<Account>();
  private myAccountSource: Subject<MyAccount> = new ReplaySubject<MyAccount>();
  private tgateSource: Subject<Tgate> = new ReplaySubject<Tgate>();

  operatoreLoggato?: Account;
  parametriDiSistema?: Parametro;
  tariffe?: Tariffa;
  myAccount?: MyAccount;
  uidConnesso?: string;

  utenteLoggato?: Observable<Account>;

  operatore = this.operatoreSource.asObservable();
  myAccountObservable = this.myAccountSource.asObservable();
  operatoreUid = this.operatoreUidSource.asObservable();
  codiceHwSelezionato = this.codiceHwSource.asObservable();
  displayName = this.displayNameSource.asObservable();

  operatoriCollection?: any;

  constructor(private afs: AngularFirestore) {


  }

  selezionaTgate(tgate: Tgate) {
    this.tgateSource.next(tgate);
  }

  deselezionaTgate() {
    // console.log('Sessione, deseleziona dispositivo');
    this.tgateSource.next(null);
  }

  selezionadisplayName(displayName: string) {
    console.log('--1-1-1-1-1-1 Sessione, selezionadisplayName ' + displayName);
    this.displayNameSource.next(displayName);
  }



  selezionaCodiceHW(codiceHw: string) {
    // console.log('Sessione.selezionaCodiceHW');
    this.codiceHwSource.next(codiceHw);
    // this.loadSistemaByCodiceHw(codiceHw);
  }

  /**
   *
   * invocata da LoginComponent non appena riconosce un utente correttamente loggato
   * Configura le variabli di sessione e scarica i Documents Account (read-only) e MyAccount
   *
   * @param uid
   */
  setOperatoreUid(uid: string) {

    // console.log('-------> SESSIONE -> setOperatoreUid ' + uid);

    this.operatoreUidSource.next(uid);
    this.uidConnesso = uid;

    const subrscriber1 = this.afs.doc<Account>('account/' + uid).valueChanges().subscribe(_item => {
        _item.id = uid;
        this.operatoreLoggato = _item;
        this.operatoreSource.next(_item);

    });

    const subrscriber2 = this.afs.doc<MyAccount>(Costanti.TABELLA_MY_ACCOUNT + '/' + uid).valueChanges().subscribe(_item => {
        this.myAccount = _item;
        this.myAccountSource.next(_item);

    });

    // const subrscriber2 = this.afs.doc<MyAccount>(Costanti.TABELLA_MY_ACCOUNT + '/' + uid).valueChanges().subscribe(_item => this.myAccount = _item);

    // adesso aggiorno MY-ACCOUNT con i dati relativi al login

    // this.utenteService.aggiornaLastLogin(uid);

  }

  deselezionaDispositivo() {
    // console.log('Sessione, deseleziona dispositivo');
    // this.dispositivoSelezionatoSource.next(null);
    this.codiceHwSource.next(null);
  }

  deselezionaCodiceHardware() {
    // console.log('Sessione, deseleziona dispositivo');
    this.codiceHwSource.next(null);
  }


}
