import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    OnChanges,
    Output,
    EventEmitter,
    Inject
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as jsPDF from 'jspdf';
import { AngularFireStorage } from '@angular/fire/storage';
import { ISubscription } from 'rxjs/Subscription';
import { Cliente } from 'src/app/domain/cliente';
import { Preventivo } from 'src/app/domain/preventivo';
import { Pagamento } from 'src/app/domain/pagamento';
import { Account } from 'src/app/domain/account';
import { SessioneService } from 'src/app/services/sessione.service';
import { Observable } from 'rxjs';
import { DAService } from 'src/app/services/da.service';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Costanti } from 'src/app/services/costanti';
import { ClienteService } from 'src/app/services/cliente.service';

@Component({
    selector: 'app-cliente',
    templateUrl: './cliente.component.html',
    styles: [`
        .errore {
            font-family: monospace;
            color: red;
            font-weight: bold;
        }`
    ]
})
export class ClienteComponent implements OnInit, OnChanges, OnDestroy {

    @Input() cliente?: Cliente;
    @Output() chiudi = new EventEmitter();

    preventivo?: Preventivo;
    preventivi?: Preventivo[];
    pagamenti?: Pagamento[];

    myForm: FormGroup;

    account?: Account;

    auditor?: any;

    accetto = false;

    showCheckList = false;

    comboAuditor?: SelectItem[] = [];

    comboSettori: SelectItem[] = Costanti.COMBO_SETTORI;
    comboCitta: SelectItem[] = Costanti.COMBO_CITTA;

    uploadedFiles: any[] = [];

    profileUrl: Observable<string | null>;
    evidenzeUrl: Observable<string | null>;

    private subscription1: ISubscription;
    private subscription2: ISubscription;


    constructor(
        private sessione: SessioneService,
        private dao: DAService,
        private storage: AngularFireStorage,
        private confirmationService: ConfirmationService,
        private clienteService: ClienteService,
        private messageService: MessageService,
    ) {
        // const ref = this.storage.ref('test/1542406388322_3512009862.pdf');
        // this.profileUrl = ref.getDownloadURL();
    }

    myUploader(event, cartella) {

        if (!this.cliente) {
            this.messageService.add({ severity: 'error', summary: 'File Uploaded', detail: 'devi prima salvare la tua Richiesta' });
            return;
        }

        for (const file of event.files) {

            console.log('upload file ' + file.name);

            this.uploadedFiles.push(file);

            // The storage path
            // const path = `identita/${this.richiesta.protocollo}`;
            const path = cartella + `/${this.cliente.id}`;

            const storageRef = this.storage.ref(path);

            /*if (cartella === 'identita') {
                this.richiestaService.setDocumentoRiconoscimentoOk(this.richiesta);
            } else {
                this.richiestaService.setEvidenzeOk(this.richiesta);
            }*/

            const storageRef2 = storageRef.getDownloadURL().subscribe(_item => {

                console.log('upload file, attivita\' su getDownloadURL -> ');
                console.log('upload file, attivita\' su getDownloadURL -> ' + JSON.stringify(_item));
                console.log('File available at', _item);
            });

            const uploadTask = storageRef.put(file);

        }

        this.messageService.add({ severity: 'success', summary: 'File Uploaded', detail: 'il documento e\' stato caricato con successo' });

    }

    ngOnInit() {}

    /**
     * invocato ogni volta che l'input del componente cambia
     */
    ngOnChanges() {

        this.account = this.sessione.operatoreLoggato;

        this.myForm = new FormGroup({

            dataCreazione: new FormControl(this.cliente.dataCreazione ? this.cliente.dataCreazione.toDate() : new Date()),
            uid: new FormControl(this.cliente.uid),
            nome: new FormControl(this.cliente.nome, Validators.required),
            cognome: new FormControl(this.cliente.cognome, Validators.required),
            email: new FormControl(this.cliente.email,[Validators.email, Validators.required]),
            citta: new FormControl(this.cliente.citta),
            telefono: new FormControl(this.cliente.telefono),


        });

         // carico i preventivi del cliente

         // this.subscription1 = this.dao.getPreventivi(null, this.cliente.id).subscribe(items => {
         this.subscription1 = this.dao.getPreventiviCliente(this.cliente.id).subscribe(items => {
            this.preventivi = items;
        });

        this.subscription2 = this.dao.getPagamenti(this.cliente.id).subscribe(items => {
            this.pagamenti = items;
        });

         /*if (this.richiesta.protocollo) {
            // carico il riferimento al documenti di identita'
            console.log('carico il riferimento al documenti di identita\': ' + this.richiesta.protocollo);
            const ref = this.storage.ref('identita/' + this.richiesta.protocollo);
            this.profileUrl = ref.getDownloadURL();

            // carico il riferimento al documento con le evidenze
            console.log('carico il riferimento al documenti di identita\': ' + this.richiesta.protocollo);
            const ref1 = this.storage.ref('evidenze/' + this.richiesta.protocollo);
            this.evidenzeUrl = ref1.getDownloadURL();
        }*/

    }

    deselezionaPrevenitvo() {
        this.preventivo = null;
    }

    flipShowCheckList() {
        this.showCheckList = !this.showCheckList;
    }

    nuovoPreventivo() {
        this.preventivo = new Preventivo(this.account.uid, this.cliente);
    }

    /*stampaRichiesta() {
        this.pdfService.stampaRichiesta(this.richiesta);
    }

    stampaCertificato() {
        this.pdfService.stampaCertificato(this.richiesta);
    }*/

    onSubmit() {

        // TODO: Use EventEmitter with form value

        console.log('ACCETTO: ' + this.accetto);

        if (this.myForm.status !== 'VALID') {
            console.error('form is not valid, cannot save to database');
            return;
        }

        /*if (!this.myForm.value.partitaIva && !this.myForm.value.codiceFiscale) {
            this.messageService.add({ severity: 'error', summary: 'Dati Fiscali', detail: 'Devi inseire il Codice Fiscale o la Partita IVA' });
            return;
        }*/

        console.log(this.myForm.value);

        if (this.cliente.id) {
            this.dao.modifica(this.cliente.id, Costanti.TABELLA_CLIENTI, this.myForm.value, true);
        } else {
            this.dao.creaSenzaChiave(this.myForm.value, Costanti.TABELLA_CLIENTI);
            this.annulla();
        }

    }

    annulla() {
        this.chiudi.emit();
    }

    elimina() {

        this.confirmationService.confirm({
            message: 'Sei sicuro di voler cancellare la tua richiesta ?',
            accept: () => {
                this.clienteService.elimina(this.cliente);
                this.chiudi.emit();
            }
        });

    }

    selezionaPreventivo(item) {
        this.preventivo = item;
    }

    salva() {
        // this.myForm.chiudi.emit();
        this.onSubmit();
        // this.chiudi.emit();
    }

    ngOnDestroy() {
        if(this.subscription1) {this.subscription1.unsubscribe(); }
        if(this.subscription2) {this.subscription2.unsubscribe(); }
    }
}
