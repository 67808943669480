<div class="ui-g dashboard" *ngIf="ticket">

    <div class="ui-g-12">

        <p-toolbar>
            <div class="ui-toolbar-group-left">
                <!--<span style="padding: 20px;">
                    <button pButton label="Salva" (click)="onSubmit()" icon="fa fa-save" type="button"></button>
                    <button *ngIf="ticket.id && ticket.stato === 'APERTO'" label="Prendi in carico" (click)="inLavorazione()" icon="fa fa-legal" class="green-btn" pButton type="button"></button>
                    <button *ngIf="ticket.id && ticket.stato === 'APERTO'" label="Annulla il Ticket" (click)="annullaTicket()" icon="fa fa-trash" class="red-btn" pButton type="button"></button>
                    <button *ngIf="ticket.id && ticket.stato === 'IN_LAVORAZIONE'" label="Chiudi il Ticket" (click)="chiudiTicket()" icon="fa fa-check" class="green-btn" pButton type="button"></button>
                    <button *ngIf="utente.ruolo === 'AMMINISTRATORE'" label="Elimina" (click)="elimina()" icon="fa fa-trash" pButton type="button" class="red-btn"></button>
                    <button label="Indietro" (click)="annulla()" icon="fa fa-reply" pButton type="button" class="orange-btn"></button>
                </span>-->

                <span>
                    <button pButton label="Salva" (click)="onSubmit()" icon="fa fa-save" type="button" class="p-button-rounded p-button-outlined p-mr-2 p-mb-2" [disabled]="!myForm.valid"></button>
                    <button *ngIf="ticket.id && ticket.stato === 'APERTO'" label="Prendi in carico" (click)="inLavorazione()" icon="fa fa-legal" class="green-btn" pButton type="button" class="p-button-rounded p-button-outlined p-button-success p-mr-2 p-mb-2"></button>
                    <button *ngIf="ticket.id && ticket.stato === 'APERTO'" label="Prendi in carico" (click)="annullaTicket()" icon="fa fa-trash" class="red-btn" pButton type="button" class="p-button-rounded p-button-outlined p-button-success p-mr-2 p-mb-2"></button>
                    <button *ngIf="ticket.id && ticket.stato === 'IN_LAVORAZIONE'" label="Chiudi il Ticket" (click)="chiudiTicket()" icon="fa fa-check" class="green-btn" pButton type="button" class="p-button-rounded p-button-outlined p-button-success p-mr-2 p-mb-2"></button>
                    <button *ngIf="utente.ruolo === 'AMMINISTRATORE'" label="Elimina" (click)="elimina()" icon="fa fa-trash" class="red-btn" pButton type="button" class="p-button-rounded p-button-outlined p-button-success p-mr-2 p-mb-2"></button>
                    <button label="Indietro" (click)="annulla()" icon="fa fa-reply" pButton type="button" class="p-button-rounded p-button-outlined p-button-secondary  p-mr-2 p-mb-2" ></button>
                </span>

            </div>
            <!--<div class="ui-toolbar-group-right"><p-splitButton class="green-btn" label="Connetti" icon="fa fa-phone" [model]="items"></p-splitButton></div>-->
        </p-toolbar>
    </div>

    <br/>
    
    <div class="ui-g-12">
        
        <div class="ui-g-12 ui-md-6">
            
            <p-panel header="Ticket">
                
                <div class="ui-g form-group">
                    
                    <div class="ui-g-12 ui-md-6">
                        <div class="ui-g-12"><label for="nome">Oggetto</label></div>
                        <div class="ui-g-12"><input id="nome" style="width: 100%" type="text" [(ngModel)]="oggetto" pInputText></div>
                    </div>
                    <br/>

                        <div class="ui-g-12"><label for="Descrizione">Descrizione</label></div>
                        <div class="ui-g-12"><input id="Descrizione" style="width: 100%" type="text" [(ngModel)]="descrizione" pInputText></div>

                </div>

            </p-panel>

            <br/>

        </div>

        <div class="ui-g-12 ui-md-6">

                <p-panel header="Camera" *ngIf="ticket.cameraId">

                    <div class="ui-g form-group">

                        <form [formGroup]="myForm" (ngSubmit)="onSubmit()">

                            <div class="ui-g-12">
                                <div class="ui-g-12"><label for="cameraid">Camera ID</label></div>
                                <div class="ui-g-12"><input readonly="true" id="cameraid" style="width: 100%" type="text" formControlName="cameraId" pInputText></div>
                            </div>

                            <div class="ui-g-12">
                                    <div class="ui-g-12"><label for="cameranome">Camera</label></div>
                                <div class="ui-g-12"><input readonly="true" id="cameranome" style="width: 100%" type="text" formControlName="cameraNome" pInputText></div>
                            </div>

                            <div class="ui-g-12">
                                    <div class="ui-g-12"><label for="cameraS">Sistemazione</label></div>
                                <div class="ui-g-12"><input readonly="true" id="cameras" style="width: 100%" type="text" formControlName="cameraSistemazione" pInputText></div>
                            </div>

                        </form>
                    </div>

                </p-panel>

                <br/>

            </div>

        <!--<div class="ui-g-12 ui-md-6">

            <p-panel header="Info" [style]="{minHeight: '100px'}">

                <div *ngIf="!tgate && !varco">
                    <p>This is a <i>general</i> Support Ticket, no connected to any T-Gate.</p>
                </div>

                <div *ngIf="tgate">
                    <p>This is a Support Ticket releated to T-Gate:</p>
                    <p><b>Serial:</b> {{tgate.codice}}</p>
                    <p><b>Name:</b> {{tgate.alias}}</p>
                </div>

                <div *ngIf="varco">
                    <p>This is a Support Ticket releated to the Gate:</p>
                    <p><b>Name:</b> {{varco.nome}}</p>
                    <p><b>Calendar:</b> {{ varco | periodoVarco}}</p>
                </div>

            </p-panel>

            <br/>

        </div>-->
    </div>
