import { OnDestroy } from '@angular/core';
import {Component, OnInit} from '@angular/core';
import { ISubscription } from 'rxjs/Subscription';
import {AppMainComponent} from './app.main.component';
import { Account } from './domain/account';
import { SessioneService } from './services/sessione.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit, OnDestroy {

    model: any[];
    account: Account;
    subscription: ISubscription;

    constructor(public app: AppMainComponent, private sessione: SessioneService) {}
    
    ngOnDestroy(): void {
        if (this.subscription) {this.subscription.unsubscribe(); }
    }

    ngOnInit() {

        this.subscription = this.sessione.operatore.subscribe(item => {
            // console.log('AppMenuComponent -> ngOnInit -> attivita\' su Ruolo ' + JSON.stringify(item));
            this.account = item;
            this.inizializza();
        });

    }

    inizializza() {
        switch (this.account.ruolo) {
          case 'AMMINISTRATORE':
          this.inizializzaAmministratore(); break;
          case 'GOVERNANTE':
          this.inizializzaGovernante(); break;
          case 'AUTISTA':
          this.inizializzaAutista(); break;
          default:
          this.inizializzaOperatore(); break;
        }
    }

    inizializzaAmministratore() {
        this.model = [
            { label: 'Principale', icon: 'fa fa-fw fa-wrench',
                items: [
                    { label: 'Scrivania', icon: 'fa fa-fw fa-home', routerLink: ['/dashboard'] },
                    { label: 'Preventivi', icon: 'fa fa-fw fa-eur', routerLink: ['/preventivi'] },
                    { label: 'Richieste Preventivo', icon: 'fa fa-fw fa-commenting', routerLink: ['/richieste-preventivo'] },
                    { label: 'Pagamenti', icon: 'fa fa-fw fa-credit-card', routerLink: ['/pagamenti'] },
                    { label: 'Clienti', icon: 'fa fa-fw fa-id-card', routerLink: ['/clienti'] },
                    // { label: 'Tickets', icon: 'fa fa-fw fa-ticket', routerLink: ['/tickets'] },
                ]
            },
            {separator: true},
            { label: 'Configurazione', icon: 'fa fa-fw fa-wrench',
            items: [
                { label: 'Tariffe Giornaliere', routerLink: ['/tariffe'], icon: 'fa fa-fw fa-eur'},
                { label: 'Parametri di Sistema', routerLink: ['/parametri'], icon: 'fa fa-fw fa-cogs'},
            ]
        },
        // {separator: true},
        // { label: 'Altro', icon: 'fa fa-fw fa-wrench',
        //     items: [
        //         { label: 'Tickets', icon: 'fa fa-fw fa-ticket', routerLink: ['/tickets'] },
        //         { label: 'Clienti', icon: 'fa fa-fw fa-id-card', routerLink: ['/clienti'] },
        //         { label: 'Camere', icon: 'fa fa-fw fa-hotel', routerLink: ['/camere'] },
        //         { label: 'Account', icon: 'fa fa-fw fa-users', routerLink: ['/accounts'] },
        //         { label: 'Operatori', icon: 'fa fa-fw fa-user-secret', routerLink: ['/operatori'] },
        //         { label: 'Messaggi', icon: 'fa fa-fw fa-envelope-o', routerLink: ['/messaggi'] },
        //         { label: 'Report', icon: 'fa fa-fw fa-bar-chart', routerLink: ['/report-preventivi'] },
        //     ]
        // },
        ];
    }

    inizializzaGovernante() {
        this.model = [
            { label: 'Menu\'', icon: 'fa fa-fw fa-wrench',
                items: [
                    { label: 'Scrivania', icon: 'fa fa-fw fa-home', routerLink: ['/dash'] },
                    { label: 'Camere', icon: 'fa fa-fw fa-hotel', routerLink: ['/camere'] },
                    { label: 'Tickets', icon: 'fa fa-fw fa-ticket', routerLink: ['/tickets'] },
                ]
            },
        ];
    }

    inizializzaAutista() {
        this.model = [
            { label: 'Menu\'', icon: 'fa fa-fw fa-wrench',
                items: [
                    { label: 'Scrivania', icon: 'fa fa-fw fa-home', routerLink: ['/dash'] },
                    { label: 'Chiamate', icon: 'fa fa-fw fa-bus', routerLink: ['/chiamate'] },
                    { label: 'Tickets', icon: 'fa fa-fw fa-ticket', routerLink: ['/tickets'] },
                ]
            },
        ];
    }

    inizializzaOperatore() {
        this.model = [
            { label: 'Menu', icon: 'fa fa-fw fa-wrench',
                items: [
                    { label: 'Scrivania', icon: 'fa fa-fw fa-home', routerLink: ['/dashboard'] },
                    { label: 'Preventivi', icon: 'fa fa-fw fa-eur', routerLink: ['/preventivi'] },
                    { label: 'Richieste Preventivo', icon: 'fa fa-fw fa-commenting', routerLink: ['/richieste-preventivo'] },
                    { label: 'Clienti', icon: 'fa fa-fw fa-id-card', routerLink: ['/clienti'] },
                    { label: 'Camere', icon: 'fa fa-fw fa-hotel', routerLink: ['/camere'] },
                    { label: 'Pagamenti (sca)', icon: 'fa fa-fw fa-credit-card-alt', routerLink: ['/pagamenti-sca'] },
                    { label: 'Pagamenti', icon: 'fa fa-fw fa-credit-card', routerLink: ['/pagamenti'] },
                    { label: 'Account', icon: 'fa fa-fw fa-users', routerLink: ['/accounts'] },
                    { label: 'Operatori', icon: 'fa fa-fw fa-user-secret', routerLink: ['/operatori'] },
                    { label: 'Messaggi', icon: 'fa fa-fw fa-envelope-o', routerLink: ['/messaggi'] },
                ]
            },
            {separator: true},
            { label: 'Configurazione', icon: 'fa fa-fw fa-wrench',
            items: [
                { label: 'Tariffe Giornaliere', routerLink: ['/tariffe'], icon: 'fa fa-fw fa-eur'},
                { label: 'Parametri di Sistema', routerLink: ['/parametri'], icon: 'fa fa-fw fa-cogs'},
            ]
        },
        ];
    }

    inizializzaOperatoreOld() {
        this.model = [

            { label: 'Scrivania', icon: 'fa fa-fw fa-home', routerLink: ['/dashboard'] },
            { label: 'Preventivi', icon: 'fa fa-fw fa-eur', routerLink: ['/preventivi'] },
            { label: 'Richieste Preventivo', icon: 'fa fa-fw fa-commenting', routerLink: ['/richieste-preventivo'] },
            { label: 'Clienti', icon: 'fa fa-fw fa-id-card', routerLink: ['/clienti'] },
            { label: 'Camere', icon: 'fa fa-fw fa-hotel', routerLink: ['/camere'] },
            { label: 'Pagamenti (sca)', icon: 'fa fa-fw fa-credit-card-alt', routerLink: ['/pagamenti-sca'] },
            { label: 'Pagamenti', icon: 'fa fa-fw fa-credit-card', routerLink: ['/pagamenti'] },
            { label: 'Account', icon: 'fa fa-fw fa-users', routerLink: ['/accounts'] },
            { label: 'Operatori', icon: 'fa fa-fw fa-user-secret', routerLink: ['/operatori'] },
            { label: 'Messaggi', icon: 'fa fa-fw fa-envelope-o', routerLink: ['/messaggi'] },
            // { label: 'Report', icon: 'fa fa-fw fa-bar-chart', routerLink: ['/report-preventivi'] },
            { label: 'Configurazione', icon: 'fa fa-fw fa-wrench',
            items: [
                { label: 'Tariffe Giornaliere', routerLink: ['/tariffe']},
                { label: 'Parametri di Sistema', routerLink: ['/parametri']},
            ]
            },
            {separator: true},
        ];
    }

    initOri() {
        this.model = [
            {
                label: 'Favorites', icon: 'pi pi-home',
                items: [
                    {label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/']}
                ]
            },
            {separator: true},
            {
                label: 'UI Kit', icon: 'pi pi-fw pi-star', routerLink: ['/uikit'],
                items: [
                    {label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout']},
                    {label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input']},
                    {label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'], class: 'rotated-icon'},
                    {label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table']},
                    {label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list']},
                    {label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree']},
                    {label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel']},
                    {label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay']},
                    {label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media']},
                    {label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu']},
                    {label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message']},
                    {label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file']},
                    {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts']},
                    {label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/uikit/misc']}
                ]
            },
            {separator: true},
            {
                label: 'Utilities', icon: 'pi pi-fw pi-compass', routerLink: ['utilities'],
                items: [
                    {label: 'Display', icon: 'pi pi-fw pi-desktop', routerLink: ['utilities/display']},
                    {label: 'Elevation', icon: 'pi pi-fw pi-external-link', routerLink: ['utilities/elevation']},
                    {label: 'FlexBox', icon: 'pi pi-fw pi-directions', routerLink: ['utilities/flexbox']},
                    {label: 'Icons', icon: 'pi pi-fw pi-search', routerLink: ['utilities/icons']},
                    {label: 'Text', icon: 'pi pi-fw pi-pencil', routerLink: ['utilities/text']},
                    {label: 'Widgets', icon: 'pi pi-fw pi-star-o', routerLink: ['utilities/widgets']},
                    {label: 'Grid System', icon: 'pi pi-fw pi-th-large', routerLink: ['utilities/grid']},
                    {label: 'Spacing', icon: 'pi pi-fw pi-arrow-right', routerLink: ['utilities/spacing']},
                    {label: 'Typography', icon: 'pi pi-fw pi-align-center', routerLink: ['utilities/typography']}
                ]
            },
            {separator: true},
            {
                label: 'Pages', icon: 'pi pi-fw pi-briefcase', routerLink: ['/pages'],
                items: [
                    {label: 'Crud', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/crud']},
                    {label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/pages/calendar']},
                    {label: 'Landing', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank'},
                    {label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login']},
                    {label: 'Invoice', icon: 'pi pi-fw pi-dollar', routerLink: ['/pages/invoice']},
                    {label: 'Help', icon: 'pi pi-fw pi-question-circle', routerLink: ['/pages/help']},
                    {label: 'Error', icon: 'pi pi-fw pi-times-circle', routerLink: ['/error']},
                    {label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/notfound']},
                    {label: 'Access Denied', icon: 'pi pi-fw pi-lock', routerLink: ['/access']},
                    {label: 'Empty', icon: 'pi pi-fw pi-circle-off', routerLink: ['/pages/empty']}
                ]
            },
            {separator: true},
            {
                label: 'Hierarchy', icon: 'pi pi-fw pi-align-left',
                items: [
                    {
                        label: 'Submenu 1', icon: 'pi pi-fw pi-align-left',
                        items: [
                            {
                                label: 'Submenu 1.1', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    {label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-align-left'},
                                    {label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-align-left'},
                                    {label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-align-left'},
                                ]
                            },
                            {
                                label: 'Submenu 1.2', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    {label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-align-left'}
                                ]
                            },
                        ]
                    },
                    {
                        label: 'Submenu 2', icon: 'pi pi-fw pi-align-left',
                        items: [
                            {
                                label: 'Submenu 2.1', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    {label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-align-left'},
                                    {label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-align-left'},
                                ]
                            },
                            {
                                label: 'Submenu 2.2', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    {label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-align-left'},
                                ]
                            },
                        ]
                    }
                ]
            },
            {separator: true},
            {
                label: 'Start', icon: 'pi pi-fw pi-download',
                items: [
                    {
                        label: 'Buy Now', icon: 'pi pi-fw pi-shopping-cart', url: ['https://www.primefaces.org/store']
                    },
                    {
                        label: 'Documentation', icon: 'pi pi-fw pi-info-circle', routerLink: ['/documentation']
                    }
                ]
            },
        ];
    }

    onMenuClick(event) {
        this.app.onMenuClick(event);
    }
}
