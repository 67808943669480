import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    OnChanges,
    Output,
    EventEmitter,
    Inject
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Operatore } from 'src/app/domain/operatore';
import { Account } from 'src/app/domain/account';
import { Costanti } from 'src/app/services/costanti';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { SessioneService } from 'src/app/services/sessione.service';
import { DAService } from 'src/app/services/da.service';
import { ClienteService } from 'src/app/services/cliente.service';

@Component({
    selector: 'app-operatore',
    templateUrl: './operatore.component.html',
    styles: [`
        .errore {
            font-family: monospace;
            color: red;
            font-weight: bold;
        }`
    ]
})
export class OperatoreComponent implements OnInit, OnChanges, OnDestroy {

    @Input() operatore?: Operatore;
    @Output() chiudi = new EventEmitter();

    myForm: FormGroup;

    account?: Account;

    auditor?: any;

    accetto = false;

    showCheckList = false;

    comboAuditor?: SelectItem[] = [];

    comboRuolo: SelectItem[] = Costanti.COMBO_RUOLI;


    uploadedFiles: any[] = [];

    constructor(
        private sessione: SessioneService,
        private dao: DAService,
        private confirmationService: ConfirmationService,
        private clienteService: ClienteService,
    ) {
        // const ref = this.storage.ref('test/1542406388322_3512009862.pdf');
        // this.profileUrl = ref.getDownloadURL();
    }

    ngOnInit() {}

    /**
     * invocato ogni volta che l'input del componente cambia
     */
    ngOnChanges() {

        this.account = this.sessione.operatoreLoggato;

        this.myForm = new FormGroup({

            dataCreazione: new FormControl(this.operatore.dataCreazione ? this.operatore.dataCreazione.toDate() : new Date()),
            nome: new FormControl(this.operatore.nome, Validators.required),
            cognome: new FormControl(this.operatore.cognome, Validators.required),
            email: new FormControl(this.operatore.email,[Validators.email, Validators.required]),
            sigla: new FormControl(this.operatore.sigla, Validators.required),
            ruolo: new FormControl(this.operatore.ruolo, Validators.required),


        });

    }

    onSubmit() {

        // TODO: Use EventEmitter with form value

        console.log('ACCETTO: ' + this.accetto);

        if (this.myForm.status !== 'VALID') {
            console.error('form is not valid, cannot save to database');
            return;
        }

        /*if (!this.myForm.value.partitaIva && !this.myForm.value.codiceFiscale) {
            this.messageService.add({ severity: 'error', summary: 'Dati Fiscali', detail: 'Devi inseire il Codice Fiscale o la Partita IVA' });
            return;
        }*/

        console.log(this.myForm.value);

        if (this.operatore.id) {
            this.dao.modifica(this.operatore.id, Costanti.TABELLA_OPERATORI, this.myForm.value, true);
        } else {
            this.dao.creaSenzaChiave(this.myForm.value, Costanti.TABELLA_OPERATORI);
            this.annulla();
        }

    }

    annulla() {
        this.chiudi.emit();
    }

    elimina() {

        this.confirmationService.confirm({
            message: 'Sei sicuro di voler cancellare la tua richiesta ?',
            accept: () => {
                this.clienteService.elimina(this.operatore);
                this.chiudi.emit();
            }
        });

    }


    salva() {
        // this.myForm.chiudi.emit();
        this.onSubmit();
        // this.chiudi.emit();
    }

    ngOnDestroy() {
        // this.sessione.deselezionaTgate();
    }
}
