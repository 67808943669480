import { firestore } from 'firebase';

export class Cliente {
    id?:                    string;
    uid?:                   string;
    email?:                 string;
    operatoreCreazione?:    string;
    dataCreazione:          firestore.Timestamp;
    nome?:                  string;
    cognome?:               string;
    citta?:                 string;
    telefono?:              string;
    preventivo?:            string; // se c'e', l'ultimo preventivo

    constructor($operatoreCreazione: string) {
        this.dataCreazione = firestore.Timestamp.fromDate(new Date());
        this.operatoreCreazione = $operatoreCreazione;
    }

}
