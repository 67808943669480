import { firestore } from 'firebase';
import { Injectable } from '@angular/core';
import { DAService } from './da.service';
import { Costanti } from './costanti';
import { SessioneService } from './sessione.service';
import { Preventivo } from '../domain/preventivo';
import * as moment from 'moment';
import { Sistemazioni } from '../domain/sistemazioni';
import { Struttura } from '../domain/struttura';
import { RichiestaPreventivo } from '../domain/richiestaPreventivo';
import { TipoResidence } from '../enumeratori/tipo-residence';
import { StatoPreventivo } from '../domain/stato-preventivo';

@Injectable()
export class PreventivoService {

  constructor(
    private sessione: SessioneService,
    private da: DAService
  ) {}


calcolaNotti(rangeDates: Date[]) {

    if (!rangeDates || rangeDates.length !== 2) {
        return -1;
    }

    const a = moment(rangeDates[1]);
    const b = moment(rangeDates[0]);
    return a.diff(b, 'days');
    // return a.diff(b, 'days') - 1;
}

getSistemazioneHuman(sistemazione: Sistemazioni): string {

    if (sistemazione === Sistemazioni.FAMILY_ROOM) {
        return 'FAMILY ROOM';
    }
    if (sistemazione === Sistemazioni.HOTEL) {
        return 'HOTEL PALACE';
    }
    if (sistemazione === Sistemazioni.VILLAGE) {
        return 'VILLAGE';
    }
    if (sistemazione === Sistemazioni.RESIDENCE) {
        return 'RESIDENCE';
    }

    return '???';
}

getStrutturaHuman(struttura: Struttura): string {

    if (struttura === Struttura.FAMILY_ROOM) {
        return 'FAMILY ROOM';
    }
    if (struttura === Struttura.HOTEL_PALACE) {
        return 'HOTEL PALACE';
    }
    if (struttura === Struttura.HOTEL_VILLAGE) {
        return 'HOTEL VILLAGE';
    }
    if (struttura === Struttura.COTTAGE) {
        return 'COTTAGE';
    }

    return '???';
}



calcolaTariffaMedia(date: Date[], sistemazione: Sistemazioni, tipoResidence: TipoResidence): number {

    // console.log('calcolaTariffaMedia, date:' + date);

    if (!date || date.length !== 2) {
        return -1;
    }

    const da_data = date[0];
    const a_data = date[1];

    // console.log('calcolaTariffaMedia, da_data:' + da_data + ' a data ' + a_data);

    // if (da_data > Costanti.PAUSA_INIZIO && da_data < Costanti.PAUSA_FINE) {
    if (da_data > Costanti.CHIUSURA_RESORT || da_data < Costanti.APERTURA_RESORT) {
        console.error('calcolaTariffaMedia, da_data fuori range');
        return -1;
    }
    // if (a_data > Costanti.PAUSA_INIZIO && a_data < Costanti.PAUSA_FINE) {
    if (a_data > Costanti.CHIUSURA_RESORT || a_data < Costanti.APERTURA_RESORT) {
        console.error('calcolaTariffaMedia, a_data fuori range');
        return -1;
    }

    const notti = this.calcolaNotti(date);
    let totale = 0;
    let tariffa = 0;

    for (let i = 0; i < notti; i++) {
        const data = moment(da_data).add(i, 'day').toDate();
        if (sistemazione === Sistemazioni.RESIDENCE) {
            console.log('calcolaTariffaMedia RESIDENCE');
            tariffa = tipoResidence === TipoResidence.MONOLOCALE ? this.calcolaTariffaResidenceMono(data) : this.calcolatariffaResidenceTrilo(data);
        } else {
            console.log('calcolaTariffaMedia HOTELs');
            tariffa = sistemazione === Sistemazioni.HOTEL ? this.calcolatariffaHp(data) : this.calcolaTariffaHVoFM(data);
            if(sistemazione === Sistemazioni.FAMILY_ROOM){
                console.log('calcolaTariffaMedia FAMILY_ROOM con maggiorazione del 20%');
                tariffa = tariffa * 1.2;
            }
        }
        console.log('calcolaTariffaMedia giorno ' + moment(data).format(Costanti.FORMATTER_DATA_SHORT) + ' = ' + tariffa);
        totale = totale + tariffa;
    }

    console.log('calcolaTariffaMedia totale = ' + totale + '/' + notti + '=' + (totale / notti));
    console.log('calcolaTariffaMedia = ' + totale / notti);


    return totale / notti;
}

// tslint:disable: align
calcolaTariffaHVoFM(data: Date): number {

    const anno = Costanti.ANNO;
    const tariffe = this.sessione.tariffe;

    // A  if (data >= new Date(anno, 4, 28 ) && data  <= new Date(anno, 5, 17)) { console.log('calcolaTariffa -> A -> ' + tariffe.tariffa1); return tariffe.tariffa1; }
    // B  if (data >= new Date(anno, 5, 18) && data  <= new Date(anno, 6, 1)) { console.log('calcolaTariffa -> B -> ' + tariffe.tariffa2); return tariffe.tariffa2; }
    // C  if (data >= new Date(anno, 6, 2) && data  <= new Date(anno, 6, 8)) { console.log('calcolaTariffa -> C -> ' + tariffe.tariffa3); return tariffe.tariffa3; }
    // D  if (data >= new Date(anno, 6, 9) && data  <= new Date(anno, 6, 15)) { console.log('calcolaTariffa -> D -> ' + tariffe.tariffa4); return tariffe.tariffa4; }
    // E  if (data >= new Date(anno, 6, 16) && data  <= new Date(anno, 7, 5)) { console.log('calcolaTariffa -> E -> ' + tariffe.tariffa5); return tariffe.tariffa5; }
    // F  if (data >= new Date(anno, 7, 6) && data  <= new Date(anno, 7, 12))  { console.log('calcolaTariffa -> F -> ' + tariffe.tariffa6); return tariffe.tariffa6; }
    // G  if (data >= new Date(anno, 7, 13) && data   <= new Date(anno, 7, 19)) { console.log('calcolaTariffa -> G -> ' + tariffe.tariffa7); return tariffe.tariffa7; }
    // H  if (data >= new Date(anno, 7, 20) && data  <= new Date(anno, 7, 26)) { console.log('calcolaTariffa -> H -> ' + tariffe.tariffa8); return tariffe.tariffa8; }
    // I  if (data >= new Date(anno, 7, 27) && data  <= new Date(anno, 8, 2)) { console.log('calcolaTariffa -> I -> ' + tariffe.tariffa9); return tariffe.tariffa9; }
    // L  if (data >= new Date(anno, 8, 3)  && data <= new Date(anno, 8, 9))  { console.log('calcolaTariffa -> L -> ' + tariffe.tariffa10); return tariffe.tariffa10; }
    // M  if (data >= new Date(anno, 8, 10)  && data  <= new Date(anno, 8, 16))  { console.log('calcolaTariffa -> M -> ' + tariffe.tariffa11); return tariffe.tariffa11; }
    // N  if (data >= new Date(anno, 8, 17)  && data  <= new Date(anno, 8, 24))  { console.log('calcolaTariffa -> M -> ' + tariffe.tariffa11); return tariffe.tariffa11; }

    /* A */ if (data >= new Date(anno, 3, 12 ) && data  <= new Date(anno, 5, 15)) { console.log('calcolaTariffa -> A -> ' + tariffe.tariffa1); return tariffe.tariffa1; }
    /* B */ if (data >= new Date(anno, 5, 16) && data  <= new Date(anno, 5, 29)) { console.log('calcolaTariffa -> B -> ' + tariffe.tariffa2); return tariffe.tariffa2; }
    /* C */ if (data >= new Date(anno, 5, 30) && data  <= new Date(anno, 6, 6)) { console.log('calcolaTariffa -> C -> ' + tariffe.tariffa3); return tariffe.tariffa3; }
    /* D */ if (data >= new Date(anno, 6, 7) && data  <= new Date(anno, 6, 13)) { console.log('calcolaTariffa -> D -> ' + tariffe.tariffa4); return tariffe.tariffa4; }
    /* E */ if (data >= new Date(anno, 6, 14) && data  <= new Date(anno, 7, 3)) { console.log('calcolaTariffa -> E -> ' + tariffe.tariffa5); return tariffe.tariffa5; }
    /* F */ if (data >= new Date(anno, 7, 4) && data  <= new Date(anno, 7, 10))  { console.log('calcolaTariffa -> F -> ' + tariffe.tariffa6); return tariffe.tariffa6; }
    /* G */ if (data >= new Date(anno, 7, 11) && data   <= new Date(anno, 7, 17)) { console.log('calcolaTariffa -> G -> ' + tariffe.tariffa7); return tariffe.tariffa7; }
    /* H */ if (data >= new Date(anno, 7, 18) && data  <= new Date(anno, 7, 24)) { console.log('calcolaTariffa -> H -> ' + tariffe.tariffa8); return tariffe.tariffa8; }
    /* I */ if (data >= new Date(anno, 7, 25) && data  <= new Date(anno, 7, 31)) { console.log('calcolaTariffa -> I -> ' + tariffe.tariffa9); return tariffe.tariffa9; }
    /* L */ if (data >= new Date(anno, 8, 1)  && data <= new Date(anno, 8, 7))  { console.log('calcolaTariffa -> L -> ' + tariffe.tariffa10); return tariffe.tariffa10; }
    /* M */ if (data >= new Date(anno, 8, 8)  && data  <= new Date(anno, 8, 14))  { console.log('calcolaTariffa -> M -> ' + tariffe.tariffa11); return tariffe.tariffa11; }
    /* N */ if (data >= new Date(anno, 8, 15)  && data  <= new Date(anno, 8, 29))  { console.log('calcolaTariffa -> M -> ' + tariffe.tariffa11); return tariffe.tariffa12; }


    return -100000;
}



calcolaTariffaResidenceMono(data: Date): number {
    const anno = Costanti.ANNO;
    const tariffe = this.sessione.tariffe;
   
    /* A  if (data >= new Date(anno, 4, 28) && data  <= new Date(anno, 5, 17))  { console.log('calcolaTariffaResidenceMono -> A -> ' + tariffe.tariffaResidenceMono1); return tariffe.tariffaResidenceMono1; }
    /* B  if (data >= new Date(anno, 5, 18) && data  <=  new Date(anno, 6, 1)) { console.log('calcolaTariffaResidenceMono -> B -> ' + tariffe.tariffaResidenceMono2); return tariffe.tariffaResidenceMono2; }
    /* C  if (data >= new Date(anno, 6, 2) && data  <= new Date(anno, 6, 8)) { console.log('calcolaTariffaResidenceMono -> C -> ' + tariffe.tariffaResidenceMono3); return tariffe.tariffaResidenceMono3; }
    /* D  if (data >= new Date(anno, 6, 9)  && data <= new Date(anno, 6, 15))  { console.log('calcolaTariffaResidenceMono -> D -> ' + tariffe.tariffaResidenceMono4); return tariffe.tariffaResidenceMono4; }
    /* E  if (data >= new Date(anno, 6, 16)  && data  <= new Date(anno, 7, 5))  { console.log('calcolaTariffaResidenceMono -> E -> ' + tariffe.tariffaResidenceMono5); return tariffe.tariffaResidenceMono5; }
    /* F  if (data >= new Date(anno, 7, 6) && data   <= new Date(anno, 7, 12)) { console.log('calcolaTariffaResidenceMono -> F -> ' + tariffe.tariffaResidenceMono6); return tariffe.tariffaResidenceMono6; }
    /* G  if (data >= new Date(anno, 7, 13) && data  <= new Date(anno, 7, 19)) { console.log('calcolaTariffaResidenceMono -> G -> ' + tariffe.tariffaResidenceMono7); return tariffe.tariffaResidenceMono7; }
    /* H  if (data >= new Date(anno, 7, 20) && data  <= new Date(anno, 7, 26)) { console.log('calcolaTariffaResidenceMono -> H -> ' + tariffe.tariffaResidenceMono8); return tariffe.tariffaResidenceMono8; }
    /* I  if (data >= new Date(anno, 7, 27) && data  <= new Date(anno, 8, 2))  { console.log('calcolaTariffaResidenceMono -> F -> ' + tariffe.tariffaResidenceMono9); return tariffe.tariffaResidenceMono9; }
    /* L  if (data >= new Date(anno, 8, 3) && data   <= new Date(anno, 8, 9)) { console.log('calcolaTariffaResidenceMono -> L -> ' + tariffe.tariffaResidenceMono10); return tariffe.tariffaResidenceMono10; }
    /* M  if (data >= new Date(anno, 8, 10) && data   <= new Date(anno, 8, 16)) { console.log('calcolaTariffaResidenceMono -> M -> ' + tariffe.tariffaResidenceMono11); return tariffe.tariffaResidenceMono11; }
    /* N  if (data >= new Date(anno, 8, 17) && data   <= new Date(anno, 8, 24)) { console.log('calcolaTariffaResidenceMono -> N -> ' + tariffe.tariffaResidenceMono12); return tariffe.tariffaResidenceMono12; }
    // /* O  if (data >= new Date(anno, 8, 17) && data   <= new Date(anno, 8, 24)) { console.log('calcolaTariffaResidenceMono -> O -> ' + tariffe.tariffaResidenceMono13); return tariffe.tariffaResidenceMono13; }
    // /* P  if (data >= new Date(anno, 8, 17) && data   <= new Date(anno, 8, 24)) { console.log('calcolaTariffaResidenceMono -> P -> ' + tariffe.tariffaResidenceMono14); return tariffe.tariffaResidenceMono14; }


    /* A */ if (data >= new Date(anno, 3, 12 ) && data  <= new Date(anno, 5, 15)) { console.log('calcolaTariffa ResidenceMono -> A -> ' + tariffe.tariffaResidenceMono1); return tariffe.tariffaResidenceMono1; }
    /* B */ if (data >= new Date(anno, 5, 16) && data  <= new Date(anno, 5, 29)) { console.log('calcolaTariffa ResidenceMono -> B -> ' + tariffe.tariffaResidenceMono2); return tariffe.tariffaResidenceMono2; }
    /* C */ if (data >= new Date(anno, 5, 30) && data  <= new Date(anno, 6, 6)) { console.log('calcolaTariffa ResidenceMono -> C -> ' + tariffe.tariffaResidenceMono3); return tariffe.tariffaResidenceMono3; }
    /* D */ if (data >= new Date(anno, 6, 7) && data  <= new Date(anno, 6, 13)) { console.log('calcolaTariffa ResidenceMono -> D -> ' + tariffe.tariffaResidenceMono4); return tariffe.tariffaResidenceMono4; }
    /* E */ if (data >= new Date(anno, 6, 14) && data  <= new Date(anno, 7, 3)) { console.log('calcolaTariffa ResidenceMono -> E -> ' + tariffe.tariffaResidenceMono5); return tariffe.tariffaResidenceMono5; }
    /* F */ if (data >= new Date(anno, 7, 4) && data  <= new Date(anno, 7, 10))  { console.log('calcolaTariffa ResidenceMono -> F -> ' + tariffe.tariffaResidenceMono6); return tariffe.tariffaResidenceMono6; }
    /* G */ if (data >= new Date(anno, 7, 11) && data   <= new Date(anno, 7, 17)) { console.log('calcolaTariffa ResidenceMono -> G -> ' + tariffe.tariffaResidenceMono7); return tariffe.tariffaResidenceMono7; }
    /* H */ if (data >= new Date(anno, 7, 18) && data  <= new Date(anno, 7, 24)) { console.log('calcolaTariffa ResidenceMono -> H -> ' + tariffe.tariffaResidenceMono8); return tariffe.tariffaResidenceMono8; }
    /* I */ if (data >= new Date(anno, 7, 25) && data  <= new Date(anno, 7, 31)) { console.log('calcolaTariffa ResidenceMono -> I -> ' + tariffe.tariffaResidenceMono9); return tariffe.tariffaResidenceMono9; }
    /* L */ if (data >= new Date(anno, 8, 1)  && data <= new Date(anno, 8, 7))  { console.log('calcolaTariffa ResidenceMono -> L -> ' + tariffe.tariffaResidenceMono10); return tariffe.tariffaResidenceMono10; }
    /* M */ if (data >= new Date(anno, 8, 8)  && data  <= new Date(anno, 8, 14))  { console.log('calcolaTariffa ResidenceMono -> M -> ' + tariffe.tariffaResidenceMono11); return tariffe.tariffaResidenceMono11; }
    /* N */ if (data >= new Date(anno, 8, 15)  && data  <= new Date(anno, 8, 29))  { console.log('calcolaTariffa ResidenceMono -> M -> ' + tariffe.tariffaResidenceMono12); return tariffe.tariffaResidenceMono12; }


    console.error('calcolaTariffaResidenceMono -> NOT FOUND -> ' + data);
    return -100000;
}

calcolatariffaResidenceTrilo(data: Date): number {
    const anno = Costanti.ANNO;
    const tariffe = this.sessione.tariffe;

    /* A  if (data >= new Date(anno, 4, 28) && data  <= new Date(anno, 5, 17))  { console.log('calcolatariffaResidenceTrilo -> A -> ' + tariffe.tariffaResidenceTrilo1); return tariffe.tariffaResidenceTrilo1; }
    /* B  if (data >= new Date(anno, 5, 18) && data  <=  new Date(anno, 6, 1)) { console.log('calcolatariffaResidenceTrilo -> B -> ' + tariffe.tariffaResidenceTrilo2); return tariffe.tariffaResidenceTrilo2; }
    /* C  if (data >= new Date(anno, 6, 2) && data  <= new Date(anno, 6, 8)) { console.log('calcolatariffaResidenceTrilo -> C -> ' + tariffe.tariffaResidenceTrilo3); return tariffe.tariffaResidenceTrilo3; }
    /* D  if (data >= new Date(anno, 6, 9)  && data <= new Date(anno, 6, 15))  { console.log('calcolatariffaResidenceTrilo -> D -> ' + tariffe.tariffaResidenceTrilo4); return tariffe.tariffaResidenceTrilo4; }
    /* E  if (data >= new Date(anno, 6, 16)  && data  <= new Date(anno, 7, 5))  { console.log('calcolatariffaResidenceTrilo -> E -> ' + tariffe.tariffaResidenceTrilo5); return tariffe.tariffaResidenceTrilo5; }
    /* F  if (data >= new Date(anno, 7, 6) && data   <= new Date(anno, 7, 12)) { console.log('calcolatariffaResidenceTrilo -> F -> ' + tariffe.tariffaResidenceTrilo6); return tariffe.tariffaResidenceTrilo6; }
    /* G  if (data >= new Date(anno, 7, 13) && data  <= new Date(anno, 7, 19)) { console.log('calcolatariffaResidenceTrilo -> G -> ' + tariffe.tariffaResidenceTrilo7); return tariffe.tariffaResidenceTrilo7; }
    /* H  if (data >= new Date(anno, 7, 20) && data  <= new Date(anno, 7, 26)) { console.log('calcolatariffaResidenceTrilo -> H -> ' + tariffe.tariffaResidenceTrilo8); return tariffe.tariffaResidenceTrilo8; }
    /* I  if (data >= new Date(anno, 7, 27) && data  <= new Date(anno, 8, 2))  { console.log('calcolatariffaResidenceTrilo -> F -> ' + tariffe.tariffaResidenceTrilo9); return tariffe.tariffaResidenceTrilo9; }
    /* L  if (data >= new Date(anno, 8, 3) && data   <= new Date(anno, 8, 9)) { console.log('calcolatariffaResidenceTrilo -> L -> ' + tariffe.tariffaResidenceTrilo10); return tariffe.tariffaResidenceTrilo10; }
    /* M  if (data >= new Date(anno, 8, 10) && data   <= new Date(anno, 8, 16)) { console.log('calcolatariffaResidenceTrilo -> M -> ' + tariffe.tariffaResidenceTrilo11); return tariffe.tariffaResidenceTrilo11; }
    /* N  if (data >= new Date(anno, 8, 17) && data   <= new Date(anno, 8, 24)) { console.log('calcolatariffaResidenceTrilo -> N -> ' + tariffe.tariffaResidenceTrilo12); return tariffe.tariffaResidenceTrilo12; }
    // /* O if (data >= new Date(anno, 8, 17) && data   <= new Date(anno, 8, 17)) { console.log('calcolatariffaResidenceTrilo -> O -> ' + tariffe.tariffaResidenceTrilo13); return tariffe.tariffaResidenceTrilo13; }
    // /* P if (data >= new Date(anno, 8, 17) && data   <= new Date(anno, 8, 24)) { console.log('calcolatariffaResidenceTrilo -> P -> ' + tariffe.tariffaResidenceTrilo14); return tariffe.tariffaResidenceTrilo14; }


    /* A */ if (data >= new Date(anno, 3, 12 ) && data  <= new Date(anno, 5, 15)) { console.log('calcolaTariffa ResidenceTRILO -> A -> ' + tariffe.tariffaResidenceTrilo1); return tariffe.tariffaResidenceTrilo1; }
    /* B */ if (data >= new Date(anno, 5, 16) && data  <= new Date(anno, 5, 29)) { console.log('calcolaTariffa ResidenceTRILO -> B -> ' + tariffe.tariffaResidenceTrilo2); return tariffe.tariffaResidenceTrilo2; }
    /* C */ if (data >= new Date(anno, 5, 30) && data  <= new Date(anno, 6, 6)) { console.log('calcolaTariffa ResidenceTRILO -> C -> ' + tariffe.tariffaResidenceTrilo3); return tariffe.tariffaResidenceTrilo3; }
    /* D */ if (data >= new Date(anno, 6, 7) && data  <= new Date(anno, 6, 13)) { console.log('calcolaTariffa ResidenceTRILO -> D -> ' + tariffe.tariffaResidenceTrilo4); return tariffe.tariffaResidenceTrilo4; }
    /* E */ if (data >= new Date(anno, 6, 14) && data  <= new Date(anno, 7, 3)) { console.log('calcolaTariffa ResidenceTRILO -> E -> ' + tariffe.tariffaResidenceTrilo5); return tariffe.tariffaResidenceTrilo5; }
    /* F */ if (data >= new Date(anno, 7, 4) && data  <= new Date(anno, 7, 10))  { console.log('calcolaTariffa ResidenceTRILO -> F -> ' + tariffe.tariffaResidenceTrilo6); return tariffe.tariffaResidenceTrilo6; }
    /* G */ if (data >= new Date(anno, 7, 11) && data   <= new Date(anno, 7, 17)) { console.log('calcolaTariffa ResidenceTRILO -> G -> ' + tariffe.tariffaResidenceTrilo7); return tariffe.tariffaResidenceTrilo7; }
    /* H */ if (data >= new Date(anno, 7, 18) && data  <= new Date(anno, 7, 24)) { console.log('calcolaTariffa ResidenceTRILO -> H -> ' + tariffe.tariffaResidenceTrilo8); return tariffe.tariffaResidenceTrilo8; }
    /* I */ if (data >= new Date(anno, 7, 25) && data  <= new Date(anno, 7, 31)) { console.log('calcolaTariffa ResidenceTRILO -> I -> ' + tariffe.tariffaResidenceTrilo9); return tariffe.tariffaResidenceTrilo9; }
    /* L */ if (data >= new Date(anno, 8, 1)  && data <= new Date(anno, 8, 7))  { console.log('calcolaTariffa ResidenceTRILO -> L -> ' + tariffe.tariffaResidenceTrilo10); return tariffe.tariffaResidenceTrilo10; }
    /* M */ if (data >= new Date(anno, 8, 8)  && data  <= new Date(anno, 8, 14))  { console.log('calcolaTariffa ResidenceTRILO -> M -> ' + tariffe.tariffaResidenceTrilo11); return tariffe.tariffaResidenceTrilo11; }
    /* N */ if (data >= new Date(anno, 8, 15)  && data  <= new Date(anno, 8, 29))  { console.log('calcolaTariffa ResidenceTRILO -> M -> ' + tariffe.tariffaResidenceTrilo12); return tariffe.tariffaResidenceTrilo12; }

    console.error('calcolatariffaResidenceTrilo -> NOT FOUND -> ' + data);
    return -100000;
}

calcolatariffaHp(data: Date): number {

    // console.log('calcolaTariffa, data:' + data);

    const anno = Costanti.ANNO;
    const tariffe = this.sessione.tariffe;

    /* A if (data >= new Date(anno, 4, 28 ) && data  <= new Date(anno, 5, 17)) { console.log('calcolaTariffa -> A -> ' + tariffe.tariffaHp1); return tariffe.tariffaHp1; }
    /* B if (data >= new Date(anno, 5, 18) && data  <= new Date(anno, 6, 1)) { console.log('calcolaTariffa -> B -> ' + tariffe.tariffaHp2); return tariffe.tariffaHp2; }
    /* C if (data >= new Date(anno, 6, 2) && data  <= new Date(anno, 6, 8)) { console.log('calcolaTariffa -> C -> ' + tariffe.tariffaHp3); return tariffe.tariffaHp3; }
    /* D if (data >= new Date(anno, 6, 9) && data  <= new Date(anno, 6, 15)) { console.log('calcolaTariffa -> D -> ' + tariffe.tariffaHp4); return tariffe.tariffaHp4; }
    /* E if (data >= new Date(anno, 6, 16) && data  <= new Date(anno, 7, 5)) { console.log('calcolaTariffa -> E -> ' + tariffe.tariffaHp5); return tariffe.tariffaHp5; }
    /* F if (data >= new Date(anno, 7, 6) && data  <= new Date(anno, 7, 12))  { console.log('calcolaTariffa -> F -> ' + tariffe.tariffaHp6); return tariffe.tariffaHp6; }
    /* G if (data >= new Date(anno, 7, 13) && data   <= new Date(anno, 7, 19)) { console.log('calcolaTariffa -> G -> ' + tariffe.tariffaHp7); return tariffe.tariffaHp7; }
    /* H if (data >= new Date(anno, 7, 20) && data  <= new Date(anno, 7, 26)) { console.log('calcolaTariffa -> H -> ' + tariffe.tariffaHp8); return tariffe.tariffaHp8; }
    /* I if (data >= new Date(anno, 7, 27) && data  <= new Date(anno, 8, 2)) { console.log('calcolaTariffa -> I -> ' + tariffe.tariffaHp9); return tariffe.tariffaHp9; }
    /* L if (data >= new Date(anno, 8, 3)  && data <= new Date(anno, 8, 9))  { console.log('calcolaTariffa -> L -> ' + tariffe.tariffaHp10); return tariffe.tariffaHp10; }
    /* M if (data >= new Date(anno, 8, 10)  && data  <= new Date(anno, 8, 16))  { console.log('calcolaTariffa -> M -> ' + tariffe.tariffaHp11); return tariffe.tariffaHp11; }
    /* N if (data >= new Date(anno, 8, 17) && data   <= new Date(anno, 8, 24)) { console.log('calcola tariffaHp -> N -> ' + tariffe.tariffaHp12); return tariffe.tariffaHp12; }

    /* A */ if (data >= new Date(anno, 3, 12 ) && data  <= new Date(anno, 5, 15)) { console.log('calcolaTariffa ResidenceTRILO -> A -> ' + tariffe.tariffaHp1); return tariffe.tariffaHp1; }
    /* B */ if (data >= new Date(anno, 5, 16) && data  <= new Date(anno, 5, 29)) { console.log('calcolaTariffa ResidenceTRILO -> B -> ' + tariffe.tariffaHp2); return tariffe.tariffaHp2; }
    /* C */ if (data >= new Date(anno, 5, 30) && data  <= new Date(anno, 6, 6)) { console.log('calcolaTariffa ResidenceTRILO -> C -> ' + tariffe.tariffaHp3); return tariffe.tariffaHp3; }
    /* D */ if (data >= new Date(anno, 6, 7) && data  <= new Date(anno, 6, 13)) { console.log('calcolaTariffa ResidenceTRILO -> D -> ' + tariffe.tariffaHp4); return tariffe.tariffaHp4; }
    /* E */ if (data >= new Date(anno, 6, 14) && data  <= new Date(anno, 7, 3)) { console.log('calcolaTariffa ResidenceTRILO -> E -> ' + tariffe.tariffaHp5); return tariffe.tariffaHp5; }
    /* F */ if (data >= new Date(anno, 7, 4) && data  <= new Date(anno, 7, 10))  { console.log('calcolaTariffa ResidenceTRILO -> F -> ' + tariffe.tariffaHp6); return tariffe.tariffaHp6; }
    /* G */ if (data >= new Date(anno, 7, 11) && data   <= new Date(anno, 7, 17)) { console.log('calcolaTariffa ResidenceTRILO -> G -> ' + tariffe.tariffaHp7); return tariffe.tariffaHp7; }
    /* H */ if (data >= new Date(anno, 7, 18) && data  <= new Date(anno, 7, 24)) { console.log('calcolaTariffa ResidenceTRILO -> H -> ' + tariffe.tariffaHp8); return tariffe.tariffaHp8; }
    /* I */ if (data >= new Date(anno, 7, 25) && data  <= new Date(anno, 7, 31)) { console.log('calcolaTariffa ResidenceTRILO -> I -> ' + tariffe.tariffaHp9); return tariffe.tariffaHp9; }
    /* L */ if (data >= new Date(anno, 8, 1)  && data <= new Date(anno, 8, 7))  { console.log('calcolaTariffa ResidenceTRILO -> L -> ' + tariffe.tariffaHp10); return tariffe.tariffaHp10; }
    /* M */ if (data >= new Date(anno, 8, 8)  && data  <= new Date(anno, 8, 14))  { console.log('calcolaTariffa ResidenceTRILO -> M -> ' + tariffe.tariffaHp11); return tariffe.tariffaHp11; }
    /* N */ if (data >= new Date(anno, 8, 15)  && data  <= new Date(anno, 8, 29))  { console.log('calcolaTariffa ResidenceTRILO -> M -> ' + tariffe.tariffaHp12); return tariffe.tariffaHp12; }


    return -100000;
}


calcolaDUS(data: Date): number {
    const anno = Costanti.ANNO;
    
    if (data >= new Date(anno, 6, 14) && data  <= new Date(anno, 7, 31)) { console.log('calcolaDUS -> B -> 50'); return 50; }
    // A */ if (data >= new Date(anno, 3, 12 ) && data  <= new Date(anno, 6, 13)) { console.log('calcolaDUS -> A -> ' +  Costanti.SUPPLEMENTO_DUS); return  Costanti.SUPPLEMENTO_DUS; }
    // B */ if (data >= new Date(anno, 6, 14) && data  <= new Date(anno, 7, 31)) { console.log('calcolaDUS -> B -> 50'); return 50; }
    // C */ if (data >= new Date(anno, 5, 30) && data  <= new Date(anno, 6, 6)) { console.log('calcolaDUS  -> C -> ' + Costanti.SUPPLEMENTO_DUS); return  Costanti.SUPPLEMENTO_DUS; }
    // console.error('calcolaDUS -> NOT FOUND -> ' + data);
    // return -100000;
    return Costanti.SUPPLEMENTO_DUS;


}

erroreNelPagamento(preventivo: Preventivo, errore: string) {
    preventivo.dataModifica =  firestore.Timestamp.fromDate(new Date());
    preventivo.erroreStripe = errore;
    // preventivo.stato = 'FAILED';
    return this.da.modifica(preventivo.id, Costanti.TABELLA_PREVENTIVI, preventivo);
  }
  
  pagamentoConSuccesso(preventivo: Preventivo) {
    preventivo.dataModifica =  firestore.Timestamp.fromDate(new Date());
    // preventivo.stato = 'CHARGED';
    preventivo.stato = StatoPreventivo.ACCETTATO;
    return this.da.modifica(preventivo.id, Costanti.TABELLA_PREVENTIVI, preventivo);
  }

  elimina(richiesta: Preventivo) {
    console.log('elimina ' + richiesta.id);
    this.da.elimina(richiesta.id, Costanti.TABELLA_PREVENTIVI);
}

accetta(preventivo: Preventivo) {
    console.log('accetta ' + preventivo.id);
    preventivo.dataModifica =  firestore.Timestamp.fromDate(new Date());
    preventivo.stato = StatoPreventivo.ACCETTATO;
    return this.da.modifica(preventivo.id, Costanti.TABELLA_PREVENTIVI, preventivo);
}

    eliminaRichiesta(richiesta: RichiestaPreventivo) {
        console.log('elimina ' + richiesta.id);
        this.da.elimina(richiesta.id, Costanti.TABELLA_RICHIESTE_PREVENTIVO);
    }

}
