
<div class="ulayout-dashboard">

        <div class="card" *ngIf="!operatore">

                <p-toolbar>
                    <div class="ui-toolbar-group-left">
                        <span>
                            <button pButton type="button" label="NUOVO OPERATORE" title="CREA UN NUOVO OPERATORE"  (click)="nuovoOperatore()" icon="fa fa-plus" style="margin-top:0"></button>
                        </span>
                    </div>
                    <!--<div class="ui-toolbar-group-right"><p-splitButton class="green-btn" label="Connetti" icon="fa fa-phone" [model]="items"></p-splitButton></div>-->
                </p-toolbar>
            </div>

    <app-operatore  *ngIf="operatore" (chiudi)='deseleziona()' [operatore]="operatore"></app-operatore>

    <div class="card" *ngIf="operatori && operatori?.length > 0 && !operatore">

            <p-table #dt [columns]="cols" [responsive]="true" [value]="operatori" dataKey="id" exportFilename="operatori" sortField="DataChangePrimaryID" sortOrder="-1" [customSort]="true" [columns]="cols">

                <ng-template pTemplate="caption">
                    <div style="text-align: right">
                        <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                        <input type="text" pInputText size="25" placeholder="Filtra" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto"/>
                    </div>
                </ng-template>

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="text-align: center">Cognome</th>
                        <th style="text-align: center">Nome</th>
                        <th style="text-align: center">E-mail</th>
                        <th style="text-align: center">Ruolo</th>
                        <th style="width: 9em;text-align: center"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-cols="columns">
                    <tr>
                        <td style="text-align: center">{{rowData.cognome}}</td>
                        <td style="text-align: center">{{rowData.nome}}</td>
                        <td style="text-align: center"><a href="mailto:{{rowData.email}}">{{rowData.email}}</a></td>
                        <td style="text-align: center"><span *ngIf="rowData.ruolo" [class]="'product-badge status-' + rowData.ruolo.toLowerCase()">{{rowData.ruolo}}</span></td>

                        <td style="text-align: center">
                            <a style="cursor: pointer;" (click)="seleziona(rowData)">[dettaglio]</a>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

    </div>

    <div class="card" *ngIf="operatori?.length === 0 && !operatore">

        <p-panel header="Elenco Operatori">

            <h1>Non ho trovato alcun Operatore</h1>
        </p-panel>
    </div>

    </div>

