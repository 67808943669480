import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService {


    getStatoValue(bit: number, stati: string): boolean {

            if (stati.length >= +bit) {
                return stati.charAt(+bit) === '1';
            } else {
                console.error('qualcosa e\' andato storto: stati = ' + stati);
                return false;
            }
    }

    formattaFiltroDiRicerca(strSearch: string) {

        if (!strSearch || strSearch.length < 2) {
            console.log(
                'formattaFiltroDiRicerca con filtro non valido -> ' + strSearch
            );
            return;
        }

        const strlength = strSearch.length;
        const strFrontCode = strSearch.slice(0, strlength - 1);
        const strEndCode = strSearch.slice(strlength - 1, strSearch.length);

        const startcode = strSearch;
        const endcode =
            strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

        return [startcode, endcode];
    }

    getPhoenixMajorVersion(codiceHw: string): number {

        if (!codiceHw) {
            console.error('codiceHw null');
            return 0;
        }

        console.log('codiceHw ok: ' + codiceHw);

        if (codiceHw.length !== 8) {
            console.error(
                'errore nella lunghezza codiceHw: ' + codiceHw.length
            );
            return 0;
        }

        console.log('ok, codiceHw.lenght() = ' + codiceHw.length);

        if (!codiceHw.startsWith('PHX')) {
            console.error('errore nella, non e\' una PHX');
            return 0;
        }

        console.log('ok, major version: ' + codiceHw.substring(3, 4));

        return +codiceHw.substring(3, 4);
    }
}
