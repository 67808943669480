import {Injectable} from '@angular/core';
/**
 * more info sulla libreria:
 *
 * https://docs.microsoft.com/it-it/scripting/javascript/reference/intl-datetimeformat-object-javascript
 *
 */
@Injectable()
export class DataService {

    constructor() {}

    getLocale(): any {

     return {
        firstDayOfWeek: 1,
        dayNames: ['Domenica', 'Lunedi\'', 'Martedi\'', 'Mercoledi\'', 'Giovedi\'', 'Venerdi\'', 'Sabato'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
        dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
        monthNames: [ 'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre' ],
        monthNamesShort: [ 'Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic' ],
        today: 'Oggi',
        currentText: 'Oggi',
        dateFormat: 'dd/mm/yy',
        showMonthAfterYear: false,
        clear: 'Cancella'
    };

    /*return {
      closeText: 'Fermer',
      prevText: 'Précédent',
      nextText: 'Suivant',
      currentText: 'Aujourd\'hui',
      monthNames: [ 'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
        'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre' ],
      monthNamesShort: [ 'janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin',
        'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.' ],
      dayNames: [ 'dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi' ],
      dayNamesShort: [ 'dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.' ],
      dayNamesMin: [ 'D', 'L', 'M', 'M', 'J', 'V', 'S' ],
      weekHeader: 'Sem.',
      dateFormat: 'dd/mm/yy',
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: ''
    };*/

  }

  addDays(date, days): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  addYears(date, years): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + (years * 365));
    return result;
  }

  remDays(date, days): Date {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  getOra(value: Date): string {

    // console.log('getOra di ' + value);

    if (!value) {
      return '???';
  }

    const options = {hour: '2-digit', minute: '2-digit'};
    // optionsLong = {weekday: '2-digit', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'};
    // optionsShort = {hour: '2-digit', minute: '2-digit'};
    // optionsLong = {weekday: '2-digit', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'};
    // optionsShort = {hour: '2-digit', minute: '2-digit', second: '2-digit'};

      return value.toLocaleString('it-it', options);
    }

    getAnnoInCorso(): number {

        return new Date().getFullYear();

    }

    getNomeMese(value: number): string {

        switch (value) {

            case 0: return 'Gennaio';
            case 1: return 'Febbraio';
            case 2: return 'Marzo';
            case 3: return 'Aprile';
            case 4: return 'Maggio';
            case 5: return 'Giugno';
            case 6: return 'Luglio';
            case 7: return 'Agosto';
            case 8: return 'Settembre';
            case 9: return 'Ottobre';
            case 10: return 'Novembre';
            case 11: return 'Dicembre';
            default: return '???';
        }
    }

getSoloDataHuman(value: Date): string {

    // console.log('getDataHuman di ' + value + ' -> ' + args);

    if (!value) {
        return '';
    }

    // const options = {hour: '2-digit', minute: '2-digit'};
    const options = {weekday: 'long', year: '2-digit', month: '2-digit', day: '2-digit'};
    // optionsShort = {hour: '2-digit', minute: '2-digit'};
    // optionsLong = {weekday: '2-digit', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'};
    // optionsShort = {hour: '2-digit', minute: '2-digit', second: '2-digit'};

    return value.toLocaleString('it-it', options);
}

getOraHuman(value: Date): string {

    // console.log('getDataHuman di ' + value + ' -> ' + args);

    if (!value) {
        return '';
    }

    const options = {hour: '2-digit', minute: '2-digit'};
    // const options = {weekday: 'long', year: '2-digit', month: '2-digit', day: '2-digit'};
    // optionsShort = {hour: '2-digit', minute: '2-digit'};
    // optionsLong = {weekday: '2-digit', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'};
    // optionsShort = {hour: '2-digit', minute: '2-digit', second: '2-digit'};

    return value.toLocaleString('it-it', options);
}

getUltimoGiornoMese(mese: number): number {

    // console.log('getUltimoGiornoMese di ' + mese);

    switch (mese) {

        case 1: return 31;
        case 2: return 28;
        case 3: return 31;
        case 4: return 30;
        case 5: return 31;
        case 6: return 30;
        case 7: return 31;
        case 8: return 31;
        case 9: return 30;
        case 10: return 31;
        case 11: return 30;
        case 12: return 31;
        default: return 0;
    }

}


}
