<div class="layout-dashboard" *ngIf="messaggio">

    <div class="card" *ngIf="showToolbar">

        <p-toolbar>
            <div class="ui-toolbar-group-left">
                <span>
                    <button pButton label="INVIA" (click)="onSubmit()" icon="fa fa-paper-plane-o" type="button" [disabled]="!myForm.valid" class="p-button-outlined p-mr-2 p-mb-2"></button>
                    <button *ngIf="messaggio.id" label="ELIMINA" (click)="elimina()" icon="fa fa-trash" pButton type="button" class="p-button-danger p-button-outlined p-mr-2 p-mb-2" ></button>
                    <button label="CHIUDI" (click)="annulla()" icon="fa fa-times" pButton type="button" class="p-button-warning p-button-outlined p-mr-2 p-mb-2" ></button>
                </span>
            </div>
        </p-toolbar>

    </div>

    <div class="card">
            <div class="card-header">
            <h1>Messaggio</h1>
            <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
                
                <div class="p-grid">
                            <div class="ui-g-12"><label for="nome">Oggetto</label></div>
                            <div class="ui-g-12"><input id="nome" style="width: 100%" type="text" formControlName="oggetto" pInputText></div>

                        <div class="ui-g-12">
                            <p-editor formControlName="testo" [style]="{'height':'320px'}">
                                <p-header>
                                    <span class="ql-formats">
                                        <button class="ql-bold" aria-label="Bold"></button>
                                        <button class="ql-italic" aria-label="Italic"></button>
                                        <button class="ql-underline" aria-label="Underline"></button>
                                    </span>
                                </p-header>
                            </p-editor>
                        </div>
                    </div>
                    </form>

    </div>

            <br/>

            <div class="p-d-flex" *ngIf="!showToolbar">
                <button *ngIf="!messaggio.id" pButton label="Invia il messaggio" (click)="onSubmit()" icon="fa fa-paper-plane-o" type="button" [disabled]="!myForm.valid" class="p-button-outlined p-mr-2 p-mb-2"></button>
                <button *ngIf="messaggio.id" label="Segna come Letto" (click)="leggi()" icon="fa fa-eye" pButton type="button" class="p-button-secondary p-button-outlined p-mr-2 p-mb-2" ></button>
                <button *ngIf="messaggio.id" label="Elimina" (click)="elimina()" icon="fa fa-trash" pButton type="button" class="p-button-danger p-button-outlined p-mr-2 p-mb-2" ></button>
            </div>


    </div>
