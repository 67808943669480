import { Pipe, PipeTransform } from '@angular/core';
import { Ticket } from '../domain/ticket';
import { StatoTicket } from '../enumeratori/stato-ticket';

@Pipe({
    name: 'iconaTicket'
})
export class IconaTicketPipe implements PipeTransform {

    transform(value: Ticket, args: string): string {

        if (!value) {
            return 'fa fa-times';
        }

        if (!args) {

            // ICONA

            if (value.stato === StatoTicket.APERTO) {
                // return 'fa fa-clock-o';
                return 'fa fa-exclamation-triangle';
            }
            if (value.stato === StatoTicket.IN_LAVORAZIONE) {
                // return 'fa fa-user-md';
                return 'fa fa-cogs';
            }
            if (value.stato === StatoTicket.CHIUSO) {
                return 'fa fa-check';
            }

            return 'fa fa-times';

        }

        if (args === 'colore') {

            // COLORE

            if (value.stato === StatoTicket.APERTO) {
                return 'red';
            }
            if (value.stato === StatoTicket.IN_LAVORAZIONE) {
                return 'orange';
            }
            if (value.stato === StatoTicket.CHIUSO) {
                return 'green';
            }

            return '';
        }


        if (args === 'tooltip') {

            // COLORE

            if (value.stato === StatoTicket.APERTO) {
                return 'OPEN';
            }
            if (value.stato === StatoTicket.IN_LAVORAZIONE) {
                return 'UNDER PROCESSING';
            }
            if (value.stato === StatoTicket.CHIUSO) {
                return 'CLOSED';
            }

            return '???';
        }

        return '';
    }
}
