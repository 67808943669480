import { Injectable } from '@angular/core';
import { Varco } from '../domain/varco';
import { DAService } from './da.service';
import { Costanti } from './costanti';
import { SessioneService } from './sessione.service';
import { firestore } from 'firebase';
import * as moment from 'moment';
import { Ticket } from '../domain/ticket';
import { Account } from '../domain/account';
import { StatoTicket } from '../enumeratori/stato-ticket';
import { MyAccount } from '../domain/my.account';
import { MessageService } from 'primeng/api';

@Injectable()
export class TicketService {

  constructor(
    private da: DAService
  ) {}


    /**
     *
     * Copia il varco passato come oggetto, modifica solo il nome
     *
     * @param tgate
     *
     */
    duplica(varco: Varco) {

        // rimuovo alcuni campi

        delete varco.id;
        delete varco.dataCreazione;
        delete varco.codice;

        // ne modifico altri

        const new_data = {
            nome: varco.nome + ' copy',
            dataCreazione: new Date(),
        };


        // duplico
        // const data = {...varco, nome: varco.nome + ' copy', };
        const data = {...varco, ...new_data};

        this.da.creaSenzaChiave(data, Costanti.TABELLA_VARCHI);
    }


nessunGiornoSelezionato(data: any): boolean {
    // console.log('nessunGiornoSelezionato ?');
    return ((!data.get('lun').value && !data.get('mar').value && !data.get('mer').value && !data.get('gio').value && !data.get('ven').value && !data.get('sab').value && !data.get('dom').value));
}

elimina(ticket: Ticket) {
    console.log('elimina ' + ticket.id);
    this.da.elimina(ticket.id, Costanti.TABELLA_TICKETS);
}

inLavorazione(ticket: Ticket, account: Account): boolean {

    console.log('inLavorazione ' + ticket.id);

    if (!ticket.id) {
        console.error('il ticket non era ancora stato salvato !');
        return false;
    }
    if (ticket.stato !== StatoTicket.APERTO) {
        console.error('il ticket non era APERTO !');
        return false;
    }

    const data: any = {
        stato: StatoTicket.IN_LAVORAZIONE ,
        operatoreUid: account.uid ,
        operatoreNominativo: account.displayName,
        dataLavorazione: new Date(),
        tempoLavorazione: new Date().getTime() - ticket.dataCreazione.toMillis(),
    };

    this.da.modifica(ticket.id, Costanti.TABELLA_TICKETS, data, false);

    return true;
}

chiudi(ticket: Ticket, account: Account): boolean {

    console.log('chiudi ' + ticket.id);

    if (!ticket.id) {
        console.error('il ticket non era ancora stato salvato !');
        return false;
    }
    if (ticket.stato !== StatoTicket.IN_LAVORAZIONE) {
        console.error('il ticket non era IN LAVORAZIONE !');
        return false;
    }

    const data: any = {
        stato: StatoTicket.CHIUSO ,
        operatoreUid: account.uid ,
        operatoreNominativo: account.displayName,
        dataChiusura: new Date(),
        tempoChiusura: new Date().getTime() - ticket.dataLavorazione.toMillis(),

    };

    this.da.modifica(ticket.id, Costanti.TABELLA_TICKETS, data, false);

    return true;
}

annulla(ticket: Ticket, account: Account): boolean {

    console.log('annulla ' + ticket.id);

    if (!ticket.id) {
        console.error('il ticket non era ancora stato salvato !');
        return false;
    }
    if (ticket.stato !== StatoTicket.APERTO) {
        console.error('il ticket non era APERTO !');
        return false;
    }

    const data: any = {
        stato: StatoTicket.ANNULLATO ,
        operatoreUid: account.uid ,
        operatoreNominativo: account.displayName,
        dataAnnulla: new Date(),
    };

    this.da.modifica(ticket.id, Costanti.TABELLA_TICKETS, data, false);

    return true;
}


}
