
import { Pipe, PipeTransform } from '@angular/core';
import { Sistemazioni } from '../domain/sistemazioni';
import { PreventivoService } from '../services/preventivo.service';
@Pipe({
    name: 'sistemazione'
  })
export class SistemazionePipe implements PipeTransform {

    constructor(private ps: PreventivoService) {}

  transform(value: Sistemazioni): string {
    return this.ps.getSistemazioneHuman(value);
}

}
