import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ISubscription } from 'rxjs/Subscription';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { Account } from 'src/app/domain/account';
import { DAService } from 'src/app/services/da.service';
import { SessioneService } from 'src/app/services/sessione.service';
import { Cliente } from '../../domain/cliente';
import { Preventivo } from '../../domain/preventivo';

@Component({
  templateUrl: './pagamenti-new-view.component.html',
})
export class PagamentiNewViewComponent implements OnInit, OnDestroy {

pagamenti?: Cliente[] = [];
pagamento?: Cliente;
preventivo?: Preventivo;

  cols: any[];

  data = new Date();

  utente?: Account;
  risultati?: any;

  private subscription: ISubscription;

  constructor(
    private sessione: SessioneService,
    private dao: DAService,
    private breadcrumbService: BreadcrumbService,
    private messageService: MessageService
  ) {}



  ngOnInit() {

    this.utente = this.sessione.operatoreLoggato;

    this.cols = [
        { field: 'amount', header: 'Ammontare', style: 'center' },
        { field: 'stato', header: 'Stato', style: 'center'},
        { field: 'token.email', header: 'E-mail', style: 'center'},
    ];

    this.breadcrumbService.setItems([
        { label: ' Pagamenti ricevuti', routerLink: [''] }

    ]);

    this.filtra();

}

filtra() {

    this.disiscrivi();

    this.pagamenti = [];

    this.subscription = this.dao.getPagamenti().subscribe(items => {

        console.log('novita sul listener ' + JSON.stringify(items));

        this.pagamenti = items;
        if (!items || items.length === 0) {
            console.log(' nessun items');
            this.risultati = null;
            this.messageService.add({severity: 'error', summary: 'Elenco Pagamenti', detail: 'Nessun risultato'});
        }
    });

}

deselezionaPreventivo() {
    this.preventivo = null;
}

deseleziona() {
    this.pagamento = null;
}

  seleziona($event) {
    console.log('seleziona ' + JSON.stringify($event));

    this.pagamento = $event;

  }

  ngOnDestroy() {
    this.disiscrivi();
  }

  disiscrivi() {
    if (this.subscription) {this.subscription.unsubscribe(); }
  }


}
