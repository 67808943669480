import { Injectable } from '@angular/core';
import { DAService } from './da.service';
import { Costanti } from './costanti';
import { SessioneService } from './sessione.service';
import { Messaggio } from '../domain/messaggio';
import { firestore } from 'firebase';
import { MessageService } from 'primeng/api';

@Injectable()
export class MessaggioService {

  constructor(
    private messageService: MessageService,
    private sessione: SessioneService,
    private da: DAService
  ) {}


  elimina(messaggio: Messaggio) {
    console.log('elimina ' + messaggio.id);
    this.da.elimina(messaggio.id, Costanti.TABELLA_MESSAGGI);
}

letto(messaggio: Messaggio, uid: string) {
    console.log('messaggio ' + messaggio.id + ' letto da ' + uid);
    messaggio.letto = true;
    messaggio.dataLettura = firestore.Timestamp.fromDate(new Date());

    this.da.modifica(messaggio.id, Costanti.TABELLA_MESSAGGI, messaggio);
}

}
