import { Injectable } from '@angular/core';
import { Costanti } from './costanti';
import 'rxjs/add/operator/map';
import { Varco } from '../domain/varco';
import { Log } from '../domain/log';
import { Tgate } from '../domain/tgate';
import { Smartphone } from '../domain/smartphone';
import { DigitalKey } from '../domain/digital-key';
import { Crediti } from '../domain/crediti';
import { Account } from '../domain/account';
import { Ticket } from '../domain/ticket';
import { Preventivo } from '../domain/preventivo';
import { Cliente } from '../domain/cliente';
import { Operatore } from '../domain/operatore';
import { Pagamento } from '../domain/pagamento';
import { Camera } from '../domain/camera';
import { Messaggio } from '../domain/messaggio';
import * as moment from 'moment';
import { Chiamata } from '../domain/chiamata';
import { DataMining } from '../domain/data-mining';
import { RichiestaPreventivo } from '../domain/richiestaPreventivo';
import { MessageService } from 'primeng/api';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable()
export class DAService {



  constructor(
      private afs: AngularFirestore,
      private messageService: MessageService) {

  }

  /**
   * Utilizzato per ottenere un array con tutti i T-Gate collegati all'UID
   * passato come parametro
   *
   */
  getTgates(account: Account) {

    console.log('getTgates ' + account.uid);

    // const collezione = this.afs.collection<Tgate>(Costanti.TABELLA_TGATES, ref => ref.where('uid', '==', uid));
    let collezione: any;

    collezione = this.afs.collection<Tgate>(Costanti.TABELLA_TGATES, ref => ref.where('uid', '==', account.uid));

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Tgate;
          data.id = a.payload.doc.id;

          // fix
          /*const myGeoProperty = a.payload.doc.data().fix;
          if (myGeoProperty) {
            data.lat = myGeoProperty.latitude;
            data.lon = myGeoProperty.longitude;
          }*/
          return data;
        });
      });
  }

  getPreventiviPerDate(da_data: Date, a_data: Date, stato?: string) {

    const limite = 1000;

    let collezione: any;

    if (!stato) {
        collezione = this.afs.collection<Preventivo>(Costanti.TABELLA_PREVENTIVI, ref => ref.orderBy('dataCreazione', 'desc').where('dataCreazione', '>', da_data).where('dataCreazione', '<=', a_data).limit(limite));
    } else {
        collezione = this.afs.collection<Preventivo>(Costanti.TABELLA_PREVENTIVI, ref => ref.orderBy('dataCreazione', 'desc').where('dataCreazione', '>', da_data).where('dataCreazione', '<=', a_data).where('stato', '==', stato).limit(limite));
    }

    return collezione.snapshotChanges()
        .map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data() as Preventivo;
                data.id = a.payload.doc.id;
                return data;
            });
        });
}

getPreventivi(da_data: Date, a_data: Date, filtro?: string) {

    console.log('getPreventivi, filtro ' + filtro);

    const limite = 1000;

    let collezione = null;

    if (!filtro) {
        collezione = this.afs.collection<Preventivo>(Costanti.TABELLA_PREVENTIVI, ref => ref.where('dataCreazione', '>', da_data).where('dataCreazione', '<=', a_data).orderBy('dataCreazione', 'desc').limit(limite));
    } else {
        collezione = this.afs.collection<Preventivo>(Costanti.TABELLA_PREVENTIVI, ref => ref.where('dataCreazione', '>', da_data).where('dataCreazione', '<=', a_data).orderBy('dataCreazione', 'desc').where('stato', '==', filtro).limit(limite));
    }

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Preventivo;
          data.id = a.payload.doc.id;

          data.date = [];
          data.date[0] = data.checkIn.toDate();
          data.date[1] = data.checkOut.toDate();
          return data;
        });
      });
  }

  getAllPreventivi(filtro?: string) {

    console.log('getAllPreventivi, filtro ' + filtro);
    
    const limite = 100; // 500
    
    let collezione = null;
    
    if (!filtro) {
        console.log('getAllPreventivi senza filtri');
        collezione = this.afs.collection<Preventivo>(Costanti.TABELLA_PREVENTIVI, ref => ref.orderBy('dataCreazione', 'desc').limit(limite));
    } else {
        collezione = this.afs.collection<Preventivo>(Costanti.TABELLA_PREVENTIVI, ref => ref.orderBy('dataCreazione', 'desc').where('stato', '==', filtro).limit(limite));
    }

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Preventivo;
          data.id = a.payload.doc.id;

          data.date = [];
          // data.date[0] = data.checkIn.toDate();
          // data.date[1] = data.checkOut.toDate();
          data.date[0] = data.checkIn ? data.checkIn.toDate() : null;
          data.date[1] = data.checkOut ? data.checkOut.toDate() : null;
          return data;
        });
      });
  }

  getAllRichiestePreventivo(stato?: string) {

    console.log('getAllRichiestePreventivo, stato ' + stato);

    const limite = 500;

    let collezione = null;

    if (!stato) {
        collezione = this.afs.collection<RichiestaPreventivo>(Costanti.TABELLA_RICHIESTE_PREVENTIVO, ref => ref.orderBy('dataCreazione', 'desc').limit(limite));
    } else {
        collezione = this.afs.collection<RichiestaPreventivo>(Costanti.TABELLA_RICHIESTE_PREVENTIVO, ref => ref.orderBy('dataCreazione', 'desc').where('stato', '==', stato).limit(limite));
    }

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as RichiestaPreventivo;
          data.id = a.payload.doc.id;
          return data;
        });
      });
  }

  getPreventiviCliente(cliente?: string) {

    console.log('getPreventiviCliente, cliente ' + cliente);

    const limite = 1000;

    const collezione = this.afs.collection<Preventivo>(Costanti.TABELLA_PREVENTIVI, ref => ref.where('cliente', '==', cliente).orderBy('dataCreazione', 'desc').limit(limite));

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Preventivo;
          data.id = a.payload.doc.id;

          data.date = [];
          data.date[0] = data.checkIn.toDate();
          data.date[1] = data.checkOut.toDate();
          return data;
        });
      });
  }

  getMessaggiAll() {

    const limite = 1000;

    const collezione = this.afs.collection<Messaggio>(Costanti.TABELLA_MESSAGGI, ref => ref.orderBy('data', 'desc').limit(limite));

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Messaggio;
          data.id = a.payload.doc.id;
          return data;
        });
      });
  }

  getMessaggi(destinatario: string) {

    console.log('getMessaggi destinati a ' + destinatario);

    const limite = 1000;

    const collezione = this.afs.collection<Messaggio>(Costanti.TABELLA_MESSAGGI, ref => ref.where('destinatario', '==', destinatario).orderBy('data', 'desc').limit(limite));

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Messaggio;
          data.id = a.payload.doc.id;
          return data;
        });
      });
  }

  getMessaggiLetti(uid: string) {

    console.log('getMessaggiLetti da ' + uid);

    const limite = 1000;
    const data = {who: uid};

    const collezione = this.afs.collection<Messaggio>(Costanti.TABELLA_MESSAGGI, ref => ref.where('lettori', 'array-contains', data).orderBy('data', 'desc').limit(limite));

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Messaggio;
          data.id = a.payload.doc.id;
          return data;
        });
      });
  }

  getClienti() {

    const limite = 10000;

    const collezione = this.afs.collection<Cliente>(Costanti.TABELLA_CLIENTI, ref => ref.orderBy('cognome', 'desc').limit(limite));

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Cliente;
          data.id = a.payload.doc.id;
          return data;
        });
      });
  }

  getClientiPerCognome(inizio: string, fine: string) {

    console.log('getClientiPerCognome da ' +  inizio + ', ' + fine);

    const collezione = this.afs.collection<Cliente>(Costanti.TABELLA_CLIENTI, ref => ref.where('cognome', '>=', inizio).where('cognome', '<', fine));

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Cliente;
          data.id = a.payload.doc.id;
          return data;
        });
      });
  }

  getClientiPerCitta(inizio: string, fine: string) {

    console.log('getClientiPerCitta da ' +  inizio + ', ' + fine);

    const collezione = this.afs.collection<Cliente>(Costanti.TABELLA_CLIENTI, ref => ref.where('citta', '>=', inizio).where('citta', '<', fine));

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Cliente;
          data.id = a.payload.doc.id;
          return data;
        });
      });
  }

  getCamere(sistemazione?: string) {

    const limite = 10000;
    let collezione: any;

    if (!sistemazione) {
        collezione = this.afs.collection<Camera>(Costanti.TABELLA_CAMERE, ref => ref.orderBy('nome').limit(limite));
    } else {
        collezione = this.afs.collection<Camera>(Costanti.TABELLA_CAMERE, ref => ref.where('sistemazione', '==', sistemazione).orderBy('nome').limit(limite));
    }

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Camera;
          data.id = a.payload.doc.id;
          return data;
        });
      });
  }

  getPagamenti(cliente?: string) {

    const limite = 10000;

    let collezione: any;

    if (!cliente) {
        collezione = this.afs.collection<Pagamento>(Costanti.TABELLA_PAGAMENTI, ref => ref.orderBy('dataCreazione', 'desc').limit(limite));
    } else {
        collezione = this.afs.collection<Pagamento>(Costanti.TABELLA_PAGAMENTI, ref => ref.where('cliente', '==', cliente).orderBy('dataCreazione', 'desc').limit(limite));
    }

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Pagamento;
          data.id = a.payload.doc.id;
          return data;
        });
      });
  }

  getOperatori() {

    const limite = 10000;

    return this.afs.collection<Operatore>(Costanti.TABELLA_OPERATORI, ref => ref.orderBy('cognome', 'desc').limit(limite)).snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Operatore;
          data.id = a.payload.doc.id;
          return data;
        });
      });
  }

  getRichiesteAuditor(auditor: Account, filtro: string) {

    console.log('getRichiesteAuditor ' + auditor.uid + ', filtro ' + filtro);
    const limite = 100;

    let collezione = null;

    if (!filtro) {
            collezione = this.afs.collection<Preventivo>(Costanti.TABELLA_PREVENTIVI, ref => ref.where('auditorUid', '==', auditor.uid).orderBy('dataCreazione', 'desc').limit(limite));
    } else {
            collezione = this.afs.collection<Preventivo>(Costanti.TABELLA_PREVENTIVI, ref => ref.where('auditorUid', '==', auditor.uid).where('stato', '==', filtro).orderBy('dataCreazione', 'desc').limit(limite));
        }

    return collezione.snapshotChanges()
        .map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data() as Preventivo;
                data.id = a.payload.doc.id;
                return data;
            });
        });
    }

    getAccounts(filtro?: string) {

        console.log('getAccounts ' + filtro);

        let collezione = null;

        if (!filtro) {
            collezione = this.afs.collection<Account>(Costanti.TABELLA_ACCOUNT, ref => ref);
        } else {
            collezione = this.afs.collection<Account>(Costanti.TABELLA_ACCOUNT, ref => ref.where('ruolo', '==', filtro).orderBy('displayName', 'desc'));
        }



        return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Account;
          data.id = a.payload.doc.id;
          return data;
        });
      });
  }

  getAuditors() {

    console.log('getAuditors');

    const collezione = this.afs.collection<Account>(Costanti.TABELLA_ACCOUNT, ref => ref.where('ruolo', '==', 'AUDITOR'));


    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Account;
          data.id = a.payload.doc.id;
          return data;
        });
      });
  }

  /**
   * Utilizzato per ottenere un array con tutti i T-Gate collegati all'UID
   * passato come parametro
   *
   */
  getTgatesAdmin(uid: string) {

    console.log('getTgatesAdmin, uid:  ' +  uid);

    const collezione = this.afs.collection<Tgate>(Costanti.TABELLA_TGATES, ref => ref.where('uidAdmin', '==', uid));

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Tgate;
          data.id = a.payload.doc.id;

          // fix
          /*const myGeoProperty = a.payload.doc.data().fix;
          if (myGeoProperty) {
            data.lat = myGeoProperty.latitude;
            data.lon = myGeoProperty.longitude;
          }*/
          return data;
        });
      });
  }

/**
 * Modifica il documento e logga l'evento
 *
 * @param codiceHw
 * @param data
 * @param utente
 * @param log
 */
public modifica(chiave: string, tabella: string, data: any, showToast?: boolean) {

    const userDocument = this.afs.doc<any>('/' + tabella + '/' + chiave);

    console.log('modifica con data: ' + JSON.stringify(data));

    userDocument
      .update(data) // .set() works correctly right here...
      // .catch(error => console.log(error));
      .catch(error => {
          console.log(error);
          if (showToast) {
              this.messageService.add({severity: 'error', summary: 'Modifica Dati', detail: error});
            }
          return;
        });

    if (showToast) {
        this.messageService.add({severity: 'success', summary: 'Modifica Dati', detail: 'Dati modificati con successo' });
    }

  }


  crea(chiave: string, dati: any, tabella: string) {

    console.log('nuovo documento con chiave /' + tabella + '/' + chiave + ' : ' + JSON.stringify(dati));

    this.afs.collection(tabella).doc(chiave).set(dati)
       .then(function(docRef) {
           console.log('nuova documento creato con successo');
       })
       .catch(function(error) {
           console.error('creazione nuovo documento failed: ', error);
       });
  }

  /*leggiMessaggio(messaggioId: string, uid: string) {

    console.log('leggiMessaggio /' + messaggioId + ' da ' + uid);

    this.afs.collection(Costanti.TABELLA_MESSAGGI)
    .doc(messaggioId)
    .collection('lettori')
    .add({ who: uid, when: new Date() })
    // .add({uid})
       .then(function(docRef) {
           console.log('leggiMessaggio con successo');
       })
       .catch(function(error) {
           console.error('leggiMessaggio failed: ', error);
       });
  }*/


  elimina($key: string, tabella: string) {
    console.log('rimuovo id ' + $key + ' da tabella ' + tabella);
    // const itemDoc = this.afs.doc(tabella + '/' + $key);
    // itemDoc.delete();

    this.afs.collection(tabella).doc($key).delete().then(function() {
        console.log('Document successfully deleted!');
    }).catch(function(error) {
        console.error('Error removing document: ', error);
    });

  }

  creaSenzaChiave(dati: any, tabella: string) {

    console.log('nuovo documento con chiave autogenerata su /' + tabella + ' : ' + JSON.stringify(dati));

    this.afs.collection(tabella).add(dati)
    .then(function(docRef) {
        console.log('nuovo documento creato con successo e id ' + docRef.id);
    })
    .catch(function(error) {
        console.error('creazione nuovo documento failed: ', error);
    });

  }

  /**
   * Utilizzato per ottenere un array con tutti i Varchi collegati al T-Gate
   * passato come parametro
   *
   */
  getVarchi(tgate?: Tgate, uid?: string) {

    console.log('getVarchi via ' + (tgate ? ('T-Gate' + tgate.codiceMagazzino) : ('uid ' + uid)));

    if (!tgate && !uid) {
        console.log('getVarchi senza tgate e uid');
        return;
    }

    let collezione: any;

    if (tgate) {
        collezione = this.afs.collection<Varco>(Costanti.TABELLA_VARCHI, ref => ref.where('uid', '==', uid).where('tgate', '==', tgate.codiceMagazzino));
    } else {
        collezione = this.afs.collection<Varco>(Costanti.TABELLA_VARCHI, ref => ref.where('uid', '==', uid));
    }

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Varco;
          data.id = a.payload.doc.id;

          return data;
        });
      });
  }

  /**
   * Utilizzato per ottenere un array con tutti i Varchi amministrati,
   * collegati al T-Gate passato come parametro.
   *
   */
  getVarchiAmministrati(tgate?: Tgate, uid?: string) {

    console.log('getVarchiAmministrati da ' + (tgate ? ('T-Gate' + tgate.codiceMagazzino + ', ') : '') + 'UID: ' + uid);

    if (!tgate && !uid) {
        console.log('getVarchiAmministrati senza tgate e uid');
        return;
    }

    let collezione: any;

    if (tgate) {
        console.log('getVarchiAmministrati, query is ref.where(uidAdmin === ' + uid + ')');
        collezione = this.afs.collection<Varco>(Costanti.TABELLA_VARCHI, ref => ref.where('uidAdmin', '==', uid).where('tgate', '==', tgate.codiceMagazzino));
    } else {
        // collezione = this.afs.collection<Varco>(Costanti.TABELLA_VARCHI, ref => ref.where('uidAdmin', '==', uid));
        collezione = this.afs.collection<Varco>(Costanti.TABELLA_VARCHI, ref => ref.where('uidAdmin', '==', uid));
    }

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Varco;
          data.id = a.payload.doc.id;

          return data;
        });
      });
  }

  /**
   * Utilizzato per ottenere un array con tutti i Varchi collegati al T-Gate
   * passato come parametro
   *
   */
  getVarchiAll(account: Account) {

      let collezione: any;

      collezione = this.afs.collection<Varco>(Costanti.TABELLA_VARCHI, ref => ref.where('uid', '==', account.uid));

      return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Varco;
          data.id = a.payload.doc.id;

          // fix
          /*const myGeoProperty = a.payload.doc.data().fix;
          if (myGeoProperty) {
            data.lat = myGeoProperty.latitude;
            data.lon = myGeoProperty.longitude;
          }*/
          return data;
        });
      });
  }

/**
   * Utilizzato per ottenere un array con tutti gli Access Logs collegati all'UID
   * passato come parametro
   *
   */
  getAccessLogs(account: Account, da_data: Date, a_data: Date) {

    const limite = 1000;

    console.log('getAccessLogs uid: ' +  account.uid + ', da_data: ' + da_data + ', a_data: ' + a_data + ', con limite a: ' + limite);

    // in realta', per adesso, non faccio alcun controllo sull'UID

    let collezione: any;
    // const collezione = this.afs.collection<Log>(Costanti.TABELLA_ACCESS_LOGS, ref => ref.where('data', '>', da_data).where('data', '<=', a_data).orderBy('data', 'desc').limit(limite));

    collezione = this.afs.collection<Log>(Costanti.TABELLA_ACCESS_LOGS, ref => ref.where('uid', '==', account.uid).where('data', '>', da_data).where('data', '<=', a_data).orderBy('data', 'desc').limit(limite));

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Log;
          data.id = a.payload.doc.id;

          return data;
        });
      });
  }

  /**
   * Utilizzato per ottenere un array con tutti gli Access Logs collegati all'UID
   * passato come parametro
   *
   */
  getAccessLogsOld(uid: string, da_data: Date, a_data: Date) {

    const limite = 1000;

    console.log('getAccessLogs uid: ' +  uid + ', da_data: ' + da_data + ', a_data: ' + a_data + ', con limite a: ' + limite);

    // in realta', per adesso, non faccio alcun controllo sull'UID

    const collezione = this.afs.collection<Log>(Costanti.TABELLA_ACCESS_LOGS, ref => ref.where('uid', '==', uid).where('data', '>', da_data).where('data', '<=', a_data).orderBy('data', 'desc').limit(limite));
    // const collezione = this.afs.collection<Log>(Costanti.TABELLA_ACCESS_LOGS, ref => ref.where('data', '>', da_data).where('data', '<=', a_data).orderBy('data', 'desc').limit(limite));

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Log;
          data.id = a.payload.doc.id;

          return data;
        });
      });
  }

  getLastTickets() {

    const limit = 1000;

    return this.afs.collection<Ticket>(Costanti.TABELLA_TICKETS, ref => ref.orderBy('dataCreazione', 'desc').limit(limit)).snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Ticket;
          data.id = a.payload.doc.id;
          data.dataCreazioneHuman = moment(data.dataCreazione.toDate()).format(Costanti.FORMATTER_DATA_SHORT);

          return data;
        });
      });
  }

  getTicketsByStato(stato: string) {

    const limite = 1000;
    let collezione: any;

    if (stato) {
        collezione = this.afs.collection<Ticket>(Costanti.TABELLA_TICKETS, ref => ref.where('stato', '==', stato).orderBy('dataCreazione', 'desc').limit(limite));
    } else {
        collezione = this.afs.collection<Ticket>(Costanti.TABELLA_TICKETS, ref => ref.orderBy('dataCreazione', 'desc').limit(limite));
    }

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Ticket;
          data.id = a.payload.doc.id;
          data.dataCreazioneHuman = moment(data.dataCreazione.toDate()).format(Costanti.FORMATTER_DATA_SHORT);

          return data;
        });
      });
  }

  getDmGenerale() {
    return this.afs.doc<DataMining>(Costanti.TABELLA_DM + '/generale').valueChanges();
  }


  getChiamate(stato?: string) {

    const limite = 1000;
    let collezione: any;

    if (stato) {
        collezione = this.afs.collection<Chiamata>(Costanti.TABELLA_CHIAMATE, ref => ref.where('stato', '==', stato).limit(limite));
    } else {
        collezione = this.afs.collection<Chiamata>(Costanti.TABELLA_CHIAMATE, ref => ref.limit(limite));
    }

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Chiamata;
          data.id = a.payload.doc.id;
          return data;
        });
      });
  }

  getTicketOperatore(uid: string, stato?: string) {

    const limite = 1000;
    let collezione: any;

    if (stato) {
        collezione = this.afs.collection<Ticket>(Costanti.TABELLA_TICKETS, ref => ref.where('operatoreUid', '==', uid).where('stato', '==', stato).limit(limite));
    } else {
        collezione = this.afs.collection<Ticket>(Costanti.TABELLA_TICKETS, ref => ref.where('operatoreUid', '==', uid).limit(limite));
    }

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Ticket;
          data.id = a.payload.doc.id;
          data.dataCreazioneHuman = moment(data.dataCreazione.toDate()).format(Costanti.FORMATTER_DATA_SHORT);
          return data;
        });
      });
  }

  /**
   * Utilizzato per ottenere un array con tutti i Crediti collegati all'UID
   * passato come parametro
   *
   */
  getCrediti(uid: string, da_data: Date, a_data: Date) {

    console.log('getCrediti uid: ' +  uid + ', da_data: ' + da_data + ', a_data: ' + a_data);

    // in realta', per adesso, non faccio alcun controllo sulla data

    const collezione = this.afs.collection<Crediti>(Costanti.TABELLA_CREDITI, ref => ref.where('uid', '==', uid).orderBy('data', 'desc').limit(100));
    // const collezione = this.afs.collection<Log>(Costanti.TABELLA_ACCESS_LOGS, ref => ref.where('uid', '==', uid).where('data', '>', da_data).where('data', '<=', a_data).orderBy('data', 'desc'));

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Crediti;
          data.id = a.payload.doc.id;

          return data;
        });
      });
  }

  /**
   * Utilizzato per ottenere l'account con la mail passata come parametro
   *
   */
  getAccount(email: string) {


    console.log('getAccount email: ' +  email);

    const collezione = this.afs.collection<Account>(Costanti.TABELLA_ACCOUNT, ref => ref.where('email', '==', email));

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Account;
          data.id = a.payload.doc.id;

          return data;
        });
      });
  }

    /**
     * Utilizzato per ottenere un array con tutti gli Access Logs collegati all'UID
     * passato come parametro
     *
     */
    getDigitalKeys(account: Account) {

        // console.log('getDigitalKeys uid: ' + uid);
        console.log('getDigitalKeys  uid: ' + account.uid);

        let collezione: any;

        collezione = this.afs.collection<DigitalKey>(Costanti.TABELLA_CHIAVI, ref => ref.where('uid', '==', account.uid).orderBy('data', 'desc'));

        return collezione.snapshotChanges()
            .map(actions => {
                return actions.map(a => {
                    const data = a.payload.doc.data() as DigitalKey;
                    data.id = a.payload.doc.id;

                    return data;
                });
            });
    }

  /**
   * Utilizzato per ottenere un array con tutti gli Smartphones collegati all'UID
   * passato come parametro
   *
   */
  getSmartphones(account: Account, os?: string) {

    const limite = 1000;

    // console.log('getSmartphones uid: ' + account.amministratore + ', os: ' + os);
    console.log('getSmartphones uid: ' + account.uid + ', os: ' + os);

    // const collezione = this.afs.collection<Smartphone>(Costanti.TABELLA_SMARTPHONES, ref => ref.where('uid', '==', uid).orderBy('dataLastLog', 'desc').limit(limite));
    let collezione: any;

    if (!os) {
              collezione = this.afs.collection<Smartphone>(Costanti.TABELLA_SMARTPHONES, ref => ref.where('uid', '==', account.uid).orderBy('dataLastLog', 'desc').limit(limite));
          } else {
              collezione = this.afs.collection<Smartphone>(Costanti.TABELLA_SMARTPHONES, ref => ref.where('uid', '==', account.uid).where('os', '==', os).orderBy('dataLastLog', 'desc').limit(limite));
          }

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Smartphone;
          data.id = a.payload.doc.id;
          data.dataLastLogHuman = a.payload.doc.data().dataLastLog.toDate();
          return data;
        });
      });
  }

  /**
   * Utilizzato per ottenere un array con tutti gli Smartphones collegati all'UID
   * passato come parametro
   *
   */
  getSmartphonesOld(uid: string, os?: string) {

    const limite = 1000;

    console.log('getSmartphones uid: ' +  uid + ', os: ' + os);

    // const collezione = this.afs.collection<Smartphone>(Costanti.TABELLA_SMARTPHONES, ref => ref.where('uid', '==', uid).orderBy('dataLastLog', 'desc').limit(limite));
    let collezione: any;

    if (!os) {
        collezione = this.afs.collection<Smartphone>(Costanti.TABELLA_SMARTPHONES, ref => ref.where('uid', '==', uid).orderBy('dataLastLog', 'desc').limit(limite));
    } else {
        collezione = this.afs.collection<Smartphone>(Costanti.TABELLA_SMARTPHONES, ref => ref.where('uid', '==', uid).where('os', '==', os).orderBy('dataLastLog', 'desc').limit(limite));
    }

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Smartphone;
          data.id = a.payload.doc.id;

          return data;
        });
      });
  }

  /**
   * Utilizzato per ottenere un array con tutti gli Smartphones collegati all'UID
   * passato come parametro
   *
   */
  getSmartphonesRitardatari(uid: string, soglia: Date) {

    const limite = 1000;

    console.log('getSmartphonesRitardatari uid: ' +  uid + ', soglia: '  + soglia);

    // const collezione = this.afs.collection<Smartphone>(Costanti.TABELLA_SMARTPHONES, ref => ref.where('uid', '==', uid).where('dataLastLog', '<', soglia).orderBy('dataLastLog', 'desc').limit(limite));
    let collezione = null;

    if (soglia) {
        collezione = this.afs.collection<Smartphone>(Costanti.TABELLA_SMARTPHONES, ref => ref.where('uid', '==', uid).where('dataLastLog', '<', soglia).orderBy('dataLastLog', 'desc').limit(limite));
    } else {
        collezione = this.afs.collection<Smartphone>(Costanti.TABELLA_SMARTPHONES, ref => ref.where('uid', '==', uid).where('dataLastLog', '==', null).limit(limite));
    }

    return collezione.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Smartphone;
          data.id = a.payload.doc.id;

          return data;
        });
      });
  }

}
