<div class="layout-dashboard" *ngIf="account">
    <div class="card">

        <p-toolbar>
            <div class="ui-toolbar-group-left">
                <span>
                    <button *ngIf="account.email" pButton label="SALVA" (click)="onSubmit()" icon="fa fa-save" type="button" [disabled]="!myForm.valid" class="p-button-outlined p-mr-2 p-mb-2"></button>
                    <button *ngIf="!account.email" label="CREA" (click)="nuovoAccount()" icon="fa fa-plus" pButton type="button" class="p-button-info p-button-outlined p-mr-2 p-mb-2" ></button>
                    <button label="CHIUDI" (click)="annulla()" icon="fa fa-times" pButton type="button" class="p-button-warning p-button-outlined p-mr-2 p-mb-2" ></button>
                </span>
            </div>
        </p-toolbar>

    </div>

    <div class="card" *ngIf="account.email">
        <div class="card-header">
            <h1>Modifica Account</h1>
        </div>
        <div class="p-col-12">
            <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
                <div class="p-d-flex">
                    <div class="p-mr-2">
                            <input id="nome" disabled="true" placeholder="uid"  type="text" formControlName="uid" pInputText/>
                        </div>
                        <div class="p-mr-2">
                            <input id="cognome" disabled="true" placeholder="e-mail" type="text" formControlName="email" pInputText/>
                        </div>
                        <div class="p-mr-2">
                            <p-dropdown formControlName="ruolo" [options]="comboRuoli" ></p-dropdown>
                        </div>
                        <div class="p-mr-2">
                            <input id="displayName" placeholder="Nominativo" type="text" formControlName="displayName" pInputText/>
                        </div>
                    </div>
                    </form>
        </div>
    </div>
        </div>


    <div class="card" *ngIf="!account.email">
        <div class="card-header">
            <h1>Crea un nuovo Account</h1>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <input id="username" placeholder="username (e-mail)" style="width: 100%" type="text" [(ngModel)]="username" pInputText/>
            </div>
            <div class="p-col-12">
                <input id="pwd" placeholder="password" style="width: 100%" type="text" [(ngModel)]="password" pInputText/>
            </div>
        </div>
    </div>

