/**
 * AutenticatoreService
 *
 * Gestisce le proedure di autenticazione via Google e Facebook
 *
 * Per google utilizza AngularFireAuth
 * Per Facebook invece AuthService (angular4-social-login npm)
 *
 */
import { Injectable } from '@angular/core';
import { DAService } from './da.service';
import { Costanti } from './costanti';
import { SessioneService } from './sessione.service';
import { Preventivo } from '../domain/preventivo';
import { Account } from '../domain/account';
import { Camera } from '../domain/camera';
import { StatoCamera } from '../enumeratori/stato-camera';

@Injectable()
export class CameraService {

  constructor(
    private da: DAService
  ) {}

  cameraPulita(camera: Camera, utente: Account): boolean {
    const data: any = {
        stato: StatoCamera.PRONTA,
        ultimaPulizia: new Date(),
        ultimaGovernante: utente.displayName,
    };

    this.da.modifica(camera.id, Costanti.TABELLA_CAMERE, data);
    return true;
}

inizializza(camera: Camera): boolean {
    const data: any = {
        stato: StatoCamera.NON_PRONTA,
        ultimaPulizia: new Date(),
    };

    this.da.modifica(camera.id, Costanti.TABELLA_CAMERE, data);
    return true;
}

    /**
     *
     * Copia il varco passato come oggetto, modifica solo il nome
     *
     * @param tgate
     *
     */
    duplica(richiesta: Preventivo) {

        // rimuovo alcuni campi

        /*delete richiesta.id;
        delete richiesta.dataCreazione;
        delete richiesta.codice;

        // ne modifico altri

        const new_data = {
            nome: varco.nome + ' copy',
            dataCreazione: new Date(),
        };


        // duplico
        const data = {...richiesta, ...new_data};

        this.da.creaSenzaChiave(data, Costanti.TABELLA_RICHIESTE);*/
    }


elimina(camera: Camera) {
    console.log('elimina ' + camera.id);
    this.da.elimina(camera.id, Costanti.TABELLA_CAMERE);
}


}
