import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ISubscription } from 'rxjs/Subscription';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { DAService } from 'src/app/services/da.service';
import { PreventivoService } from 'src/app/services/preventivo.service';
import { SessioneService } from 'src/app/services/sessione.service';
import { Account } from '../../domain/account';
import { RichiestaPreventivo } from '../../domain/richiestaPreventivo';

@Component({
  templateUrl: './richieste-preventivo-view.component.html',
})
export class RichiestePreventivoViewComponent implements OnInit, OnDestroy {

preventivi?: RichiestaPreventivo[] = [];
preventivo?: RichiestaPreventivo;

  cols: any[];

  data = new Date();

  utente?: Account;
  risultati?: any;

   // BOX
   totalePreventivi = 0;
   preventiviAccettati = 0;
   preventiviInCorso = 0;
   totaleClienti = 0;

  private subscription: ISubscription;
  private subscribe1: ISubscription;
  private subscribe2: ISubscription;
  private subscribe3: ISubscription;
  private subscribe4: ISubscription;

  constructor(
    private sessione: SessioneService,
    private preventivoService: PreventivoService,
    private confirmationService: ConfirmationService,
    private dao: DAService,
    private breadcrumbService: BreadcrumbService,
  ) {}



  elimina(preventivo: RichiestaPreventivo) {

    this.confirmationService.confirm({
        message: 'Sei sicuro di voler cancellare la richiesta di preventivo ?',
        accept: () => {
            this.preventivoService.eliminaRichiesta(preventivo);
        }
    });

}

trasformaInPreventivo() {

    this.confirmationService.confirm({
        message: 'Sei sicuro di voler trasformare la richiesta in un preventivo ?',
        accept: () => {
            // this.preventivoService.eliminaRichiesta(this.preventivo);
        }
    });

}

  ngOnInit() {

    this.utente = this.sessione.operatoreLoggato;

    this.subscribe1 = this.dao.getAllRichiestePreventivo(null).subscribe(item => this.preventivi = item);
    // this.subscribe2 = this.dao.getAllPreventivi('ACCETTATO').subscribe(item => this.preventiviAccettati = item.length);
    // this.subscribe3 = this.dao.getAllPreventivi('IN_CORSO').subscribe(item => this.preventiviInCorso = item.length);
    // this.subscribe4 = this.dao.getClienti().subscribe(item => this.totaleClienti = item.length);

    this.cols = [
        { field: 'protocollo', header: 'Protocollo', style: 'center' },
        { field: 'nomeStruttura', header: 'Struttura', style: 'center'},
        { field: 'stato', header: 'Stato', style: 'center'},
    ];

    this.breadcrumbService.setItems([
        { label: ' Scrivania', routerLink: ['/dash'] },
        { label: ' Richieste Preventivi', routerLink: [''] }

    ]);

}

  deseleziona() {
    this.preventivo = null;
  }

  seleziona($event) {
    this.preventivo = $event;
  }

  ngOnDestroy() {
    if (this.subscription) {this.subscription.unsubscribe(); }
    if (this.subscribe1) {this.subscribe1.unsubscribe(); }
    if (this.subscribe2) {this.subscribe2.unsubscribe(); }
    if (this.subscribe3) {this.subscribe3.unsubscribe(); }
    if (this.subscribe4) {this.subscribe4.unsubscribe(); }
  }

  disiscrivi() {
    if (this.subscription) {this.subscription.unsubscribe(); }
  }


}
