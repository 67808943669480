import { firestore } from 'firebase';
import { StatoCamera } from '../enumeratori/stato-camera';
import { Struttura } from './struttura';

export class Camera {
    id?:                               string;
    nome?:                             string;
    stato?:                            StatoCamera;
    sistemazione?:                     Struttura;
    dataCreazione?:                    firestore.Timestamp;
    ultimaPulizia?:                    firestore.Timestamp;
    ultimaGovernante?:                 string;

    constructor(_nome: string) {
       this.nome = _nome;
       this.stato = StatoCamera.NON_PRONTA;
       this.sistemazione = Struttura.HOTEL_VILLAGE;
       this.dataCreazione = firestore.Timestamp.fromDate(new Date());
    }
}
