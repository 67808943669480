import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    OnChanges,
    Output,
    EventEmitter
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { Costanti } from 'src/app/services/costanti';
import { DAService } from 'src/app/services/da.service';
import { SessioneService } from 'src/app/services/sessione.service';
import { Account } from 'src/app/domain/account';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
})
export class AccountComponent implements OnInit, OnChanges, OnDestroy {

    @Input() account?: Account;
    @Output() chiudi = new EventEmitter();

    myForm: FormGroup;

    qrCode?: any;

    advanced = false;

    comboRuoli: SelectItem[] = Costanti.COMBO_RUOLI;

    // nuovo account

    password: string;
    username: string;

    constructor(
        private sessione: SessioneService,
        private dao: DAService,
        private afa: AngularFireAuth,
        private messageService: MessageService,
    ) {}

    ngOnInit() {}

    nuovoAccount() {

        if (!this.username || !this.password) {

            this.messageService.add({
                severity: 'error',
                summary: 'Sign in',
                detail: 'Inserisci email e password'
            });
            return;
        }

        if (this.password.length < 8) {
            this.messageService.add({
                severity: 'error',
                summary: 'Sign in',
                detail: 'La Password deve avere almeno 8 characters'
            });
            return;
        }

        this.messageService.add({severity: 'success', summary: 'Sign in', detail: 'Account creato con successo !'});

        return this.afa.createUserWithEmailAndPassword(this.username, this.password)
            .then(() => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Sign in',
                    detail: 'Account creato con successo !'
                });
            })
            .catch((e) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Qualcosa e\' andato storto',
                    detail: e
                });
            });
    }

    /**
     * invocato ogni volta che l'input del componente cambia
     */
    ngOnChanges() {

        this.myForm = new FormGroup({
            ruolo: new FormControl(this.account.ruolo, Validators.required),
            citta: new FormControl(this.account.citta),
            displayName: new FormControl(this.account.displayName),
            // READ ONLY
            dataCreazione: new FormControl(this.account.dataCreazione ? this.account.dataCreazione.toDate() : new Date()),
            email: new FormControl(this.account.email, Validators.required),
            uid: new FormControl(this.account.uid),
        });

    }

    deselezionaSistema() {
        console.log('deselect @ SchedaSistema');
        this.sessione.deselezionaDispositivo();
    }

    flipAdvanced() {
        // console.log('deselect @ SchedaSistema');
        this.advanced = !this.advanced;
    }

    onSubmit() {

        // TODO: Use EventEmitter with form value

        console.log('SUBMINT');

        if (this.myForm.status !== 'VALID') {
            console.error('form is not valid, cannot save to database');
            return;
        }

        /*if (this.varcoService.erroreNelleDate(this.myForm)) {
            console.error('errore nelle date');
            return;
        }

        if (this.varcoService.erroreNelleUscite(this.myForm)) {
            console.error('errore nelle uscite');
            return;
        }

        if (this.varcoService.erroreNelleNotificheEmail(this.myForm)) {
            console.error('errore nelle notifiche e-mail');
            return;
        }*/

        console.log(this.myForm.value);
            this.dao.modifica(this.account.id, Costanti.TABELLA_ACCOUNT, this.myForm.value, true);

    }

    annulla() {
        this.chiudi.emit();
    }

    salva() {
        // this.myForm.chiudi.emit();
        this.onSubmit();
        // this.chiudi.emit();
    }

    ngOnDestroy() {
        // this.sessione.deselezionaTgate();
    }
}
