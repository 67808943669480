import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { DAService } from './da.service';
import { Costanti } from './costanti';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UtenteService {

    constructor(
        private messageService: MessageService,
        private http: HttpClient,
        private da: DAService,
        private afa: AngularFireAuth,

      ) {}

      aggiornaDataLastLogin(uid: string) {

        console.log('aggiornaDataLastLogin ' + uid);

        const data = {dataLastLogin: new Date()};

        this.da.modifica(uid, Costanti.TABELLA_MY_ACCOUNT, data, false);

        console.log('aggiornaDataLastLogin ok');
      }

      aggiornaDatiLogin(uid: string) {

        this.http.get<any>('https://jsonip.com').subscribe(data => {
            const ip = data.ip;
            const userAgent = navigator.userAgent;

            const _data = {
                dataLastLogin: new Date(),
                lastIp: ip,
                userAgent: userAgent,
                locale: navigator.language,
            };

            this.da.modifica(uid, Costanti.TABELLA_MY_ACCOUNT, _data, false);

        })  

      }


      /*aggiornaDatiLoginOld(uid: string) {



            console.log('********* updateLoginData, do nothing *********');
            const promise = new Promise((resolve, reject) => {
              this.http
              .get('https://jsonip.com')
              .toPromise()
              .then(res => {
                // Success
                console.log('"updateLoginData success ->' + JSON.stringify(res));

                // console.log('updateLoginData .ip: ' + res.json().ip);
                // console.log('updateLoginData .about: ' + res.json().about);
                console.log('updateLoginData .geolocation: ' + navigator.geolocation);
                console.log('updateLoginData .userAgent: ' + navigator.userAgent);
                console.log('updateLoginData .language: ' + navigator.language);

                // const ip = res.json().ip;
                const ip = res.ip;
                const userAgent = navigator.userAgent;

                const data = {
                    dataLastLogin: new Date(),
                    lastIp: ip,
                    userAgent: userAgent,
                    locale: navigator.language,
                };

                this.da.modifica(uid, Costanti.TABELLA_MY_ACCOUNT, data, false);

                resolve();

              })
              .catch(function(error) {
                console.log('Error updateLoginData -> ', error);
              });
          });

            console.log('aggiornaLastIp ok');
      }*/

      async cambiaPassword(password1: string, passwrod2: string) {

        console.log('cambiaPassword');

        if (!password1 || !passwrod2) {
            this.messageService.add({severity: 'error', summary: 'Password', detail: 'Inserisci due volte la nuova password' });
            return;
        }
        if (password1 !== passwrod2) {
            this.messageService.add({severity: 'error', summary: 'Password', detail: 'Le passwords NON coincidono' });
            return;
        }
        if (password1.length < 8) {
            this.messageService.add({severity: 'error', summary: 'Password', detail: 'La password deve avere almeno 8 caratteri' });
            return;
        }
        if (password1.search(/[A-Z]/) === -1) {
            this.messageService.add({severity: 'error', summary: 'Password', detail: 'La password deve avere almeno 1 carattere maiuscolo' });
            return;
        }
        /*if (password1.search(/[a-z]/) === -1) {
            this.messageService.add({severity: 'error', summary: 'Password', detail: 'La password deve avere almeno 1 carattere minuscolo' });
            return;
        }*/
        /*if (password1.search(/[0-9]/) === -1) {
            this.messageService.add({severity: 'error', summary: 'Password', detail: 'La password deve avere almeno 1 numero' });
            return;
        }*/

        const user = this.afa.currentUser;

        (await user).updatePassword(password1)
            .then(res => {

                console.log('PASSWORD MODFICATA CON SUCCESSO');
                this.messageService.add({severity: 'info', summary: 'Password', detail: 'Password modificata con successo !' });

            })
            .catch(res => {

                console.log('ERRORE NELLA MODFICATA PASSWORD -> ' + res);
                this.messageService.add({severity: 'error', summary: 'Password', detail: 'Qualcosa e\' andato storto.' + res });

            });

        /*user.updatePassword(password1).then(function() {
            // Update successful.
            console.log('PASSWORD MODFICATA CON SUCCESSO');
            this.afs.collection(Costanti.TABELLA_UTENTI).doc(utente.uid).set(
                {
                    dataLastPassword: new Date(),
                },
                { merge: true }
            );
            this.messageService.add({severity: 'info', summary: 'Password', detail: 'Password modificata con successo !' });
        }).catch(function(error) {
            // An error happened.
            console.log('ERRORE NELLA MODFICATA PASSWORD -> ' + error);
            this.messageService.add({severity: 'error', summary: 'Password', detail: 'Qualcosa e\' andato storto' });
        });*/
    }

}
