import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ISubscription } from 'rxjs/Subscription';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { DAService } from 'src/app/services/da.service';
import { SessioneService } from 'src/app/services/sessione.service';
import { Account } from '../../domain/account';
import { Ticket } from '../../domain/ticket';

@Component({
  templateUrl: './tickets.view.component.html',
})
export class TicketsViewComponent implements OnInit, OnDestroy {

  tickets?: Ticket[] = [];
  mieiTickets?: Ticket[] = [];
  ticket?: Ticket;

  cols: any[];

  data = new Date();

  utente?: Account;
  risultati?: any;
  filtro = 'TUTTI';

   // BOX
   totale = 0;
   inLavorazione = 0;
   aperti = 0;
   chiusi = 0;
   miei = 0;
   tempoMedioDiChiusura = 0;
    tempoMedioLavorazione = 0;

   // TOTALI
   tutti: Ticket[] = [];
   ticketsInLavorazione: Ticket[] = [];
   ticketsAperti: Ticket[] = [];
   ticketsChiusi: Ticket[] = [];
   ticketsMiei: Ticket[] = [];

  private subscription: ISubscription;
  private subscribe0: ISubscription;
  private subscribe1: ISubscription;
  private subscribe2: ISubscription;
  private subscribe3: ISubscription;
  private subscribe4: ISubscription;

  constructor(
    private sessione: SessioneService,
    private dao: DAService,
    private breadcrumbService: BreadcrumbService,
    private messageService: MessageService
  ) {}



  ngOnInit() {

    this.utente = this.sessione.operatoreLoggato;

    console.log('TicketsViewComponent getDmGenerale ....');
    this.subscribe0 = this.dao.getDmGenerale().subscribe(item => {
        console.log('TicketsViewComponent getDmGenerale ---> ' + JSON.stringify(item));
        this.totale = item.tickets;
        this.tempoMedioDiChiusura = item.tempoMedioChiusura;
        this.tempoMedioLavorazione = item.tempoMedioLavorazione;
    });
    this.subscribe1 = this.dao.getLastTickets().subscribe(item => {
        this.tutti = item;
    });
    this.subscribe2 = this.dao.getTicketsByStato('APERTO').subscribe(item => {
        this.aperti = item.length;
        this.ticketsAperti = item;
    });
    this.subscribe3 = this.dao.getTicketsByStato('IN_LAVORAZIONE').subscribe(item => {
        this.inLavorazione = item.length;
        this.ticketsInLavorazione = item;
    });
    this.subscribe4 = this.dao.getTicketsByStato('CHIUSO').subscribe(item => {
        this.chiusi = item.length;
        this.ticketsChiusi = item;
    });
    this.subscription = this.dao.getTicketOperatore(this.utente.uid).subscribe(item => {
        this.miei = item.length;
        this.ticketsMiei = item;
    });



    this.cols = [
        { field: 'data', header: 'Data' },
        { field: 'tipo', header: 'Tipo' },
        { field: 'oggetto', header: 'Oggetto' },
    ];

    this.breadcrumbService.setItems([
        { label: ' Elenco Tickets', routerLink: [''] }

    ]);

    this.filtra();

}

filtra() {

    this.disiscrivi();

    this.tickets = [];
    this.mieiTickets = [];

    // this.subscription = this.dao.getTickets().subscribe(items => {
    this.subscription = this.dao.getLastTickets().subscribe(items => {

        if (items && items.length > 0) {
            // console.log('AccessLogsComponent --- ' + JSON.stringify(items));
            this.risultati = '- ' + items.length + ' records';
            this.tickets = items;
        } else {
            console.error('Tickets --- NO ITEMS');
            this.risultati = null;
            this.messageService.add({ severity: 'error', summary: 'Tickets', detail: 'No records found'});
        }
    });

}

filtraSoloMiei() {

    this.disiscrivi();

    this.tickets = [];
    this.mieiTickets = [];

    this.subscription = this.dao.getTicketOperatore(this.utente.uid).subscribe(items => {

        if (items && items.length > 0) {
            // console.log('AccessLogsComponent --- ' + JSON.stringify(items));
            this.risultati = '- ' + items.length + ' records';
            this.tickets = items;
        } else {
            console.error('Tickets --- NO ITEMS');
            this.risultati = null;
            this.messageService.add({ severity: 'error', summary: 'Tickets', detail: 'No records found'});
        }
    });

}

filtraTutti() {
    this.disiscrivi();
    this.tickets = this.tutti;
    this.filtro = 'TUTTI';
}
filtraChiusi() {
    this.disiscrivi();
    this.tickets = this.ticketsChiusi;
    this.filtro = 'CHIUSI';
}
filtraLavorazione() {
    this.disiscrivi();
    this.tickets = this.ticketsInLavorazione;
    this.filtro = 'LAVORAZIONE';
}
filtraMiei() {
    this.disiscrivi();
    this.tickets = this.ticketsMiei;
    this.filtro = 'MIEI';
}

filtraAperti() {

    this.disiscrivi();

    this.tickets = [];
    this.mieiTickets = [];

    this.subscription = this.dao.getTicketOperatore(this.utente.uid).subscribe(items => {

        if (items && items.length > 0) {
            // console.log('AccessLogsComponent --- ' + JSON.stringify(items));
            this.risultati = '- ' + items.length + ' records';
            this.tickets = items;
        } else {
            console.error('Tickets --- NO ITEMS');
            this.risultati = null;
            this.messageService.add({ severity: 'error', summary: 'Tickets', detail: 'No records found'});
        }
    });

}

nuovo() {
    this.ticket = new Ticket();

    this.breadcrumbService.setItems([
        // { label: 'Elenco Tickets', routerLink: [''] },
        { label: 'Nuovo Ticket', routerLink: [''] }

    ]);
}

  selezionaTicket(_ticket: Ticket) {
    console.log('seleziona ticket ' + JSON.stringify(_ticket));
    this.ticket = _ticket;
    // this.seleziona.emit(ticket);
}

  deseleziona() {
    this.ticket = null;

    this.breadcrumbService.setItems([
        { label: ' Scrivania', routerLink: ['/dash'] },
        { label: ' Elenco Tickets', routerLink: [''] }
    ]);

  }

  cambiaLog($event) {
    console.log('cambiaTicket ' + JSON.stringify($event));

    this.ticket = $event;

  }

  ngOnDestroy() {
    this.disiscrivi();
    if (this.subscribe0) {this.subscribe0.unsubscribe(); }
    if (this.subscribe1) {this.subscribe1.unsubscribe(); }
    if (this.subscribe2) {this.subscribe2.unsubscribe(); }
    if (this.subscribe3) {this.subscribe3.unsubscribe(); }
    if (this.subscribe4) {this.subscribe4.unsubscribe(); }
  }

  disiscrivi() {
    if (this.subscription) {this.subscription.unsubscribe(); }
  }


}
