<div class="layout-dashboard" *ngIf="operatore">

    <div class="card">
        <p-toolbar>
            <div class="ui-toolbar-group-left">
                <span>
                    <button pButton label="SALVA" (click)="onSubmit()" icon="fa fa-save" type="button" [disabled]="!myForm.valid" class="p-button-outlined p-mr-2 p-mb-2"></button>
                    <button label="CHIUDI" (click)="annulla()" icon="fa fa-times" pButton type="button" class="p-button-warning p-button-outlined p-mr-2 p-mb-2" ></button>
                </span>
            </div>
        </p-toolbar>
    </div>

    <div class="card">
            <div class="card-header">
                <h1>Dati Operatore</h1>
            </div>
            <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-6">
                            <input id="nome" placeholder="nome" style="width: 100%" type="text" formControlName="nome" pInputText/>
                            <span class="errore" *ngIf="(myForm.get('nome').touched || myForm.get('nome').dirty ) && myForm.get('nome').errors">
                               manca il nome
                            </span>
                        </div>
                        <div class="p-col-12 p-md-6">
                            <input id="cognome" placeholder="cognome" style="width: 100%" type="text" formControlName="cognome" pInputText/>
                            <span class="errore" *ngIf="(myForm.get('cognome').touched || myForm.get('cognome').dirty ) && myForm.get('cognome').errors">
                                manca il cognome
                             </span>
                        </div>
                        <div class="p-col-12 p-md-6">
                            <input id="email" placeholder="email" style="width: 100%" type="text" formControlName="email" pInputText/>
                                <span class="errore" *ngIf="(myForm.get('email').touched || myForm.get('email').dirty ) && myForm.get('email').errors">
                                    manca una e-mail
                                 </span>
                        </div>
                        <div class="p-col-12 p-md-6">
                            <input id="sigla" placeholder="sigla" style="width: 100%" type="text" formControlName="sigla" pInputText/>
                            <span class="errore" *ngIf="(myForm.get('sigla').touched || myForm.get('sigla').dirty ) && myForm.get('sigla').errors">
                                manca una sigla
                             </span>
                        </div>

                        <div class="p-col-12 p-md-6">
                            <label for="tipo">Ruolo</label>
                            <p-dropdown [options]="comboRuolo" formControlName="ruolo"></p-dropdown>
                        </div>

                    </div>
            </form>

    </div>

</div>
