import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ISubscription } from 'rxjs/Subscription';
import { Product } from 'src/app/demo/domain/product';
import { ProductService } from 'src/app/demo/service/productservice';
import { Account } from 'src/app/domain/account';
import { Pagamento } from 'src/app/domain/pagamento';
import { DAService } from 'src/app/services/da.service';

@Component({
    styleUrls: ['./pagamenti.scss'],
    selector: 'app-pagamenti',
    templateUrl: './pagamenti.component.html',
})
export class PagamentiComponent implements OnInit, OnChanges, OnDestroy {

@Input() pagamenti?: Pagamento[] = [];
@Input() showFilter?: boolean;

pagamento?: Pagamento;

  cols: any[];
  products: Product[];

  data = new Date();

  utente?: Account;
  risultati?: any;

  private subscription: ISubscription;

  constructor(
    private dao: DAService,
    private messageService: MessageService,
    private productService: ProductService
  ) {}



  ngOnInit() {

    this.cols = [
        { field: 'amount', header: 'Ammontare', style: 'center' },
        { field: 'stato', header: 'Stato', style: 'center'},
        { field: 'token.email', header: 'E-mail', style: 'center'},
    ];

    this.productService.getProducts().then(data => this.products = data);


}

ngOnChanges() {

    if (!this.pagamenti) {
        this.filtra();
    }

}


filtra() {

    this.disiscrivi();

    this.pagamenti = [];

    this.subscription = this.dao.getPagamenti().subscribe(items => {

        console.log('novita sul listener ' + JSON.stringify(items));

        this.pagamenti = items;
        if (!items || items.length === 0) {
            console.log(' nessun items');
            this.risultati = null;
            this.messageService.add({severity: 'error', summary: 'Elenco Pagamenti', detail: 'Nessun risultato'});
        }
    });

}

deseleziona() {
    this.pagamento = null;
}

  ngOnDestroy() {
    this.disiscrivi();
  }

  disiscrivi() {
    if (this.subscription) {this.subscription.unsubscribe(); }
  }


}
