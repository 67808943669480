<div class="p-grid" *ngIf="preventivo">
    <div class="p-col-12" >
        <p-toolbar>
            <div class="ui-toolbar-group-left">
                <span>
                    <button *ngIf="preventivo.stato !== 'SCADUTO'" pButton label="Salva e Invia" (click)="salva()" icon="fa fa-paper-plane-o" type="button" class="p-button-rounded p-button-outlined p-mr-2 p-mb-2" [disabled]="!myForm.valid"></button>
                    <button pButton label="Print" (click)="print()" icon="fa fa-print" type="button" class="p-button-rounded p-button-outlined p-mr-2 p-mb-2" [disabled]="!myForm.valid"></button>
                    <button *ngIf="preventivo.id && preventivo.stato !== 'ACCETTATO'" label="Accettato !" (click)="accetta()" icon="fa fa-check" pButton type="button" class="p-button-rounded p-button-outlined p-button-success p-mr-2 p-mb-2"></button>
                    <button *ngIf="preventivo.id && preventivo.stato !== 'ACCETTATO'" label="Elimina" (click)="elimina()" icon="fa fa-trash" pButton type="button" class="p-button-rounded p-button-outlined p-button-danger p-mr-2 p-mb-2"></button>
                    <button label="Chiudi" (click)="annulla()" icon="fa fa-times" pButton type="button" class="p-button-rounded p-button-outlined p-button-secondary  p-mr-2 p-mb-2" ></button>
                </span>
            </div>
        </p-toolbar>
    </div>

</div>

<div class="p-grid" *ngIf="preventivo">
    
    <!--SX-->

        <div class="p-col-12 p-md-8">
            <div class="card ">

                <h3>Preventivo</h3>

                    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">

                        <div class="p-d-flex p-flex-wrap p-as-end">
                            <div class="p-mr-2 p-mb-2">
                                <div class="p-col-12"><label for="adult">Operatore</label></div>
                                <p-dropdown [options]="comboOperatori" formControlName="operatore"></p-dropdown>
                            </div>
                            <div class="p-mr-2 p-mb-2">
                                <div class="p-col-12"><label for="mod">Modalita</label></div>
                                <p-dropdown [options]="comboModalita" formControlName="modalita"></p-dropdown>
                            </div>
                            <div class="p-mr-2 p-mb-2">
                                <div class="p-col-12"><label for="adult">Scadenza</label></div>
                                <p-calendar [disabled]="preventivo.stato === 'ACCETTATO'" [locale]="locale" dateFormat="dd/mm/yy" formControlName="dataScadenza" [showIcon]="true"  [readonlyInput]="true"></p-calendar>
                            </div>
                            <div class="p-mr-2 p-mb-2 p-as-end">
                                <p-selectButton [options]="sistemazioni" formControlName="sistemazione"></p-selectButton>
                            </div>
                        </div>

                    <div class="p-d-flex p-flex-wrap">
                        <div class="p-mr-2 p-mb-2">
                            <div class="p-col-12"><label for="adult">Date Soggiorno</label></div>
                            <p-calendar [disabled]="preventivo.stato === 'ACCETTATO'" [inline]="true" [minDate]="minDate" [maxDate]="maxDate" [locale]="locale" dateFormat="dd/mm/yy" formControlName="date" selectionMode="range" [showIcon]="true"  [readonlyInput]="true"></p-calendar>
                        </div>

                        <div class="p-mr-2 p-mb-2">
                        <div class="p-col-12">
                            <div class="p-mr-2 p-mb-2" *ngIf="!myForm.value.specialCase">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon"><i class="fa fa-black-tie"></i></span>
                                    <p-inputNumber [min]="0" [max]="100" placeholder="Adulti (>=12)" formControlName="adulti" [showButtons]="true"></p-inputNumber>
                                </div>
                                <div class="p-col-12" *ngIf="(myForm.get('adulti').touched || myForm.get('adulti').dirty ) && myForm.get('adulti').errors">
                                    <p class="errore">inserisci almeno un Adulto</p>
                                </div>
                            </div>

                            <div class="p-mr-2 p-mb-2" *ngIf="!myForm.value.specialCase">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon"><i class="fa fa-child"></i></span>
                                    <p-inputNumber placeholder="Infanti (0->2,9)" [min]="0" [max]="100" id="infanti" [showButtons]="true" formControlName="infanti"></p-inputNumber>
                                </div>
                            </div>
                            <div class="p-mr-2 p-mb-2 p-flex-wrap">
                                <p-checkbox label="Adulto e CDH" *ngIf="myForm.value.sistemazione !== 'FR' && myForm.value.sistemazione !== 'RE'" binary="true" formControlName="specialCase"></p-checkbox>
                                &nbsp;
                                <p-checkbox label="CHD 3° letto" *ngIf="(myForm.value.sistemazione === 'HP' || myForm.value.sistemazione === 'HV') && !myForm.value.specialCase" name="chd3" binary="true" formControlName="chd3"></p-checkbox>
                            </div>
                            <br/>
                            <div class="p-mr-2 p-mb-2 p-d-flex">
                                <p-checkbox name="chd4" label="CHD 4° letto" binary="true" formControlName="chd4"></p-checkbox>
                                &nbsp;
                                <p-checkbox name="adl3" label="Adulto 3° letto" binary="true" formControlName="adult3"></p-checkbox>
                                &nbsp;
                                &nbsp;
                            </div>
                            <p-checkbox name="adl4" label="Adulto 4° letto" binary="true" formControlName="adult4"></p-checkbox>
                        </div>
                        
                        <div class="p-mr-2 p-mb-2 p-d-flex">
                            <div class="p-field">
                                <label for="firstname1">Sconto</label>
                                <div class="p-inputgroup">
                                    <input *ngIf="myForm.value.sistemazione === 'HP'" id="aliquotaSconto1" [maxLength]="2" [size]="6" [readOnly]="true" placeholder="Sconto HP %" type="text" value="{{aliquotaScontoHp}}" pInputText />
                                    <input *ngIf="myForm.value.sistemazione === 'HV'" id="aliquotaSconto2" [maxLength]="2" [size]="6" [readOnly]="true" placeholder="Sconto HV %" type="text" value="{{aliquotaScontoHv}}" pInputText />
                                    <input *ngIf="myForm.value.sistemazione === 'FR'" id="aliquotaSconto3" [maxLength]="2" [size]="6" [readOnly]="true" placeholder="Sconto FR %" type="text" value="{{aliquotaScontoFr}}" pInputText />
                                    <input *ngIf="myForm.value.sistemazione === 'RE'" id="aliquotaSconto4" [maxLength]="2" [size]="6" [readOnly]="true" placeholder="Sconto RE %" type="text" value="{{aliquotaScontoRe}}" pInputText />
                                    <span class="p-inputgroup-addon">%</span>
                                </div>
                            </div>
                            &nbsp;
                            <div class="p-field">
                                <label for="firstname1">Animali</label>
                                <div class="p-inputgroup">
                                    <input id="animali" [maxLength]="2" [size]="6" [readOnly]="preventivo.stato === 'ACCETTATO'" placeholder="animali" type="text" formControlName="animali" pInputText />
                                    <span class="p-inputgroup-addon"><i class="fa fa-paw"></i></span>
                                    <span class="errore" *ngIf="(myForm.get('animali').touched || myForm.get('animali').dirty ) && myForm.get('animali').errors">inserisci un numero</span>
                                </div>
                            </div>
                        </div>
                    
                    <div class="p-mr-2 p-mb-2 p-d-flex" *ngIf="myForm.value.sistemazione === 'RE'">
                        <p-selectButton [options]="tipiResidence" formControlName="tipoResidence"></p-selectButton>
                        &nbsp;
                        <p-checkbox name="letto3" *ngIf="myForm.value.tipoResidence === 'MONOLOCALE'" label="3° letto" binary="true" formControlName="letto3"></p-checkbox>
                        <p-checkbox name="letto5" *ngIf="myForm.value.tipoResidence === 'TRILOCALE'" label="5° letto" binary="true" formControlName="letto5"></p-checkbox>
                        <p-checkbox name="letto6" *ngIf="myForm.value.tipoResidence === 'TRILOCALE'" label="6° letto" binary="true" formControlName="letto6"></p-checkbox>
                        <p-checkbox *ngIf="myForm.value.sistemazione !== 'RE'" name="MezzaPensione" label="Mezza Pensione" binary="true" formControlName="mezzaPensione"></p-checkbox>
                        &nbsp;
                        <p-checkbox  *ngIf="myForm.value.sistemazione === 'HP'" name="VistaMare" label="Vista Mare" binary="true" formControlName="vistaMare"></p-checkbox>
                        &nbsp;
                        <p-checkbox  *ngIf="myForm.value.sistemazione === 'HP'" name="DUS" label="DUS" binary="true" formControlName="dus"></p-checkbox>
                 </div>
                    
            </div>

                </div>
                    </form>
                    <div class="p-mr-2 p-mb-2 p-d-flex">
                        <p-checkbox *ngIf="myForm.value.sistemazione === 'HV'" name="BALC" label="Balcone / Veranda" binary="true" [(ngModel)]="balcone"></p-checkbox>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <!--<p-checkbox *ngIf="myForm.value.sistemazione === 'HV'" name="FINESTRA" label="Finestra" binary="true" [(ngModel)]="finestra"></p-checkbox>-->
                    &nbsp;
                    <p-checkbox *ngIf="economyAvailable()"  name="ECO" label="Economy / Cottage" binary="true" (onChange)="onCheckEconomy($event)" [(ngModel)]="economy"></p-checkbox>
                </div>
            </div>

            <p-panel header="Note" [toggleable]="true" [collapsed]="true">
                <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
                    <div class="ui-g">
                    <div class="p-col-12">
                        <p-editor formControlName="note" [style]="{'height':'320px'}">
                            <p-header>
                                <span class="ql-formats">
                                    <button class="ql-bold" aria-label="Bold"></button>
                                    <button class="ql-italic" aria-label="Italic"></button>
                                    <button class="ql-underline" aria-label="Underline"></button>
                                </span>
                            </p-header>
                        </p-editor>
                    </div>
                    </div>
                </form>
        </p-panel>
        </div>
    
    <!--DX-->

        <div class="p-col-12 p-md-4">
            <div class="card " id="da_stampare">
            <h3>Numero {{preventivo.numero ? preventivo.numero : 'DA ASSEGNARE'}}</h3>
        
                        <!--<img class="logo" id="da_stampare" src="assets/layout/images/atena.png" />-->
        
                        <!--<div class="p-col-12" style="text-align: center">
                            <h3>Athena Resort</h3>
                        </div>-->
        
                        <div class="p-col-12">
                            <table style="width: 100%;">
                                <tbody>
                                    <tr>
                                        <td><b>Data</b></td>
                                        <td></td>
                                        <td style="text-align: right">{{preventivo.dataCreazione.toDate() | date :'shortDate'}}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Cliente</b></td>
                                        <td style="text-align: right"> {{preventivo.clienteNome}}</td>
                                        <td style="text-align: right"> {{preventivo.clienteCognome}}</td>
                                    </tr>
                                    <tr>
                                        <td><hr/></td>
                                        <td><hr/></td>
                                        <td><hr/></td>
                                    </tr>
                                    <tr *ngIf="preventivo.checkIn">
                                        <td><b>Check-in</b></td>
                                        <td></td>
                                        <td style="text-align: right">{{preventivo.checkIn.toDate() | date :'shortDate'}}</td>
                                    </tr>
                                    <tr *ngIf="preventivo.checkOut">
                                        <td><b>Check-out</b></td>
                                        <td></td>
                                        <td style="text-align: right">{{preventivo.checkOut.toDate() | date :'shortDate'}}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Notti</b></td>
                                        <td style="text-align: center">{{notti}}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><b>Tariffa giornaliera</b></td>
                                        <td></td>
                                        <td style="text-align: right">{{tariffaMedia | currency :'EUR'}}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Pax</b></td>
                                        <!--<td style="text-align: center">{{pax}}</td>-->
                                        <td style="text-align: center">{{myForm.value.adulti}}</td>
                                        <td style="text-align: right">{{totaleAdulti | currency :'EUR'}}</td>
                                        <!--<td style="text-align: right">{{subTotaleLordo | currency :'EUR'}}</td>-->
                                    </tr>
                                    <tr  *ngIf="myForm.value.sistemazione !== 'FR' && (myForm.value.chd3 || myForm.value.chd4 || myForm.value.adult3 || myForm.value.adult4)">
                                        <td></td>
                                        <td></td>
                                        <td><hr/></td>
                                    </tr>
                                    <tr *ngIf="myForm.value.sistemazione !== 'FR' && myForm.value.chd3">
                                        <td><b>Bambini (3->11,9)</b> </td>
                                        <!--<td style="text-align: center">1</td>
                                        <td style="text-align: right">free</td>-->
                                        <td style="text-align: center">1 al 50%</td>
                                        <td style="text-align: right">{{costoChd3 | currency :'EUR'}}</td>
                                    </tr>
                                    <tr *ngIf="myForm.value.sistemazione !== 'FR' && myForm.value.chd4">
                                        <td><b>Bambini (3->11,9)</b> </td>
                                        <td style="text-align: center">1 al 50%</td>
                                        <td style="text-align: right">{{costoChd4 | currency :'EUR'}}</td>
                                    </tr>
                                    <tr *ngIf="myForm.value.sistemazione !== 'FR' && myForm.value.adult3">
                                        <td><b>Adulti 3° letto</b> </td>
                                        <td style="text-align: center">sconto 30%</td>
                                        <td style="text-align: right">{{costoAdl3 | currency :'EUR'}}</td>
                                    </tr>
                                    <tr *ngIf="myForm.value.sistemazione !== 'FR' && myForm.value.adult4">
                                        <td><b>Adulti 4° letto</b> </td>
                                        <td style="text-align: center">sconto 30%</td>
                                        <td style="text-align: right">{{costoAdl4 | currency :'EUR'}}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td><hr/></td>
                                    </tr>
        
                                    <tr>
                                        <td><b>Supplemento < 4gg</b> </td>
                                        <td style="text-align: center">{{supplementoNotteAliquota}} %</td>
                                        <td style="text-align: right">{{supplementoNotte | currency :'EUR'}}</td>
                                    </tr>
                                    <tr *ngIf="supplementoSingola">
                                        <td><b>Supplemento Singola</b> </td>
                                        <td style="text-align: center">{{supplementoSingolaAliquota}} %</td>
                                        <td style="text-align: right">{{supplementoSingola | currency :'EUR'}}</td>
                                    </tr>
                                    <tr *ngIf="myForm.value.mezzaPensione">
                                        <td><b>Riduzione mezza pensione</b> </td>
                                        <td></td>
                                        <td style="text-align: right">{{riduzioneMezzaPensione | currency :'EUR'}}</td>
                                    </tr>
                                    <tr *ngIf="myForm.value.sistemazione !== 'FR' && myForm.value.vistaMare">
                                        <td><b>Vista Mare</b> </td>
                                        <td></td>
                                        <td style="text-align: right">{{supplementoVistaMare | currency :'EUR'}}</td>
                                    </tr>
                                    <tr *ngIf="myForm.value.sistemazione !== 'FR' && myForm.value.dus">
                                            <td><b>DUS</b> </td>
                                            <td></td>
                                            <td style="text-align: right">{{supplementoDus | currency :'EUR'}}</td>
                                    </tr>
                                    <tr *ngIf="myForm.value.sistemazione !== 'FR' && myForm.value.balcone">
                                        <td><b>Supplemento balcone o veranda</b> </td>
                                        <td></td>
                                    <td style="text-align: right">{{supplementoBalconeVeranda | currency :'EUR'}}</td>
                                    </tr>
                                    <tr *ngIf="myForm.value.animali">
                                        <td><b>Animali</b> </td>
                                        <td></td>
                                        <td style="text-align: right">{{supplementoAnimali | currency :'EUR'}}</td>
                                    </tr>
                                    <tr *ngIf="forfaitConsumi">
                                        <td><b>Forfait consumi</b> </td>
                                        <td></td>
                                        <td style="text-align: right">{{forfaitConsumi | currency :'EUR'}}</td>
                                    </tr>
                                    <tr *ngIf="supplementoLetto3">
                                        <td><b>Supplemento 3° letto</b> </td>
                                        <td></td>
                                        <td style="text-align: right">{{supplementoLetto3 | currency :'EUR'}}</td>
                                    </tr>
                                    <tr *ngIf="supplementoLetto5">
                                        <td><b>Supplemento 5° letto</b> </td>
                                        <td></td>
                                        <td style="text-align: right">{{supplementoLetto5 | currency :'EUR'}}</td>
                                    </tr>
                                    <tr *ngIf="supplementoLetto6">
                                        <td><b>Supplemento 6° letto</b> </td>
                                        <td></td>
                                        <td style="text-align: right">{{supplementoLetto6 | currency :'EUR'}}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td><hr/></td>
                                    </tr>
                                    <tr>
                                        <td><b>SubTotale</b></td>
                                        <td></td>
                                        <td style="text-align: right">{{subTotale | currency :'EUR'}}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Sconto</b></td>
                                        <!--<td style="text-align: center">{{myForm.value.aliquotaSconto}} %</td>-->
                                        <td style="text-align: center">{{aliquotaSconto}} %</td>
                                        <td style="text-align: right">{{sconto | currency :'EUR'}}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td><hr/></td>
                                    </tr>
                                    <tr>
                                        <td><b>Totale lordo</b></td>
                                        <td></td>
                                        <td style="text-align: right">{{ totaleLordo | currency :'EUR'}}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Infant</b></td>
                                        <td style="text-align: center">{{myForm.value.infanti}}</td>
                                        <td style="text-align: right">{{tessereInfant | currency :'EUR'}}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Tessere Club</b></td>
                                        <td style="text-align: center">{{numeroTessereClub}}</td>
                                        <td style="text-align: right">{{tessereClub | currency :'EUR'}}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td><hr/></td>
                                    </tr>
                                    <tr>
                                        <td><b>Totale Soggiorno</b></td>
                                        <td></td>
                                        <td style="text-align: right;text-decoration: underline"><b>{{totale | currency :'EUR'}}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                            <br/><br/><br/>
                            <div>
                                <p><b>Sistemazione</b></p>
                                <!--<p>{{myForm.value | sistemazione}}</p>-->
                                <p>{{myForm.value.sistemazione | sistemazione}} {{economy ? ' in Mansarda / Cottage' : ''}}</p>
                                <p>{{letti}}</p>
                                <p><b>Trattamento</b></p>
                                <span *ngIf="myForm.value.sistemazione === 'RE'">
                                    <p>Solo pernottamento</p>
                                </span>
                                <span *ngIf="myForm.value.sistemazione !== 'RE'">
                                    <p>{{myForm.value.mezzaPensione ? 'Mezza Pensione' : 'Pensione Completa'}}, bevande incluse ai pasti (acqua, vino e soft drink)</p>
                                </span>
                            </div>
                            <div *ngIf="myForm.value.note">
                                <br/>
                                <p><b>Note</b></p>
                                <p [innerHTML]="myForm.value.note"></p>
                            </div>
                            <br/><br/><br/>
                        </div>
                        <button label="Calcola" class="p-button-rounded p-button-outlined" (click)="calcola()" icon="fa fa-cogs" pButton type="button"></button>
                    </div>
            </div>
    
    </div>
