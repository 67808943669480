<div class="p-grid table-demo">
	<div class="p-col-12">
		<div class="card">
			<h4>Default</h4>
			<p>Pagination, sorting, filtering and checkbox selection.</p>
			<p-table #dt [value]="customers1" [(selection)]="selectedCustomers1" dataKey="id"
					 styleClass="p-datatable-customers" [rowHover]="true" [rows]="10" [paginator]="true"
					 [filterDelay]="0" [globalFilterFields]="['name','country.name','representative.name','status']">
				<ng-template pTemplate="caption">
					<div class="table-header">
						Customers
						<span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
						   placeholder="Global Search"/>
                </span>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th style="width: 3rem">
							<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
						</th>
						<th pSortableColumn="name">Name
							<p-sortIcon field="name"></p-sortIcon>
						</th>
						<th pSortableColumn="country.name">Country
							<p-sortIcon field="country.name"></p-sortIcon>
						</th>
						<th pSortableColumn="representative.name">Representative
							<p-sortIcon field="representative.name"></p-sortIcon>
						</th>
						<th pSortableColumn="date">Date
							<p-sortIcon field="date"></p-sortIcon>
						</th>
						<th pSortableColumn="status">Status
							<p-sortIcon field="status"></p-sortIcon>
						</th>
						<th pSortableColumn="activity">Activity
							<p-sortIcon field="activity"></p-sortIcon>
						</th>
						<th style="width: 8rem"></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-customer>
					<tr class="p-selectable-row">
						<td>
							<p-tableCheckbox [value]="customer"></p-tableCheckbox>
						</td>
						<td>
							<span class="p-column-title">Name</span>
							{{customer.name}}
						</td>
						<td>
							<span class="p-column-title">Country</span>
							<img src="assets/demo/flags/flag_placeholder.png"
								 [class]="'flag flag-' + customer.country.code" width="30">
							<span class="image-text" style="margin-left: .5em;vertical-align: middle">{{customer.country.name}}</span>
						</td>
						<td>
							<span class="p-column-title">Representative</span>
							<img [alt]="customer.representative.name"
								 src="assets/demo/images/avatar/{{customer.representative.image}}" width="32"
								 style="vertical-align: middle"/>
							<span class="image-text" style="margin-left: .5em;vertical-align: middle">{{customer.representative.name}}</span>
						</td>
						<td>
							<span class="p-column-title">Date</span>
							{{customer.date}}
						</td>
						<td>
							<span class="p-column-title">Status</span>
							<span [class]="'customer-badge status-' + customer.status">{{customer.status}}</span>
						</td>
						<td>
							<span class="p-column-title">Activity</span>
							<p-progressBar [value]="customer.activity" [showValue]="false"></p-progressBar>
						</td>
						<td style="text-align: center">
							<button pButton type="button" class="p-button-secondary" icon="pi pi-cog"></button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="8">No customers found.</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

	<div class="p-col-12">
		<div class="card">
			<h4>Customized</h4>
			<p>Scrollable table with gridlines (<mark>.p-datatable-gridlines</mark>), striped rows (<mark>.p-datatable-striped</mark>) and smaller paddings (<mark>p-datatable-sm</mark>).</p>

			<p-table #dtc [value]="customers2" [(selection)]="selectedCustomer" dataKey="id" selectionMode="single"
					 styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
					 [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [globalFilterFields]="['name','country.name','representative.name','status']">
				<ng-template pTemplate="caption">
					<div class="table-header">
						Customers
						<span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dtc.filterGlobal($event.target.value, 'contains')"
						   placeholder="Global Search"/>
                </span>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th pSortableColumn="name">Name
							<p-sortIcon field="name"></p-sortIcon>
						</th>
						<th pSortableColumn="country.name">Country
							<p-sortIcon field="country.name"></p-sortIcon>
						</th>
						<th pSortableColumn="representative.name">Representative
							<p-sortIcon field="representative.name"></p-sortIcon>
						</th>
						<th pSortableColumn="date">Date
							<p-sortIcon field="date"></p-sortIcon>
						</th>
						<th pSortableColumn="status">Status
							<p-sortIcon field="status"></p-sortIcon>
						</th>
						<th pSortableColumn="activity">Activity
							<p-sortIcon field="activity"></p-sortIcon>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-customer>
					<tr [pSelectableRow]="customer">
						<td>
							<span class="p-column-title">Name</span>
							{{customer.name}}
						</td>
						<td>
							<span class="p-column-title">Country</span>
							<img src="assets/demo/flags/flag_placeholder.png"
								 [class]="'flag flag-' + customer.country.code" width="30">
							<span class="image-text" style="margin-left: .5em;vertical-align: middle">{{customer.country.name}}</span>
						</td>
						<td>
							<span class="p-column-title">Representative</span>
							<img [alt]="customer.representative.name"
								 src="assets/demo/images/avatar/{{customer.representative.image}}" width="32"
								 style="vertical-align: middle"/>
							<span class="image-text" style="margin-left: .5em;vertical-align: middle">{{customer.representative.name}}</span>
						</td>
						<td>
							<span class="p-column-title">Date</span>
							{{customer.date}}
						</td>
						<td>
							<span class="p-column-title">Status</span>
							<span [class]="'customer-badge status-' + customer.status">{{customer.status}}</span>
						</td>
						<td>
							<span class="p-column-title">Activity</span>
							<p-progressBar [value]="customer.activity" [showValue]="false"></p-progressBar>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="8">No customers found.</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

	<div class="p-col-12">
		<div class="card">
			<h4>Row Expand</h4>
			<p-table [value]="products" dataKey="name">
				<ng-template pTemplate="header">
					<tr>
						<th style="width: 3rem"></th>
						<th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
						<th>Image</th>
						<th pSortableColumn="price">Price <p-sortIcon field="price"></p-sortIcon></th>
						<th pSortableColumn="category">Category <p-sortIcon field="category"></p-sortIcon></th>
						<th pSortableColumn="rating">Reviews <p-sortIcon field="rating"></p-sortIcon></th>
						<th pSortableColumn="inventoryStatus">Status <p-sortIcon field="inventoryStatus"></p-sortIcon></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-product let-expanded="expanded">
					<tr>
						<td>
							<button type="button" pButton pRipple [pRowToggler]="product" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
						</td>
						<td>{{product.name}}</td>
						<td><img [src]="'assets/demo/images/product/' + product.image" [alt]="product.name" width="100" class="p-shadow-4" /></td>
						<td>{{product.price | currency:'USD'}}</td>
						<td>{{product.category}}</td>
						<td><p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating></td>
						<td><span [class]="'product-badge status-' + product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span></td>
					</tr>
				</ng-template>
				<ng-template pTemplate="rowexpansion" let-product>
					<tr>
						<td colspan="7">
							<div class="p-p-3">
								<p-table [value]="product.orders" dataKey="id">
									<ng-template pTemplate="header">
										<tr>
											<th pSortableColumn="id">Id <p-sortIcon field="price"></p-sortIcon></th>
											<th pSortableColumn="customer">Customer <p-sortIcon field="customer"></p-sortIcon></th>
											<th pSortableColumn="date">Date <p-sortIcon field="date"></p-sortIcon></th>
											<th pSortableColumn="amount">Amount <p-sortIcon field="amount"></p-sortIcon></th>
											<th pSortableColumn="stats">Status <p-sortIcon field="status"></p-sortIcon></th>
											<th style="width: 4rem"></th>
										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-order>
										<tr>
											<td>{{order.id}}</td>
											<td>{{order.customer}}</td>
											<td>{{order.id}}</td>
											<td>{{order.amount | currency:'USD'}}</td>
											<td><span [class]="'order-badge order-' + order.status.toLowerCase()">{{order.status}}</span></td>
											<td><p-button type="button" icon="pi pi-search"></p-button></td>
										</tr>
									</ng-template>
									<ng-template pTemplate="emptymessage">
										<tr>
											<td colspan="6">There are no order for this product yet.</td>
										</tr>
									</ng-template>
								</p-table>
							</div>
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

	<div class="p-col-12">
		<div class="card">
			<h4>Row Group and Scrolling</h4>
			<p-table [value]="customers3" sortField="representative.name" sortMode="single" (onSort)="onSort()" [scrollable]="true" scrollHeight="600px">
				<ng-template pTemplate="header">
					<tr>
						<th>Name</th>
						<th>Country</th>
						<th>Company</th>
						<th>Status</th>
						<th>Date</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-customer let-rowIndex="rowIndex">
					<tr *ngIf="rowGroupMetadata[customer.representative.name].index === rowIndex">
						<td colspan="5">
							<img [alt]="customer.representative.name" src="assets/demo/images/avatar/{{customer.representative.image}}" width="32" style="vertical-align: middle" />
							<span class="p-text-bold p-ml-2">{{customer.representative.name}}</span>
						</td>
					</tr>
					<tr>
						<td>
							{{customer.name}}
						</td>
						<td>
							<img src="assets/demo/flags/flag_placeholder.png" [class]="'flag flag-' + customer.country.code" width="30">
							<span class="image-text" style="margin-left: .5em">{{customer.country.name}}</span>
						</td>
						<td>
							{{customer.company}}
						</td>
						<td>
							<span [class]="'customer-badge status-' + customer.status">{{customer.status}}</span>
						</td>
						<td>
							{{customer.date}}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
</div>
