
<div class="layout-dashboard">

        <div class="p-card" *ngIf="!camera">

                <p-toolbar>
                    <div class="ui-toolbar-group-left">
                        <span class="p-buttonset">
                            <button pButton pRipple label="TUTTI" [ngClass]="filtro !== 'TUTTI' ? '' : 'p-button-secondary'" (click)="filtra()"></button>
                            <button pButton pRipple label="HOTEL VILLAGE" [ngClass]="filtro !== 'HV' ? '' : 'p-button-secondary'" (click)="filtro = 'HV'; filtra()"></button>
                            <button pButton pRipple label="HOTEL PALACE" [ngClass]="filtro !== 'HP' ? '' : 'p-button-secondary'" (click)="filtro = 'HP';filtra()"></button>
                            <button pButton pRipple label="FAMILY ROOM" [ngClass]="filtro !== 'FR' ? '' : 'p-button-secondary'" (click)="filtro = 'FR';filtra()"></button>
                            <button pButton pRipple label="COTTAGE" [ngClass]="filtro !== 'CO' ? '' : 'p-button-secondary'" (click)="filtro = 'CO';filtra()"></button>
                        </span>
                    </div>
                    <div class="p-toolbar-group-right">
                        <button pButton pRipple type="button" pTooltip="NUOVA CAMERA" class="p-button-rounded p-button-outlined p-mr-2 p-mb-2" (click)="nuovaCamera()" icon="fa fa-plus"></button>
                    </div>                
                </p-toolbar>
            </div>

    <div class="p-col-12" *ngIf="camera">
        <app-camera (chiudi)='deseleziona()' [camera]="camera"></app-camera>
    </div>

    <div class="p-col-12" *ngIf="camere && camere?.length > 0 && !camera">
        <app-camere  (chiudi)='deseleziona()' (seleziona)='seleziona($event)' [camere]="camere"></app-camere>
    </div>

    </div>

