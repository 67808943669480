import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    OnChanges,
    Output,
    EventEmitter
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MyAccount } from 'src/app/domain/my.account';
import { Account } from 'src/app/domain/account';
import { Messaggio } from 'src/app/domain/messaggio';
import { ISubscription } from 'rxjs/Subscription';
import { Costanti } from 'src/app/services/costanti';
import { SessioneService } from 'src/app/services/sessione.service';
import { DAService } from 'src/app/services/da.service';
import { MessaggioService } from 'src/app/services/messaggio.service';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';

@Component({
    selector: 'app-messaggio',
    templateUrl: './messaggio.component.html',
})
export class MessaggioComponent implements OnInit, OnChanges, OnDestroy {

    @Input() messaggio?: Messaggio;
    @Input() showToolbar?: boolean;
    @Output() chiudi = new EventEmitter();
    @Output() messaggioInviato = new EventEmitter();

    myForm: FormGroup;
    utente?: Account;
    myAccount?: MyAccount;
    private subscription: ISubscription;

    comboTipo: SelectItem[] = Costanti.COMBO_TIPO_TICKET;

    constructor(
        private sessione: SessioneService,
        private dao: DAService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private messaggioService: MessaggioService
    ) {}

    ngOnInit() {
        this.utente = this.sessione.operatoreLoggato;
        this.myAccount = this.sessione.myAccount;

    }

    /**
     * invocato ogni volta che l'input del componente cambia
     */
    ngOnChanges() {

        if (this.messaggio) {
            this.myForm = new FormGroup({

                data: new FormControl(this.messaggio.data ? this.messaggio.data.toDate() : null),
                oggetto: new FormControl(this.messaggio.oggetto, Validators.required),
                mittenteNominativo: new FormControl(this.messaggio.mittenteNominativo),
                mittenteUid: new FormControl(this.messaggio.mittenteUid),
                testo: new FormControl(this.messaggio.testo, Validators.required),
                letto: new FormControl(this.messaggio.letto),
            });

        } else {
            console.error('MessaggioComponent ngOnChanges senza messaggio');
        }
    }

    onSubmit() {

        // TODO: Use EventEmitter with form value

        console.log('SUBMINT');

        if (this.myForm.status !== 'VALID') {
            console.error('form is not valid, cannot save to database');
            return;
        }

        if (this.messaggio.id) {
            console.error('non puoi modificare un messaggio gia\' inviato');
            return;
        }

        this.dao.creaSenzaChiave(this.myForm.value, Costanti.TABELLA_MESSAGGI_USCITA);
        this.messageService.add({ severity: 'success', summary: 'Nuovo Messaggio', detail: 'Messaggio inviato con successo !' });

        this.messaggioInviato.emit();
    }

    annulla() {
        this.chiudi.emit();
    }

    elimina() {

        this.confirmationService.confirm({
            message: 'Sei sicuro di voler cancellare il Messaggio ?',
            accept: () => {
                this.messaggioService.elimina(this.messaggio);
                this.messaggioInviato.emit();
            }
        });

    }

    leggi() {

        this.confirmationService.confirm({
            message: 'Sei sicuro di voler segnare il Messaggio come \'letto\' da ' + this.utente.uid +' ?',
            accept: () => {
                this.messaggioService.letto(this.messaggio, this.utente.uid);
                this.messaggioInviato.emit();
            }
        });

    }

    salva() {
        this.onSubmit();
    }

    ngOnDestroy() {
        if (this.subscription) {this.subscription.unsubscribe(); }
    }
}
